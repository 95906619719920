import firebase from "../../utils/firebase/firebase.utils";

export default {
  graph: function (user,clickDates,clickTimes) {

    const toHtml = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>chart</title>
        <style>
          * {
            margin: 0;
            font-family: sans-serif;
          }
          .chartMenu {
            width: 100vw;
            height: 40px;
            background: #1a1a1a;
            color: rgb(26, 60, 255);
          }
          .chartMenu p {
            padding: 10px;
            font-size: 20px;
          }
          .chartCard {
            width: 100vw;
            height: calc(100vh - 40px);
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .chartBox {
            width: 700px;
            padding: 20px;
            border-radius: 20px;
            border: solid 3px rgb(0, 1, 43);
            background: white;
          }
        </style>
      </head>
      <body>
        <div class="chartCard">
          <div class="chartBox">
            <canvas id="myChart"></canvas>
            Start:
            <input id="start" type="date" value="" />
            End:
            <input id="end" type="date" value="" />
            <button onclick="filterDate()">Filter</button>
            <button onclick="resetDate()">Reset</button>
          </div>
        </div>
        <script
          type="text/javascript"
          src="https://cdn.jsdelivr.net/npm/chart.js"
        ></script>
        <script src="https://cdn.jsdelivr.net/npm/chartjs-adapter-date-fns/dist/chartjs-adapter-date-fns.bundle.min.js"></script>
    
        <script>

          const dates = [${clickDates}];
          const clickTimes = ${JSON.stringify(clickTimes)}
    
          const copyDates = [...dates];
          const clickArray = [];
          const clicksPerDay = {};
          copyDates.forEach(function (i) {
            clicksPerDay[i] = (clicksPerDay[i] || 0) + 1;
            clickArray.push(clicksPerDay[i]);
          });
    
          const data = {
            labels: dates,
            datasets: [
              {
                label: "Daily Clicks",
                data: clickArray,
                times:clickTimes,
                backgroundColor: "rgba(26, 24, 151, 0.2)",
                borderColor: "rgb(0, 1, 43)",
                borderWidth: 1
              }
            ]
          };
    
          const config = {
            type: "bar",
            data,
            options: {
              plugins:{
                tooltip:{
                    callbacks:{
                      title: (context) => {
                        const d = new Date(context[0].parsed.x);
                        const formattedDate = d.toLocaleString([],{
                            month:'short',
                            year:'numeric',
                            day:'numeric',
                        })
                        const t = clickTimes[context[0].dataIndex]

                        return formattedDate +' '+ t
                    }
                    }
                }
            },
              scales: {
                x: {
                  type: "time",
                  time: {
                    unit: "day"
                  }
                },
                y: {
                  ticks: {
                    stepSize: 1
                  },
                  beginAtZero: true
                }
              }
            }
          };
    

          const myChart = new Chart(document.getElementById("myChart"), config);
    
          function filterDate() {
            const start1 = new Date(document.getElementById("start").value);
            const start = start1.setHours(24, 0, 0, 0);
            const end1 = new Date(document.getElementById("end").value);
            const end = end1.setHours(24, 0, 0, 0);
    
            const filteredDates = dates.filter(
              (date) => date >= start && date <= end
            );
    
            myChart.config.data.labels = filteredDates;

    
            const filteredArray = [];
            const clicksPerDay = {};
            filteredDates.forEach(function (i) {
              clicksPerDay[i] = (clicksPerDay[i] || 0) + 1;
              filteredArray.push(clicksPerDay[i]);
            });
            myChart.config.data.datasets[0].data = filteredArray;
    
            myChart.update();
          }
    
          function resetDate() {
            myChart.config.data.labels = dates;
            myChart.config.data.datasets[0].data = clickArray;
    
            myChart.update();
          }
        </script>
      </body>
    </html>
    

    `;

    var storageRef = firebase.storage().ref();
    var db = firebase.firestore();

    const encodedString =
      "data:text/html;base64," + new Buffer(toHtml).toString("base64");
    storageRef
      .child(`${user.id}-graph.html`)
      .putString(encodedString, "data_url")
      .then((snapshot) => {
        storageRef
          .child(`${user.id}-graph.html`)
          .getDownloadURL()
          .then((url) => {
            db.collection("users")
              .doc(`${user.id}`)
              .update({
                graphUrl: `${url}`
              });
          });
      });
  }
};
