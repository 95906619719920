import React from "react";
import { MDBIcon } from "mdbreact";
import { Link } from "react-router-dom";

const SideNavLink = ({ toggleSideNav, route, icon, label }) => {
  return (
    <Link to={route} className="sidenav-link" onClick={toggleSideNav}>
      <MDBIcon icon={icon} size="2x" />
      <p className="sidenav-link__label">{label}</p>
    </Link>
  );
};

export default SideNavLink;
