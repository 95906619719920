export const compareAttribute = function (attr1, attr2) {
  let diffAttr = [];
  for (let key in attr1) {
    if (key !== "index" && key in attr2) {
      if (attr1[key] !== attr2[key]) {
        diffAttr.push(key);
      }
    }
  }
  return diffAttr;
};
