import React from "react";
import ReactDOM from "react-dom";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBIcon } from "mdbreact";
import GoogleButton from "react-google-button";
import { Link } from "react-router-dom";
import { signInWithGoogle } from "../../../utils/firebase/firebase.utils";
import { signInWithFacebook } from "../../../utils/firebase/firebase.utils";

import "./LoginModal.styles.scss";

const LoginModal = ({ toggleModal, isOpen }) => {
  return ReactDOM.createPortal(
    <MDBModal isOpen={isOpen} toggle={toggleModal} className="login-modal">
      <MDBModalHeader toggle={toggleModal}>
        Log In to Get Started!
      </MDBModalHeader>
      <MDBModalBody className="login-modal__body">
        {" "}
        <div className="auth-links">
          {/* <Link
            to="/register"
            style={{
              width: "240px",
              textDecoration: "none",
              margin: "5px",
              display: "flex"
            }}
            onClick={toggleModal}
            className="custom-button"
          >
            <MDBIcon icon="user-plus" /> &nbsp; Register
          </Link> */}

          <Link
            to="/login"
            style={{
              width: "240px",
              textDecoration: "none",
              margin: "5px"
            }}
            onClick={toggleModal}
            className="custom-button"
          >
            <MDBIcon icon="sign-in-alt" /> &nbsp; Log In
          </Link>

          <GoogleButton
            type="light"
            style={{
              fontFamily: "Roboto",
              margin: "5px",
              paddingLeft: '20px',
              paddingRight: '20px',
            }}
            onClick={signInWithGoogle}
          />

          <button
            style={{
              width: "240px",
              textDecoration: "none",
              margin: "5px"
            }}
            className="custom-button-fb"
            onClick={signInWithFacebook}
          >
            <MDBIcon size='2x' fab icon="facebook" /> &nbsp; &nbsp; &nbsp; Sign in with Facebook
          </button>

          <br />

          <div className="text-center">
            <Link onClick={toggleModal} to="/password-reset">Forgot Your Password?</Link>
          </div>
        </div>
      </MDBModalBody>
    </MDBModal>,
    document.getElementById("login-modal")
  );
};

export default LoginModal;
