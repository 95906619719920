export const toggleDesignState = (payload) => {
  return {
    type: "CANVAS/DESIGNING",
    payload
  };
};

export const setManager = (payload) => {
  return {
    type: "CANVAS/MANAGER",
    payload
  };
};

export const configViewport = (payload) => {
  return {
    type: "CANVAS/CONFIG_VIEWPORT",
    payload: payload
  };
};

export const zoom = (payload) => {
  return {
    type: "CANVAS/ZOOM",
    payload: payload
  };
};

export const pan = (payload) => {
  return {
    type: "CANVAS/PAN",
    payload: payload
  };
};

export const toggleToolbar = (payload) => {
  return {
    type: "TOOLBAR/TOGGLE",
    payload: payload
  };
};

export const changeDesignType = (payload) => {
  return {
    type: "CANVAS/DESIGNTYPE",
    payload: payload
  };
};
