import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { MDBIcon, MDBContainer, MDBModal, MDBModalBody } from "mdbreact";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import firebase from "../../utils/firebase/firebase.utils";

import SideNavLink from "./SideNavLink";
import LogoutModal from "./LogoutModal";
import LoginModal from "../../pages/landing/LoginModal/LoginModal";
import Switch from "@material-ui/core/Switch";

import "./SideNav.styles.scss";

class Sidenav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      loginModalOpen: false,
      QRmodal: false,
      checked: false
    };
  }

  componentDidUpdate() {
    if (this.props.currentUser && this.state.loginModalOpen) {
      this.toggleLoginModal();
    }
    var db = firebase.firestore();
    if (this.props.adminControl && this.state.checked === true) {
      db.collection("users").doc(`${this.props.userId}`).update({
        isEnterprise: true
      });
    }
    else if ((this.props.adminControl)) {
      db.collection("users").doc(`${this.props.userId}`).update({
        isEnterprise: false
      });
    } else{
      
    }
  }

  // toggleQR = () => {
  //   this.setState({
  //     QRmodal: !this.state.QRmodal
  //   });
  // };

  toggleModal = () => {
    this.setState((prevState) => ({ modalOpen: !prevState.modalOpen }));
  };

  toggleLoginModal = () => {
    this.setState((prevState) => ({
      loginModalOpen: !prevState.loginModalOpen
    }));
  };

  handleSwitchChange = () => {
    this.setState({ checked: !this.state.checked });
  };

  authenticatedLinks = (roleLinkRenderer) => (
    <div className="sidenav__nav-links">
      {this.props.currentUser.role === "fulfillment" ||
      this.props.currentUser.email === "americanrooterservices@gmail.com" ||
      this.props.currentUser.email === "rangewater@gmail.com" ||
      this.props.currentUser.email === "sugarcoatops@gmail.com" ||
      this.props.currentUser.email === "marmenta@galleryresidential.com" ||
      this.props.currentUser.email === "ksarvis@columbiares.com" ? null : (
        <>
          
            {/* <div className="display-QR" onClick={() => this.toggleQR()}>
              <MDBIcon icon="qrcode" />{" "}
              <p className="display-QR__QRlabel">QR CODE</p>
            </div>
         
          <MDBContainer>
            <MDBModal isOpen={this.state.QRmodal} toggle={this.toggleQR}>
              
                
                  <img
                    // style={{ width: "90%", float: "right" }}
                    src={
                      this.props.customQrImg
                        ? this.props.customQrImg
                        : this.props.currentUser?.QrImg
                    }
                    alt="Qr"
                  />
                
              
            </MDBModal>
          </MDBContainer> */}

          <SideNavLink
            toggleSideNav={this.props.toggleSideNav}
            route="/contact-info"
            icon="address-card"
            label="Profile"
          />
          <SideNavLink
            toggleSideNav={this.props.toggleSideNav}
            route="/shop"
            icon="store"
            label="Shop"
          />
        </>
      )}

      {roleLinkRenderer()}

      {this.props.adminControl ?
      <Switch
        size="small"
        checked={this.state.checked}
        onClick={() => this.handleSwitchChange()}
      />
      :null}

      <div className="sidenav-link sidenav-link--login-logout">
        <MDBIcon
          icon="sign-out-alt"
          size="2x"
          onClick={() => {
            this.toggleModal();
          }}
        />
        <p className="sidenav-link__label">Log Out</p>
      </div>
    </div>
  );

  renderLinks = () => {
    return this.props.currentUser ? (
      this.authenticatedLinks(this.renderRoleLink)
    ) : (
      <div className="sidenav__nav-links">
        {/* <SideNavLink
          toggleSideNav={this.props.toggleSideNav}
          route="/device-compatibility"
          icon="mobile-alt"
          label="Devices"
        /> */}
        <SideNavLink
          toggleSideNav={this.props.toggleSideNav}
          route="/contact-us"
          icon="envelope-open"
          label="Contact Us"
        />

        <div className="sidenav-link sidenav-link--login-logout">
          <MDBIcon
            icon="sign-in-alt"
            size="2x"
            onClick={() => {
              this.toggleLoginModal();
            }}
          />
          <p className="sidenav-link__label">Log In</p>
        </div>
      </div>
    );
  };

  renderRoleLink = () => {
    switch (this.props.currentUser.role) {
      case "enterprise":
        return (
          <SideNavLink
            toggleSideNav={this.props.toggleSideNav}
            route="/enterprise"
            icon="star"
            label="Enterprise"
          />
        );
      case "admin":
        return (
          <SideNavLink
            toggleSideNav={this.props.toggleSideNav}
            route="/admin"
            icon="star"
            label="Admin"
          />
        );
      case "sales admin":
        return (
          <SideNavLink
            toggleSideNav={this.props.toggleSideNav}
            route="/salesadmin"
            icon="star"
            label="Admin"
          />
        );
      case "sales":
        return (
          <SideNavLink
            toggleSideNav={this.props.toggleSideNav}
            route="/sales"
            icon="star"
            label="Sales"
          />
        );
      default:
        return null;
    }
  };

  render() {
    const routes = window.location.href.split("/");

    if (routes.includes("design")) {
      return null;
    }

    return ReactDOM.createPortal(
      <div
        className={`sidenav ${this.props.hidden ? "sidenav--hidden" : ""}`}
        ref={this.props.forwardedRef}
      >
        <LogoutModal
          isOpen={this.state.modalOpen}
          toggleModal={this.toggleModal}
          history={this.props.history}
          toggleSideNav={this.props.toggleSideNav}
        />
        <LoginModal
          isOpen={this.state.loginModalOpen}
          toggleModal={this.toggleLoginModal}
        />
        <Link to={this.props.currentUser ? "/contact-info" : "/"}>
          <img
            src={require("../../assets/mytap_white__transparent.png")}
            alt="Small MyTap logo"
            className="sidenav__logo"
          />
        </Link>
        {this.renderLinks()}
      </div>,
      document.getElementById("sidenav")
    );
  }
}

// const mapStateToProps = ({ user: { currentUser } }) => ({ currentUser });

const mapStateToProps = (state, ownProps) => {
  // console.log('dddddd',state.designImages[0])
  return {
    currentUser: state.user?.currentUser,
    userId: state.user.currentUser?.uid,
    adminControl: state.user.currentUser?.adminControl,
    customQrImg: state?.designImages[0]?.customQrImg
  };
};

const ConnectedSideNav = withRouter(connect(mapStateToProps)(Sidenav));

export default React.forwardRef((props, ref) => (
  <ConnectedSideNav {...props} forwardedRef={ref} />
));
