import React, { Component } from "react";
import Select from "react-select";
import { phoneOptions } from "./PhoneData";

import { connect } from "react-redux";
import { addObject, updateObject } from "../../../redux/object/object.actions";

class PhoneCase extends Component {
  componentDidMount() {}

  componentDidUpdate() {}

  selectPhoneCase = (selectedOption) => {
    var ImageURL =
      window.location.origin +
      "/assets/images/PhoneCase/" +
      selectedOption.value +
      "/PNG File/sample (1).png";
    this.props.changeCase(ImageURL, selectedOption.value);
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "2px solid black",
        backgroundColor: state.isSelected ? "gray" : "white",
        position: "relative !important"
      }),
      control: (provided) => ({
        ...provided,
        marginTop: "5%"
      })
    };

    return (
      <Select
        styles={customStyles}
        className="phonecase basic-single mt-3"
        classNamePrefix="select"
        defaultValue={phoneOptions[0]}
        options={phoneOptions}
        onChange={this.selectPhoneCase}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    objects: state.objects
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addPhoneCase: (payload) => {
      dispatch(addObject(payload));
    },
    updateObject: (payload) => {
      dispatch(updateObject(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneCase);
