export const addObject = (payload) => {
  return {
    type: "OBJECT/ADD",
    payload
  };
};

export const updateSequence = (payload) => {
  return {
    type: "OBJECT/SEQUENCE",
    payload
  };
};

export const selectedObject = (payload) => {
  return {
    type: "OBJECT/SELECTED",
    payload
  };
};

export const releasedObject = (payload) => {
  return {
    type: "OBJECT/RELEASED",
    payload
  };
};

export const updateObject = (payload) => {
  return {
    type: "OBJECT/UPDATE",
    payload
  };
};

export const deleteObject = (payload) => {
  return {
    type: "OBJECT/DELETE",
    payload
  };
};

export const setObject = (payload) => {
  return {
    type: "OBJECT/SET",
    payload
  };
};
