import React from "react";
import { connect } from "react-redux";
import {
  MDBMedia,
  MDBCard,
  MDBContainer,
  MDBCardBody,
  MDBModalBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBTooltip,
  MDBCardFooter,
  MDBBtn,
  MDBLink,
  MDBIcon
} from "mdbreact";

import firebase from "../../utils/firebase/firebase.utils";

import { defaultRedirect } from "../../redux/routing/routing.actions";
import { fetchDesigns } from "../../redux/designImages/designImages.actions";

import API2 from "../../components/generateQr/generateGoogleQr";
import API3 from "../../components/renderDirectCardImage/renderGoogleReviewCardImage";

import { Carousel } from "react-bootstrap";
import UploadLogo from "../../components/upload-your-logo/uploadLogo";
import CustomGoogleQr from "../../components/customGoogleQr/customGoogleQr";
import { CircularProgress, Box } from "@material-ui/core";

// Stripe
import { ElementsConsumer } from "@stripe/react-stripe-js";

//

import {
  createSession,
  createStripeCustomer
} from "../../utils/firebase/firebase.utils";
import { updateUser } from "../../redux/user/user.actions";
import "./RMO.styles.scss";
import CardBack from "./assets/images/IDCard/card-back copy.png";
import fiveStar from "./assets/images/IDCard/fivestar.png";
import cardBackPlaceholder from "./assets/images/IDCard/mytapQr.png";



class GoogleReview extends React.Component {
  state = {
    MyTapReviewCardURL: "",
    loading: false,
    customQrCode: false,
    saveBtn: "SAVE",
    selectedIndex: "",
  };



  onDirectURLChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  customQrCheckbox = (event) => {
    var db = firebase.firestore();
    // console.log(event.target.name, event.target.checked);
    const { name, checked } = event.target;
    this.setState({ [name]: checked });

    if (this.state.customQrCode === false) {
      return db.collection("users").doc(`${this.props.userId}`).update({
      customGoogleQrImg: firebase.firestore.FieldValue.delete()
    });
    }
  };


  handleSelect = (selectedIndex) => {
    this.setState({
      selectedIndex: selectedIndex
    });
  };

  saveMyTapReviewCardURLToDB(e) {
    var db = firebase.firestore();

    this.state.customQrCode = false 

    db.collection("users").doc(`${this.props.userId}`).update({
      customGoogleQrImg: firebase.firestore.FieldValue.delete()
    });
  
    db.collection("users").doc(`${this.props.userId}`).update({
      MyTapReviewCardURL: 
      this.state.MyTapReviewCardURL.includes("http://"||"https://")
        ? this.state.MyTapReviewCardURL
        : `https://${this.state.MyTapReviewCardURL}`
    }).then(()=> {
      setTimeout(() => {
        API2.generateQR();
      }, 500);
    })
    
    this.setState({ saveBtn: "SAVED!" });
    setTimeout(() => {
      this.setState({ saveBtn: "SAVE" });
    }, 3000);

  }


  generateAndBuy(e) {
    // setTimeout(() => {
    //   API2.generateQR();
    // }, 500);

    setTimeout(() => {
      API3.renderDirectCardImage();
    }, 1000);
  }

  render() {
    const { currentUser } = this.props.user;

    if (this.state.loading)
      return (
        <Box mt={10} textAlign="center">
          <CircularProgress />
        </Box>
      );

    return (
    
          <div >
          <MDBCardTitle>
                <strong><span>Single Link Card</span></strong>
              </MDBCardTitle>
          <MDBCardText>
                
                  <label>URL: &nbsp;</label>
                  <input
                    style={{ width: "70%" }}
                    onChange={this.onDirectURLChange.bind(this)}
                    onBlur={this.saveMyTapReviewCardURLToDB.bind(this)}
                    // disabled={this.props.orderPlaced}
                    type="text"
                    name="MyTapReviewCardURL"
                    placeholder={this.props.MyTapReviewCardURL}
                    value={this.state.MyTapReviewCardURL}
                  ></input>
                  <p> Current order: {this.props.MyTapReviewCardURL}</p>
              </MDBCardText>

            <Carousel
              activeIndex={this.index}
              onSelect={this.handleSelect}
              controls={true}
              interval={null}
              variant="dark"
            >
              <Carousel.Item className="p-4">
              
                 <img
                  className="shop-item__image shop-item__image--card"
                  alt="card"
                  src={CardBack}
                />
                <img
                  style={{
                    width: "32.5%",
                    position: "absolute",
                    top: "21%",
                    left: "34%"
                  }}
                  alt="Qr"
                  src={
                    this.state.customQrCode === true && this.props.customGoogleQrImg ? this.props.customGoogleQrImg 
                    : this.props.GoogleQrImg ? this.props.GoogleQrImg : cardBackPlaceholder
                  }
                />
               
                
              </Carousel.Item>

              <Carousel.Item className="p-4">
                
                <div className="shop-item__image shop-item__image--card">
                <img
                  className="shop-item__image shop-item__image--cardIMG"
                  alt="card"
                  src={fiveStar}
                />
                </div>
  
              </Carousel.Item>
            </Carousel>
              
        

            <MDBCardBody cascade className="text-center">
              
              <MDBCardText>
               {this.props.MyTapReviewCardURL && this.props.MyTapReviewCardURL.length > 10 ?
                  <div>
                    <input
                      onChange={this.customQrCheckbox}
                      name="customQrCode"
                      type="checkbox"
                      className="checkbox"
                      checked={this.state.customQrCode}
                    ></input>
                    <label>Custom QR Code?</label>
                  </div>
                  : <null/>
               }

                  {this.state.customQrCode === true ? (
                    <MDBTooltip placement="top">
                      <MDBBtn color="transparent" className="p-1 m-0 z-depth-0">
                        <CustomGoogleQr/>
                      </MDBBtn>
                      <div>Create your custom QR-Code!</div>
                    </MDBTooltip>
                  ) : null}
        
              </MDBCardText>
              
              <MDBCardFooter>
                <span className="float-left">
                  <div className="shop-item__price">$20</div>
                </span>
                <span className="float-right">
                  <MDBTooltip placement="top">
                    <MDBBtn color="transparent" className="p-1 m-0 z-depth-0">
                      <ElementsConsumer>
                        {({ stripe, elements }) => (
                          <form
                            onSubmit={async (e) => {
                              this.setState({ loading: true });
                              e.preventDefault();
                              this.generateAndBuy();
                              if (!currentUser.stripeCustomerId) {
                                return await createStripeCustomer(
                                  currentUser
                                ).then(async () => {
                                  const session = await createSession(
                                    "MyTap SL Review Card",
                                    currentUser.uid
                                  );
                                  stripe.redirectToCheckout({
                                    sessionId: session.data.id
                                  });
                                  console.log("session", session);
                                });
                              } else {
                                const session = await createSession(
                                  "MyTap SL Review Card",
                                  currentUser.uid
                                );
                                stripe.redirectToCheckout({
                                  sessionId: session.data.id
                                });
                                console.log("session", session);
                              }
                            }}
                          >
                            <MDBBtn
                              disabled={
                                this.state.MyTapReviewCardURL.length < 4
                              }
                              color="transparent"
                              className="p-1 m-0 z-depth-0"
                              type="submit"
                            >
                              <MDBIcon fas size="lg" icon="shopping-cart" />
                            </MDBBtn>
                     

                          </form>
                        )}
                      </ElementsConsumer>
                    </MDBBtn>
                    <div>Order!</div>
                  </MDBTooltip>
                </span>
              </MDBCardFooter>
            </MDBCardBody>
          </div>
   
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log('dddddd',this.props.user.id)
  // console.log('bbbbbb',this.props.currentUser)
  // console.log('selectedUser',state.selectedUser.currentUser?.uid)

  return {
    reviewCardUploadedLogo:
      state.user.currentUser?.reviewCardUploadedLogo,
    MyTapReviewCardURL: state.user.currentUser?.MyTapReviewCardURL,
    GoogleQrImg: state.user.currentUser?.GoogleQrImg,
    customGoogleQrImg: state.user.currentUser?.customGoogleQrImg,
    reviewCardImage: state.user.currentUser?.reviewCardImage,
    shortUrl: state.user.currentUser?.shortUrl,
    orderPlaced: state.user.currentUser?.orderPlaced,
    user: state.user,
    userId: state.user.currentUser?.uid,
    currentUser: state.user?.currentUser,
    
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateUser: (payload) => {
      dispatch(updateUser(payload));
    },
    defaultRedirect: () => {
      dispatch(defaultRedirect());
    },
    fetchDesigns: (payload) => {
      dispatch(fetchDesigns(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleReview);
