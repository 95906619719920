import React, { Component } from "react";
import { connect } from "react-redux";
import { MDBIcon, MDBContainer, MDBRow, MDBNavbarBrand } from "mdbreact";
import { withRouter } from "react-router-dom";

import ItemPanel from "../design-tools/ItemPanel";
import PropertyPanel from "../properties";
import FontAwesome from "react-fontawesome";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

import { toggleToolbar } from "../../redux/drawing-area/drawing-area.actions";
import firebase from "../../utils/firebase/firebase.utils";

import "./ContentPanel.styles.css";

class ContentPanel extends Component {
  state = {
    kindPanel: "",
    activeAdd: false
  };
  changePanel(pKind, ev) {
    this.setState({ kindPanel: pKind });
    // ev.stopPropagation();
    this.props.toggleToolbar("show");
  }

  showItems() {
    this.setState({ activeAdd: !this.state.activeAdd });
  }

  addItem(pKind) {
    this.showItems();
    this.changePanel(pKind);
  }

  saveButton() {
    return (
      <MDBNavbarBrand
        className="saveDesign propertyItem"
        onClick={(e) => this.saveDesign()}
      >
        <div className="propIcon">
          <FontAwesome name="save" />
        </div>
        <div> Save</div>
      </MDBNavbarBrand>
    );
  }

  getLaptopPanel() {
    return (
      <MDBContainer fluid className="leftsidebar">
        {/* <div className="contentPanel stylish-color pt-2" onClick = {e=>this.changePanel('', e)}>                     */}
        <div className="contentPanel stylish-color pt-2">
          {/* <button className="stylish-color" data-tab="phone" onClick = {e=>this.changePanel('phone',e)} disabled={this.props.designState}>
                      <MDBIcon icon="mobile-alt" size="2x"/>
                      <div>Mobile</div>
                  </button> */}
          <button
            className="stylish-color"
            data-tab="image"
            onClick={(e) => this.changePanel("image", e)}
            disabled={!this.props.designState}
          >
            <MDBIcon icon="image" size="2x" />
            <div>Image</div>
          </button>
          <button
            className="stylish-color"
            data-tab="text"
            onClick={(e) => this.changePanel("text", e)}
            disabled={!this.props.designState}
          >
            <MDBIcon icon="font" size="2x" />
            <div>Text</div>
          </button>
          <button
            className="stylish-color"
            data-tab="shape"
            onClick={(e) => this.changePanel("shape", e)}
            disabled={!this.props.designState}
          >
            <MDBIcon icon="shapes" size="2x" />
            <div>Shape</div>
          </button>
          <button
            className="stylish-color"
            data-tab="layer"
            onClick={(e) => this.changePanel("layer", e)}
            disabled={!this.props.designState}
          >
            <MDBIcon icon="layer-group" size="2x" />
            <div>Layer</div>
          </button>
        </div>
        <ItemPanel activeTool={this.state.kindPanel} />
        <PropertyPanel />
      </MDBContainer>
    );
  }

  getMobilePanel() {
    let showItemPanel =
      this.props.lastAction !== "OBJECT/RELEASED" &&
      this.props.lastAction !== "OBJECT/SELECTED" &&
      this.props.lastAction !== "OBJECT/UPDATE";
    let showPropPanel =
      this.props.lastAction === "OBJECT/SELECTED" ||
      this.props.lastAction === "OBJECT/UPDATE";
    // let propHeight = window.innerHeight - 64 - this.props.drawingH - 35 - 66;
    let propHeight = 48 * 4.5;
    return (
      <MDBContainer
        fluid
        className={"toolbar" + (this.state.activeAdd ? " active" : "")}
      >
        <MDBRow className="menuPanel">{this.saveButton()}</MDBRow>
        <MDBRow
          className={
            "setPropertyPanel" + (this.state.activeAdd ? " active" : "")
          }
          style={{ height: this.props.drawingH + "px" }}
        >
          <MDBRow
            className={
              "containerItems" + (this.state.activeAdd ? " active" : "")
            }
          >
            <MDBContainer
              className="itemImage"
              onClick={(e) => this.addItem("image")}
            >
              <button data-tab="layer">
                <MDBIcon icon="image" size="2x" />
              </button>
              <div>Image</div>
            </MDBContainer>
            <MDBContainer
              className="itemText"
              onClick={(e) => this.addItem("text")}
            >
              <button data-tab="layer">
                <MDBIcon icon="font" size="2x" />
              </button>
              <div>Text</div>
            </MDBContainer>
            <MDBContainer
              className="itemShape"
              onClick={(e) => this.addItem("shape")}
            >
              <button data-tab="layer">
                <MDBIcon icon="shapes" size="2x" />
              </button>
              <div>Shape</div>
            </MDBContainer>
          </MDBRow>
        </MDBRow>
        <MDBRow className="kindItemPanel">
          <MDBRow
            className={"showItems" + (this.state.activeAdd ? " active" : "")}
          >
            <button data-tab="layer" onClick={(e) => this.showItems()}>
              <MDBIcon icon="plus" size="2x" />
            </button>
          </MDBRow>

          {showItemPanel && <ItemPanel activeTool={this.state.kindPanel} />}
          {showPropPanel && <PropertyPanel height={propHeight} />}
        </MDBRow>
      </MDBContainer>
    );
  }

  async saveDesign() {
    console.log("save");
    let imgData = await this.props.sceneManager.getCropImage();

 
      var database = firebase.database();
      database.ref(`designs/ + ${this.props.userId}`).update({
        [this.props.designType]: imgData
      })

    this.props.history.push("/shop");
  }

  render() {
    return (
      <>
        {!this.props.mobile && this.getLaptopPanel()}
        {this.props.mobile && this.getMobilePanel()}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    designState: state.designState,
    designType: state.designType.type,
    phoneCase: state.designType.phoneCase,
    mobile: state.mobile,
    lastAction: state.lastAction,
    sceneManager: state.sceneManager,
    userId: state.user?.currentUser?.id,
    googleUserId: state.user?.currentUser?.uid
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleToolbar: (action) => {
      dispatch(toggleToolbar(action));
    }
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContentPanel)
);
