import React, { useEffect, useState, useRef } from 'react'
import { connect,useDispatch } from "react-redux";
import { setSelectedUser } from "../../redux/selectedUser/selectedUser.actions";
import { useParams } from "react-router-dom"
import firebase from "../../utils/firebase/firebase.utils"
import RangeWaterMultiOrder from '../rangewater-multiOrder/rangewaterMultiOrder'
import RangewaterAdminOrders from '../rangewater-admin-orders/rangewaterAdminOrders'
import SugarcoatAdminOrders from '../sugarcoat-admin-orders/sugarcoatAdminOrders'
import { withRouter } from "react-router-dom";
import { fetchDesigns } from "../../redux/designImages/designImages.actions";

function EditEnterpriseUserPage({ explicitUser, ...props }) {
    // console.log("EEUP: props: " + JSON.stringify(explicitUser))
    const { id: userId } = useParams()
    const [user, setUser] = useState(explicitUser)
    const dispatch = useDispatch()


    let isRendered = useRef(false);

    useEffect(() => {
        // console.log("EEUP#useEffect")

        isRendered = true;
        firebase.firestore()
            .collection('users')
            .doc(explicitUser?.id || userId).get().then(user => {
                if (isRendered) {
                    // console.log("EEUP#setUser",explicitUser)
                    setUser({ ...user.data(), id: user.id })
                    dispatch(setSelectedUser(explicitUser))
                }
                return null
            }).catch(err => console.log(err));
            return () => {
                isRendered = false;
            }
    }, [userId, explicitUser])

    const augmentedProps = { fetchDesigns, user, ...props }
    const FormPageWithRouter = withRouter(RangewaterAdminOrders)
    // console.log(user)


    return (
        // <div>
        //     { user && <FormPageWithRouter {...augmentedProps} />}
        //     { !user && <p>Please wait...</p>}
        // </div>
        // <RangeWaterMultiOrder/>
        
        user.creatorId === "vXEfevg5L4XgUOqxNGB3j8wJURd2" 
        // || "9Q6Xdwa7R6SaPuqSOFEY1O8PgOH2" 
        ? <SugarcoatAdminOrders/> : <RangewaterAdminOrders/>
        
    )

}


// const mapDispatchToProps = (dispatch) => ({
//     setSelectedUser: (user) => dispatch(setSelectedUser(user))
//   });
  
export default EditEnterpriseUserPage

// export default connect(null, mapDispatchToProps)(EditEnterpriseUserPage);


