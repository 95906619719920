import React, { Component } from "react";
import { MDBNavbar, MDBIcon, MDBModal, MDBContainer } from "mdbreact";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./NavHeader.styles.scss";

class NavHeader extends Component {
  state = {
    collapseID: "",
    QRmodal: false,
  };


  toggleCollapse = (collapseID) => () =>
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));

    toggleQR = () => {
      this.setState({
        QRmodal: !this.state.QRmodal
      });
    };

  renderIcon = () => {
    const routes = window.location.href.split("/");

    if (routes.includes("design")) {
      return (
        <div className="backbtn-container">
          <MDBIcon
            icon="arrow-left"
            size="2x"
            className="nav-backbtn"
            onClick={() => this.props.history.goBack()}
          />
        </div>
      );
    } else {
      return (
        <MDBIcon
          icon="bars"
          size="2x"
          className="sidenav-toggler"
          onClick={this.props.toggleSideNav}
        />
      );
    }
  };

  render() {
    return (
      <MDBNavbar className="mytap-navbar d-flex justify-content-end" color="unique-color-dark navbar" dark>
        {this.renderIcon()}

        {!this.props.currentUser || 
        this.props.currentUser.role === "fulfillment" ||
        this.props.currentUser.email === "americanrooterservices@gmail.com" ||
        this.props.currentUser.email === "rangewater@gmail.com" ||
        this.props.currentUser.email === "sugarcoatops@gmail.com" ||
        this.props.currentUser.email === "marmenta@galleryresidential.com" ||
        this.props.currentUser.email === "ksarvis@columbiares.com" ? 

        (<span className="brand">MyTap.net</span>) 
        : 
          <span className="display-QR" onClick={() => this.toggleQR()}>
            <MDBIcon icon="qrcode" />{" "}
          </span>
        }


        <MDBModal isOpen={this.state.QRmodal} toggle={this.toggleQR}>
        
          
        <img
          // style={{ width: "90%", float: "right" }}
          src={
            this.props.customQrImg
              ? this.props.customQrImg
              : this.props.currentUser?.QrImg
          }
          alt="Qr"
        />
      
    
  </MDBModal>

      </MDBNavbar>
      
      
      
    
    );
  }
}

// const mapStateToProps = ({ user: { currentUser } }) => ({ currentUser });

const mapStateToProps = (state, ownProps) => {
  // console.log('dddddd',state.designImages[0])
  return {
    currentUser: state.user?.currentUser,
    customQrImg: state?.designImages[0]?.customQrImg,
  };
};


export default withRouter(connect(mapStateToProps)(NavHeader));
