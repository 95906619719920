import React from "react";
import ReactDOM from "react-dom";
import store from "../../redux/store";

import ReactQr from "react-awesome-qr";

import firebase from "../../utils/firebase/firebase.utils";

import MyLogo from "../../assets/logo5.png";

export default {
  generateMultiUserQR: function () {
    var db = firebase.firestore();
    var storageRef = firebase.storage().ref();
    var database = firebase.database();

    // const MyCardDirectURL = store.getState().user.currentUser.MyCardDirectURL;
    const userId = store.getState().selectedUser.currentUser.id;
    const shortUrl = store.getState().selectedUser.currentUser.shortUrl;


    // console.log('eowedwedcewsecdwswcsdcswdcs',userId)

    ReactDOM.render(
      <ReactQr
        text={
          shortUrl && shortUrl.length > 4 ? shortUrl : ""
        }
        correctLevel={3}
        size={700}
        dotScale={0.7}
        // MyTapReviewCardURL.length > 4 ? null :
        bgSrc={
          null
        }
        callback={
          (data) =>
            // console.log(data)

            db
              .collection("users")
              .doc(`${userId}`)
              .update({
                QrImg: `${data}`
              })
              .then(() => {
                storageRef
                  .child(`${userId}, QRCODE.png`)
                  .putString(data, "data_url");
              })
              .then(() => {
                storageRef
                  .child(`${userId}, QRCODE.png`)
                  .getDownloadURL()
                  .then((url) => {
                    database.ref(`designs/ + ${userId}`).update({
                      QrImgUrl: url
                    })
                  });
              })

          // storageRef
          //   .child(`${this.props.userId}, QRCODE.png`)
          //   .putString(data, "data_url")
          //   .then(function (snapshot) {
          //     // alert("QR SENT");
          //   })
        }
      />,
      document.getElementById("yo")
    );
  }
};
