import React from 'react';
import { connect } from 'react-redux';

import Image from "./uploadimage";
import Text from "./text";
import Shape from "./shape";
import Layer from "./layer";

// import { toggleDesignState, toggleToolbar } from '../DrawingAreaReducer';

class ItemPanel extends React.Component{
    state = {
        statePanel : 'hide',
    }

    // hidePanel(){
    //     this.props.toggleDesignState(true);
    //     this.props.toggleToolbar('hide');
    // }

    // addedPhoneCase(){
    //     this.setState({addedPhone:true})
    // }

    UNSAFE_componentWillReceiveProps(newProps){
        this.updatePanelState('show', newProps);
    }

    UNSAFE_componentWillMount(){
        this.updatePanelState();
    }

    updatePanelState(pHide='show', newProps=undefined){
        let state = 'hide';
        if(newProps!==undefined){
            if(newProps.activeTool!=='layer'){
                state = newProps.stateToolbar;
            }else{
                state = pHide;

            }
        }else{
            if(this.props.activeTool!=='layer'){
                state = this.props.stateToolbar;
            }else{
                state = pHide;
            }
        }
        this.setState({statePanel:state});
    }

    render (){
        return (
            <div className={"itemPanel " + this.state.statePanel + (this.props.mobile ? " mobile":"")}>
                {this.props.activeTool==="image" && <Image />}
                {this.props.activeTool==="text" && <Text />}
                {this.props.activeTool==="shape" && <Shape />}
                {this.props.activeTool==="layer" && <Layer />}
                {this.props.activeTool==="layer" && <button className="hideItemPanel" onClick={e=>this.updatePanelState('hide')} >Hide</button> }
            </div>
        )
        
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        stateToolbar : state.stateToolbar,
        mobile : state.mobile,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        // toggleDesignState : (payload)=>{
        //     dispatch(toggleDesignState(payload))
        // },
        // toggleToolbar : (payload)=>{
        //     dispatch(toggleToolbar(payload))
        // }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemPanel);