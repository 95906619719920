import React from "react";
import { connect } from "react-redux";

import { designOn } from "../../redux/observe";
import {
  setManager,
  configViewport,
  zoom,
  pan,
  toggleToolbar
} from "../../redux/drawing-area/drawing-area.actions";
import {
  selectedObject,
  releasedObject,
  updateObject
} from "../../redux/object/object.actions";

import DesignTool from "../design-canvas/DesignCanvas";
import EntityObject from "../design-tools/entityObject";

import "./DrawingArea.css";

class DrawingArea extends React.Component {
  UNSAFE_componentWillMount() {
    designOn("open", this.openScene.bind(this));
  }

  componentDidMount() {
    let manager = new DesignTool.SceneManager({
      canvas: this.c,
      width: this.props.width,
      height: this.props.height,
      message: this.messageFromScene.bind(this)
    });
    this.props.setManager(manager);
    this.props.configViewport({
      canvasViewport: {
        x: 0,
        y: 0,
        width: this.props.width,
        height: this.props.height
      }
    });
  }

  componentDidUpdate() {
    if (
      !!this.props.sceneManager &&
      (this.props.width !== this.props.sceneManager.width ||
        this.props.height !== this.props.sceneManager.height)
    ) {
      this.props.sceneManager.resize(this.props.width, this.props.height);
      this.props.configViewport({
        canvasViewport: {
          x: 0,
          y: 0,
          width: this.props.width,
          height: this.props.height
        }
      });
    }
  }

  messageFromScene(pData) {
    let oneself = this;
    switch (pData.type) {
      case "updateAttribute":
        this.props.updateObject(pData.data);
        break;
      case "bindEvent":
        let fabricObject = pData.data;
        fabricObject.on("selected", function () {
          oneself.props.objects.forEach((obj) => {
            if (obj.id === fabricObject.id) {
              oneself.props.selectedObject(obj);
            }
          });
        });

        fabricObject.on("deselected", function () {
          oneself.props.releasedObject();
        });

        fabricObject.on("rotated", function () {
          oneself.props.updateObject({
            id: fabricObject.id,
            angle: fabricObject.angle,
            left: fabricObject.left,
            top: fabricObject.top
          });
        });

        fabricObject.on("moved", function () {
          oneself.props.updateObject({
            id: fabricObject.id,
            left: fabricObject.left,
            top: fabricObject.top
          });
        });

        fabricObject.on("scaled", function () {
          oneself.props.updateObject({
            id: fabricObject.id,
            scaleX: fabricObject.scaleX,
            scaleY: fabricObject.scaleY,
            left: fabricObject.left,
            top: fabricObject.top
          });
        });
        break;
      default:
    }
  }

  openScene() {
    if (!!this.props.sceneManager) this.props.sceneManager.clearScene();
  }

  createChildren() {
    if (
      !!this.props.sceneManager &&
      (this.props.sceneManager.width !== this.props.width ||
        this.props.sceneManager.height !== this.props.height)
    ) {
      this.props.sceneManager.resize(this.props.width, this.props.height);
    }

    const children = [];
    this.props.objects.forEach((object, i) => {
      let child = null;
      child = React.createElement(EntityObject, {
        attr: object,
        key: i
      });

      child && children.push(child);
    });

    return children;
  }

  hideToolbar() {
    // console.log(this.props.sceneManager.canvas.getObjects());
    this.props.toggleToolbar("hide");
  }

  render() {
    let chidren = this.createChildren();
    return (
      // <div className="drawingArea" onClick={e=>this.hideToolbar()} style = {{ marginTop : this.props.top+'px', position:'absolute' }} >
      <div
        className="drawingArea p-0"
        onClick={(e) => this.hideToolbar()}
        style={{
          top: this.props.top + "px",
          width: this.props.width + "px",
          height: this.props.height + "px"
        }}
      >
        <canvas
          ref={(c) => (this.c = c)}
          width={this.props.width}
          height={this.props.height}
        />
        {this.props.sceneManager && chidren}
      </div>
    );
  }
}

const mapStateToProps = function mapStateToProps(state) {
  return {
    designState: state.designState,
    sceneManager: state.sceneManager,
    objects: state.objects,
    activeObject: state.activeObject,
    canvas: state.canvas
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    configViewport: (payload) => {
      dispatch(configViewport(payload));
    },
    zoom: (payload) => {
      zoom(payload);
    },
    pan: (payload) => {
      pan(payload);
    },
    updateObject: (payload) => {
      dispatch(updateObject(payload));
    },
    selectedObject: (payload) => {
      dispatch(selectedObject(payload));
    },
    releasedObject: (payload) => {
      dispatch(releasedObject(payload));
    },
    setManager: (payload) => {
      dispatch(setManager(payload));
    },
    toggleToolbar: (payload) => {
      dispatch(toggleToolbar(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawingArea);
