import store from "../../redux/store";
import firebase from "../../utils/firebase/firebase.utils";

import RangewaterCardTemplate from "./imgs/RW3.png";

export default {
  renderDirectCardImage: async function () {
    var db = firebase.firestore();
    const GoogleQrImg = store.getState().user.currentUser?.GoogleQrImg;
    const reviewCardUploadedLogo = store.getState().user.currentUser?.reviewCardUploadedLogo;
    const userId = store.getState().user.currentUser.id;


    // var canvas = document.getElementById("canvas1");
    // if (!canvas || !canvas.getContext) {
    //   return false;
    // }
    // canvas.width = 2.5*445;
    // canvas.height = 2.5*280;
    // var ctx = canvas.getContext("2d");
    // var img1 = new Image();
    // var img2 = new Image();
    // var img3 = new Image();
    // img1.src = RangewaterCardTemplate;
    // img2.src = reviewCardUploadedLogo;
    // img3.src = QrImg;
    // img1.onload = function () {
    //   ctx.drawImage(img1, 0, 0, 2.5*445, 2.5*280);
    // };
    // img2.onload = function () {
    //   ctx.drawImage(img2, 2.5*235, 2.5*56, 2.5*172, 2.5*172);
    // };
    // img3.onload = function () {
    //   ctx.drawImage(img3, 2.5*38, 2.5*53, 2.5*175, 2.5*175);
    // };


    // setTimeout(() => {
    //   var fullQuality = canvas.toDataURL();

    //   db.collection("users")
    //     .doc(`${userId}`)
    //     .update({
    //       reviewCardImage: `${fullQuality}`
    //     });
    // }, 1000);


    var canvas = document.getElementById("canvas1");
    if (!canvas || !canvas.getContext) {
      return false;
    }
    canvas.width = 2.5*445;
    canvas.height = 2.5*280;
    var ctx = canvas.getContext("2d");
    var img1 = new Image();
    var img2 = new Image();
    var img3 = new Image();
    img1.src = RangewaterCardTemplate;
    img2.src = reviewCardUploadedLogo;
    img3.src = GoogleQrImg;


    await Promise.all([
      new Promise((resolve, reject) => {
        img1.onload = function () {
          ctx.drawImage(img1, 0, 0, 2.5*445, 2.5*280);
          resolve();
        };
      }),
      new Promise((resolve, reject) => {
        img2.onload = function () {
          ctx.drawImage(img2, 2.5*235, 2.5*56, 2.5*172, 2.5*172);
          resolve();
        };
      }),
      new Promise((resolve, reject) => {
        img3.onload = function () {
          ctx.drawImage(img3, 2.5*38, 2.5*53, 2.5*175, 2.5*175);
          resolve();
        };
      })
    ]);


    
      var fullQuality = canvas.toDataURL();

      await db.collection("users")
        .doc(`${userId}`)
        .update({
          reviewCardImage: `${fullQuality}`
        });

  }
};
