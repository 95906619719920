import React, { Component } from "react";
import { Button } from "react-bootstrap";
import FontAwesome from "react-fontawesome";

import { connect } from "react-redux";
import { addObject } from "../../../redux/object/object.actions";

class Text extends Component {

  componentDidMount() {}

  componentDidUpdate() {}

  addTextItem(){
    let payloadObject = {
      id : Math.floor(Math.random()*10000)+'_'+ new Date().getTime(),
      kind : 'Text',
      type : 'text',
      text : 'Default Text',
    }
    this.props.addText(payloadObject);
  }

  render() {
    return (
      <div className='itemWraper'>
        <Button className="addText" variant="outline-light" onClick={e=>this.addTextItem(e)}>
            <FontAwesome name="font" />
            &nbsp;Text
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // prop: state.prop
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addText: (payload) => {
      dispatch(addObject(payload))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Text);
