const fonts = [
  'Adobe Arabic',
  'Adobe Caslon Pro',
  'Adobe Fan Heiti Std',
  'Adobe Fangsong Std',
  'Adobe Garamond Pro',
  'Adobe Gothic Std',
  'Adobe Hebrew',
  'Adobe Heiti Std',
  'Adobe Kaiti Std',
  'Adobe Ming Std',
  'Adobe Myungjo Std',
  'Adobe Song Std',
  'Allura',
  'Amatic SC',
  'Arial',
  'Bahnscrift',
  'Birch Std',
  'Blackoak Std',
  'Book Antiqua',
  'Bookman Old Style',
  'Bookshelf Symbol',
  'Brush Script Std',
  'Calibri',
  'Cambria',
  'Cambria Math',
  'Candara',
  'Century',
  'Chaparral Pro',
  'Charlemagne Std',
  'Comic Sans MS',
  'Consolas',
  'Constantia',
  'Cooper Std',
  'Corbel',
  'Courier New',
  'DengXian',
  'Ebrima',
  'FangSong',
  'Franklin Gothic',
  'Gabriola',
  'Gadugi',
  'Garamond',
  'Georgia',
  'Giddyup Std',
  'Hobo Std',
  'HoloLens MDL2 Assets',
  'Impact',
  'Ink Free',
  'Javanese Text',
  'KaiTi',
  'Kozuka Gothic Pr6N',
  'Kozuka Gothic Pro',
  'Kozuka Mincho Pr6N',
  'Kozuka Mincho Pro',
  'Leelawadee',
  'Leelawadee UI',
  'Letter Gothic Std',
  'Lithos Pro',
  'Lucida Console',
  'Lucida Sans Unicode',
  'Malgun Gothic',
  'Marlett',
  'Mesquite Std',
  'Microsoft Himalaya',
  'Microsoft JhengHei',
  'Microsoft JhengHei UI',
  'Microsoft New Tai Lue',
  'Microsoft PhagsPa',
  'Microsoft Sans Serif',
  'Microsoft Tai Le',
  'Microsoft Uighur',
  'Microsoft YaHei',
  'Microsoft YaHei UI',
  'Microsoft Yi Baiti',
  'MingLiU_HKSCS-ExtB',
  'MingLiU-ExtB',
  'Minion Pro',
  'Mongolian Baiti',
  'Monotype Corsiva',
  'MS Gothic',
  'MS PGothic',
  'MS Reference Sans Serif',
  'MS Reference Specialty',
  'MS UI Gothic',
  'MB Boli',
  'Myanmar Text',
  'Myriad Pro',
  'Mirmala UI',
  'NSimSun',
  'Nueva Std',
  'OCR A Std',
  'Orator Std',
  'Palatino Linotype',
  'PMingLiU-ExtB',
  'Poplar Std',
  'Prestige Elite Std',
  'Rosewood Std',
  'Segoe MDL2 Assets',
  'Segoe Print',
  'Segoe Script',
  'Segoe UI',
  'Segoe UI Emoji',
  'Segoe UI Historic',
  'Segoe UI Symbol',
  'SimHei',
  'SimSun',
  'SimSun-ExtB',
  'Sitka',
  'Stencil Std',
  'Sylfaen',
  'Symbol',
  'Tahoma',
  'TeamViewer15',
  'Tekton Pro',
  'Times New Roman',
  'Trajan Pro',
  'Trebuchet MS',
  'Verdana',
  'Webdings',
  'Wingdings',
  'Wingdings 2',
  'Wingdings 3',
  'Yu Gothic',
  'Yu Gothic UI',
  'Roboto Slab',
  'Noto Sans',
  'Open Sans Condensed',
  'Playfair Display',
  'Ubuntu',
  'Turret Road',
  'PT Serif',
  'Titillium Web',
  'Nunito',
  'Fira Sans',
  'Noto Sans JP',
  'Grenze',
  'Work Sans',
  'PT Sans Narrow',
  'Quicksand',
  'Inconsolata',
  'Notable',
  'Teko',
  'Mukta',
  'Arimo',
  'Noto Sans KR',
  'Noto Sans TC',
  'Yanone Kaffeesatz',
  'Josefin Sans',
  'Heebo',
  'Cute Font',
  'Fjalla One',
  'Indie Flower',
  'Barlow Condensed',
  'Anton',
  'Pacifico',
  'Dancing Script',
  'Squada One',
  'Abril Fatface',
  'Shadows Into Light',
  'Ramabhadra',
  'Acme',
  'Bree Serif',
  'Kanit',
  'Catamaran',
  'Amatic SC',
  'Righteous',
  'Fira Sans Condensed',
  'Ubuntu Condensed',
  'Patua One',
  'Orbitron',
  'Alegreya',
  'Cinzel',
  'Rajdhani',
  'Permanent Marker',
  'Francois One',
  'Satisfy',
  'Cuprum',
  'Ranga',
  'Courgette',
  'Caveat',
  'Kalam',
  'Luckiest Guy',
  'Zilla Slab',
  'Pathway Gothic One',
  'Fredoka One',
  'Alfa Slab One',
  'Kaushan Script',
  'Great Vibes',
  'Cookie',
  'Lobster Two',
  'Sacramento',
  'Gloria Hallelujah',
  'Patrick Hand',
  'Poiret One',
  'Economica',
  'Passion One',
  'Bangers',
  'BenchNine',
  'Handlee',
  'Saira Stencil One',
  'Josefin Slab',
  'Lexend Deca',
  'Lexend Mega',
  'Russo One',
  'Neucha',
  'Paytone One',
  'Viga',
  'Gudea',
  'Playfair Display SC',
  'Ruda',
  'Ultra',
  'Vollkorn SC',
  'Tangerine',
  'Khand',
  'Advent Pro',
  'Monoton',
  'Yellowtail',
  'Amaranth',
  'Marck Script',
  'Unica One',
  'Playball',
  'Parisienne',
  'Bad Script',
  'Unlock',
  'Press Start 2P',
  'Boogaloo',
  'Homemade Apple',
  'Audiowide',
  'Schoolbell',
  'Baloo',
  'Chilanka',
  'Asap Condensed',
  'Fugaz One',
  'Sigmar One',
  'Julius Sans One',
  'Shadows Into Light Two',
  'Covered By Your Grace',
  'Oleo Script',
  'Allura',
  'Merienda',
  'Rock Salt',
  'Staatliches',
  'Damion',
  'Love Ya Like A Sister',
  'Rubik Mono One',
  'Pragati Narrow',
  'Nothing You Could Do',
  'Barriecito',
  'Chewy',
  'Mr Dafoe',
  'Pinyon Script',
  'Black Ops One',
  'Leckerli One',
  'Slackey',
  'Alex Brush',
  'Fredericka the Great',
  'Caveat Brush',
  'Nanum Pen Script',
  'Niconne',
  'Lilita One',
  'Prociono',
  'Reenie Beanie',
  'Aclonica',
  'Bahianita',
  'Italianno',
  'Cabin Sketch',
  'Aladin',
  'Marvel',
  'Coming Soon',
  'Saira Condensed',
  'Yesteryear',
  'Berkshire Swash',
  'Ruge Boogie',
  'Cinzel Decorative',
  'Nanum Brush Script',
  'Grand Hotel',
  'Norican',
  'Arizonia',
  'Raleway Dots',
  'Pattaya',
  'Kristi',
  'Shojumaru',
  'Calligraffitti',
  'Mountains of Christmas',
  'Beth Ellen',
  'Mr De Haviland',
  'Ceviche One',
  'Finger Paint',
  'Montez',
  'Six Caps',
  'Seaweed Script',
  'Cedarville Cursive',
  'Faster One',
  'Bungee Shade',
  'Zhi Mang Xing',
  'Clicker Script',
  'Mrs Saint Delafield',
  'Euphoria Script',
  'Galada',
  'Eater',
  'Codystar',
  'IM Fell English SC',
  'Creepster',
  'Ewert',
  'Sarina',
  'Nosifer',
  'League Script',
  'Miniver',
  'Monofett',
  'Barrio',
  'Metal Mania',
  'Libre Barcode 39 Extended Text',
  'Libre Barcode 39 Text',
  'Kenia',
  ].sort();

export let labelFont = [    
  'Wallpoet'
];

export default fonts;