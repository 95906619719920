import store from "../../redux/store";
import firebase from "../../utils/firebase/firebase.utils";

import CardTemplate from "./imgs/card-back.png";

export default {
  renderDirectCardImage: async function () {
    var db = firebase.firestore();
    const QrImg = store.getState().user.currentUser?.GoogleQrImg;
    const customQrImg = store.getState().user.currentUser?.customGoogleQrImg;
    const userId = store.getState().user.currentUser?.id;
  
    var canvas = document.getElementById("canvas1");
    if (!canvas || !canvas.getContext) {
      return false;
    }
    canvas.width = 2.5 * 445;
    canvas.height = 2.5 * 280;
    var ctx = canvas.getContext("2d");
    var img1 = new Image();
    var img2 = new Image();
    img1.src = CardTemplate;
    img2.src = customQrImg ? customQrImg : QrImg;
  
    await Promise.all([
      new Promise((resolve, reject) => {
        img1.onload = function () {
          ctx.drawImage(img1, 0, 0, 2.5 * 445, 2.5 * 280);
          resolve();
        };
      }),
      new Promise((resolve, reject) => {
        img2.onload = function () {
          resolve();
        };
      })
    ]);
  
    ctx.drawImage(img2, 2.5 * 140, 2.5 * 56, 2.5 * 165, 2.5 * 165);
  
    var fullQuality = canvas.toDataURL();
  
  
    await db.collection("users")
      .doc(`${userId}`)
      .update({
        reviewCardImage: `${fullQuality}`
      });
  }
  
  
};
