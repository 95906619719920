import React from "react";
import firebase from "../../utils/firebase/firebase.utils";
import { connect } from "react-redux";

import axios from "axios";
import { copyImageToClipboard } from 'copy-image-clipboard'

import { fetchOrders } from "../../redux/fulfillment/fulfillment.actions";

import "./Fulfillment.styles.scss";

import Gallary from "./assets/GalleryMyTapCardLogo.png"
import RW from "./assets/RW1.png"
import Five from "./assets/fivestar.png"



class Fulfillment extends React.Component {
  state = {
    allOrders: []
  };

  componentDidMount() {
    this.props.fetchOrders();
  }
  componentDidUpdate() {
    // this.props.fetchOrders();
  }
  // checkOrder(order) {
  //   this.props.fetchOrders();
  //   var database = firebase.database();
  //   database
  //     .ref(`orders/ + ${order.stripeCheckoutSessionId}/fulfilled`)
  //     .once("value", async (snapshot) => {
  //       console.log("thisshiitttt", snapshot.val());
  //       return await snapshot.val();
  //     });
  // }

  renderUnfulfilledOrders() {
    var database = firebase.database();
    return this.props.orders
      .filter((order) => !order.fulfilled)
      .map((order) => {
        return (
          <div key={order.stripeCheckoutSessionId} className="order">
            <div style={{ float: "right" }}>
              <li>Product: {order.item}</li>
              <li>Quantity: {order.quantity}</li>
              {/* {order.card.length > 10 ? (
                <img
                  src={`${order.card}`}
                  alt=""
                  style={{ width: "100px" }}
                ></img>
              ) : null}
              {order.phone.length > 10 ? (
                <img
                  src={`${order.phone}`}
                  alt=""
                  style={{ width: "100px" }}
                ></img>
              ) : null}
              {order.QrImg.length > 10 ? (
                <img
                  src={`${order.QrImg}`}
                  alt=""
                  style={{ width: "100px" }}
                ></img>
              ) : null} */}
            </div>

            <div>
              <h4>{order.recipientName}</h4>
              <p>{order.shippingAddress.line1}</p>
              <p>{order.shippingAddress.line2}</p>
              <p>{`${order.shippingAddress.city}, ${order.shippingAddress.state} ${order.shippingAddress.postal_code}`}</p>
              <p>{order.shippingAddress.country}</p>
            </div>

            {/* {order.cardUrl ? (
              <p style={{ fontSize: "12px" }}> {order.cardUrl} </p>
            ) : null} */}

            <button
              onClick={
                () =>
                  database
                    .ref(`orders/ + ${order.stripeCheckoutSessionId}/fulfilled`)
                    .once("value", async (snapshot) => {
                      console.log("thisshiitttt", snapshot.val());
                      // return await snapshot.val();
                      if (snapshot.val() !== "In-Process") {
                        database
                          .ref(`orders/ + ${order.stripeCheckoutSessionId}`)
                          .update({
                            fulfilled: "In-Process",
                            fulfilledBy: `${this.props.currentUser} is making the order`
                          })
                          .then(() => {
                            this.props.fetchOrders();
                          });
                      } else {
                        this.props.fetchOrders();
                      }
                    })

                //  {
                //    if(this.checkOrder(order) !== "In-Process" ){
                //   database
                //   .ref(`orders/ + ${order.stripeCheckoutSessionId}`)
                //   .update({
                //     fulfilled: "In-Process",
                //     fulfilledBy: `${this.props.currentUser} is making the order`
                //   })
                //   .then(() => {
                //     this.props.fetchOrders();
                //   })
                //  }
                // }

                // order.fulfilled === "In-Process"
                //   ? null
                //   : database
                //       .ref(`orders/ + ${order.stripeCheckoutSessionId}`)
                //       .update({
                //         fulfilled: "In-Process",
                //         fulfilledBy: `${this.props.currentUser} is making the order`
                //       })
                //       .then(() => {
                //         this.props.fetchOrders();
                //       })
              }
            >
              MAKE ORDER
            </button>

            <div style={{ wordWrap: "break-word" }}>
              <li style={{ fontSize: "8px" }}>
                Order ID: {order.stripeCheckoutSessionId}
              </li>
              <li style={{ fontSize: "8px" }}>UID: {order.uid}</li>
              <li style={{ fontSize: "8px" }}>
                Customer Email: {order.customerEmail}
              </li>
            </div>
          </div>
        );
      });
  }

  renderInProcessOrders() {
    var database = firebase.database();
    return this.props.orders
      .filter(
        (order) =>
          order.fulfilled === "In-Process" &&
          order.fulfilledBy === `${this.props.currentUser} is making the order`
      )
      .map((order) => {
        const date = new Date(order.createdAt).toLocaleString();
        return (
          <div key={order.stripeCheckoutSessionId} className="order">
            <div style={{ float: "right" }}>

              {order.item.includes("Review") ? (
                <img
                  id="reviewCardImg"
                  onClick={() => {copyImageToClipboard(order.reviewCardImage)}}
                  src={`${order.reviewCardImage}`}
                  alt="Back"
                  style={{ width: "100px" }}
                ></img>
              ): null}

              {order.item.includes("Review") &&
                order.customerEmail !== 'marmenta@galleryresidential.com' &&
                order.customerEmail !== 'thaughton@liverangewater.com'
                ? 
                  <img
                    id="reviewCardImg"
                    onClick={() => {copyImageToClipboard(Five)}}
                    src={`${Five}`}
                    alt="front"
                    style={{ width: "100px" }}
                  ></img>
                  : null}

              {order.customerEmail === 'marmenta@galleryresidential.com' ? (
                <img
                  id="reviewCardImg"
                  onClick={() => {copyImageToClipboard(Gallary)}}
                  src={`${Gallary}`}
                  alt="front"
                  style={{ width: "100px" }}
                ></img>
              ) : order.customerEmail === 'thaughton@liverangewater.com' ?
                <img
                  id="reviewCardImg"
                  onClick={() => {copyImageToClipboard(RW)}}
                  src={`${RW}`}
                  alt="front"
                  style={{ width: "100px" }}
                ></img>
              : null}

              {order.item.includes("Review") && order.reviewCardUploadedFrontLogo && order.reviewCardUploadedFrontLogo.length > 10 ? (
                <img
                  id="reviewCardImg"
                  onClick={() => {copyImageToClipboard(order.reviewCardUploadedFrontLogo)}}
                  src={`${order.reviewCardUploadedFrontLogo}`}
                  alt="Front"
                  style={{ width: "100px" }}
                ></img>
              ) : null}

              {!order.item.includes("Classic") && order.card && order.card.length > 10 ? (
                <img
                  id="reviewCardImg"
                  onClick={() => {copyImageToClipboard(order.card)}}
                  src={`${order.card}`}
                  alt=""
                  style={{ width: "100px" }}
                ></img>
              ) : null}

              {order.phone && order.phone.length > 10 ? (
                <img
                  src={`${order.phone}`}
                  alt=""
                  style={{ width: "100px" }}
                ></img>
              ) : null}
              {/* {order.QrImg && order.QrImg.length > 10 ? (
                <img
                  src={`${order.QrImg}`}
                  alt=""
                  style={{ width: "100px" }}
                ></img>
              ) : null} */}

              {order.item.includes("+ QR") &&
              order.customQrImg &&
              order.customQrImg.length > 10 ? (
                <img
                  id="reviewCardImg"
                  onClick={() => {copyImageToClipboard(order.customQrImg)}}
                  src={`${order.customQrImg}`}
                  alt=""
                  style={{ width: "100px" }}
                ></img>) 
                : order.item.includes("Review") && order.customGoogleQrImg && order.customGoogleQrImg.length > 10 ?
                <img
                  id="reviewCardImg"
                  onClick={() => {copyImageToClipboard(order.customGoogleQrImg)}}
                  src={`${order.customGoogleQrImg}`}
                  alt=""
                  style={{ width: "100px" }}
              ></img>
                : order.item.includes("Review") && order.GoogleQrImg && order.GoogleQrImg.length > 10 ?
                <img
                  id="reviewCardImg"
                  onClick={() => {copyImageToClipboard(order.GoogleQrImg)}}
                  src={`${order.GoogleQrImg}`}
                  alt=""
                  style={{ width: "100px" }}
                ></img> 
              : order.QrImg && order.QrImg.length > 10 ? (
                <img
                  id="reviewCardImg"
                  onClick={() => {copyImageToClipboard(order.QrImg)}}
                  src={`${order.QrImg}`}
                  alt=""
                  style={{ width: "100px" }}
                ></img>
              ) : null}
            </div>
            <div>
              <h4>{order.recipientName}</h4>
              <p>{order.shippingAddress.line1}</p>
              <p>{order.shippingAddress.line2}</p>
              <p>{`${order.shippingAddress.city}, ${order.shippingAddress.state} ${order.shippingAddress.postal_code}`}</p>
              <p>{order.shippingAddress.country}</p>
            </div>
            {/* {order.cardUrl ? (
              <p style={{ fontSize: "12px" }}> {order.cardUrl} </p>
            ) : null} */}
            MyTapReviewCardURL:
            {order.MyTapReviewCardURL ? (
              <p 
              id='reviewCardUrl'
              onClick={() => {navigator.clipboard.writeText(order.MyTapReviewCardURL)}}
              style={{ fontSize: "12px" }}> 
              {order.MyTapReviewCardURL} </p>
            ) : null}
            shortUrl:
            {order.shortUrl ? (
              <p 
              id='shortUrl'
              onClick={() => {navigator.clipboard.writeText(order.shortUrl)}}
              style={{ fontSize: "12px" }}> {order.shortUrl} </p>
            ) : null}
            MyCardDirectURL:
            {order.MyCardDirectURL ? (
              <p style={{ fontSize: "12px" }}> {order.MyCardDirectURL} </p>
            ) : null}
            directLinks Url:
            {order.directLinksUrl ? (
              <p style={{ fontSize: "12px" }}> {order.directLinksUrl} </p>
            ) : null}
            Shipping Label Url:
            {order.label ? (
              <a href={order.label} target="_blank"> {order.trackerCode} </a>
            ) : null}
            
            <button
              onClick={() =>
                database
                  .ref(`orders/ + ${order.stripeCheckoutSessionId}`)
                  .update({
                    fulfilled: false,
                    fulfilledBy: ""
                  })
                  .then(() => {
                    this.props.fetchOrders();
                  })
              }
            >
              UNPROCESS ORDER
            </button>
            <button
              onClick={() =>
                database
                  .ref(`orders/ + ${order.stripeCheckoutSessionId}`)
                  .update({
                    fulfilled: true,
                    fulfilledBy: this.props.currentUser
                  })
                  .then(() => {
                    this.props.fetchOrders();
                    axios({
                      method: "POST",
                      url: `https://us-central1-mytap-2d6fa.cloudfunctions.net/sendStatusMail?dest=${order.customerEmail}`,
                      data: {
                        customerName: order.recipientName,
                        customerEmail: order.customerEmail,
                        message:
                          `TRACKING INFO HERE:
                          <a href="${order.tracker}" target="_blank">${order.trackerCode}</a>
                          
                          `,
                        customerAddress: ` 
                        <p>${order.shippingAddress.line1}</p>
                        <p>${
                          order.shippingAddress.line2
                            ? order.shippingAddress.line2
                            : ""
                        }</p>
                        <p>${order.shippingAddress.city}, ${
                          order.shippingAddress.state
                        } ${order.shippingAddress.postal_code}</p>
                        <p>${order.shippingAddress.country}</p>`,
                        orderId: order.stripeCheckoutSessionId
                      }
                    }).then((response) => {
                      if (response.data === "Sended") {
                        console.log("Message Sent.");
                      } else {
                        console.log("Message failed to send.");
                      }
                    });
                  })
              }
            >
              FULFILL ORDER
            </button>
            <div style={{ wordWrap: "break-word" }}>
              <li style={{ fontSize: "10px" }}>Item: {order.item}</li>
              <li style={{ fontSize: "10px" }}>Quantity: {order.quantity}</li>
              <li style={{ fontSize: "8px" }}>
                Order ID: {order.stripeCheckoutSessionId}
              </li>
              <li style={{ fontSize: "8px" }}>UID: {order.uid}</li>
              <li style={{ fontSize: "8px" }}>
                Customer Email: {order.customerEmail}
              </li>
              <li style={{ fontSize: "10px" }}>
                Fulfilled by: {order.fulfilledBy}
              </li>
              <li style={{ fontSize: "10px" }}>Date: {date}</li>
            </div>
          </div>
        );
      });
  }
  

  renderfulfilledOrders() {
    return this.props.orders
      .filter((order) => order.fulfilled === true && order.createdAt )
      .sort((a, b) =>  new Date(b.createdAt) - new Date(a.createdAt))
      .map((order) => {
        // const db = firebase.firestore();
        const date = new Date(order.createdAt).toLocaleString();
        return (
          <div key={order.stripeCheckoutSessionId} className="order">
            <h4>{order.recipientName}</h4>
            <p>{order.shippingAddress.line1}</p>
            <p>{order.shippingAddress.line2}</p>
            <p>{`${order.shippingAddress.city}, ${order.shippingAddress.state} ${order.shippingAddress.postal_code}`}</p>
            <p>{order.shippingAddress.country}</p>
            {/* <p>Fulfilled Date: {order.fulfilledDate}</p> */}

            {/* <button
              onClick={() =>
                
              }
            >
              UNFULFILL ORDER
            </button> */}

            <div style={{ wordWrap: "break-word" }}>
              <li style={{ fontSize: "10px" }}>Item: {order.item}</li>
              <li style={{ fontSize: "10px" }}>Quantity: {order.quantity}</li>
              <li style={{ fontSize: "8px" }}>
                Order ID: {order.stripeCheckoutSessionId}
              </li>
              <li style={{ fontSize: "8px" }}>UID: {order.uid}</li>
              <li style={{ fontSize: "8px" }}>
                Customer Email: {order.customerEmail}
              </li>
              <li style={{ fontSize: "10px" }}>
                Fulfilled by: {order.fulfilledBy}
              </li>
              <li style={{ fontSize: "10px" }}>Date: {date}</li>
            </div>
          </div>
        );
      })
  }

  renderOldfulfilledOrders() {
    return this.props.orders
      .filter((order) => order.fulfilled === true)
      .map((order) => {
        // const db = firebase.firestore();
        const date = new Date(order.createdAt).toLocaleString();
        return (
          <div key={order.stripeCheckoutSessionId} className="order">
            <h4>{order.recipientName}</h4>
            <p>{order.shippingAddress.line1}</p>
            <p>{order.shippingAddress.line2}</p>
            <p>{`${order.shippingAddress.city}, ${order.shippingAddress.state} ${order.shippingAddress.postal_code}`}</p>
            <p>{order.shippingAddress.country}</p>
            {/* <p>Fulfilled Date: {order.fulfilledDate}</p> */}

            {/* <button
              onClick={() =>
                
              }
            >
              UNFULFILL ORDER
            </button> */}

            <div style={{ wordWrap: "break-word" }}>
              <li style={{ fontSize: "10px" }}>Item: {order.item}</li>
              <li style={{ fontSize: "10px" }}>Quantity: {order.quantity}</li>
              <li style={{ fontSize: "8px" }}>
                Order ID: {order.stripeCheckoutSessionId}
              </li>
              <li style={{ fontSize: "8px" }}>UID: {order.uid}</li>
              <li style={{ fontSize: "8px" }}>
                Customer Email: {order.customerEmail}
              </li>
              <li style={{ fontSize: "10px" }}>
                Fulfilled by: {order.fulfilledBy}
              </li>
              <li style={{ fontSize: "10px" }}>Date: {date}</li>
            </div>
          </div>
        );
      })
  }

  render() {
    // console.log(this.props.orders);
    return (
      <div className="fulfillment">
        <h1 className="fulfillment__header">FULFILLMENT</h1>
        <div className="fulfillment__orders-container">
          <div className="orders orders-unfulfilled">
            <h2 className="orders__header">Unfulfilled</h2>
            {this.renderUnfulfilledOrders()}
          </div>
          <div className="orders orders-fulfilled">
            <h2 className="orders__header">In-Progress</h2>
            {this.renderInProcessOrders().length > 0 ? (
              this.renderInProcessOrders()
            ) : (
              <div style={{ height: "200px" }} className="order"></div>
            )}
            <h2 className="orders__header">Fulfilled</h2>
            <i
              style={{ fontSize: "30pt" }}
              className="fas fa-arrow-down orders__header"
            ></i>

            {this.renderfulfilledOrders()}

            {this.renderOldfulfilledOrders()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user, fulfillment }) => ({
  currentUser: user?.currentUser?.displayName,
  orders: fulfillment
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchOrders: (payload) => {
      dispatch(fetchOrders(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Fulfillment);
