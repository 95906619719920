import store from "../../redux/store";
import firebase from "../../utils/firebase/firebase.utils";
import React from "react";

// const { saveAs, encodeBase64 } = require('@progress/kendo-file-saver');

export default {
  updateProfile: function () {
    var userId = store.getState().user.currentUser.id;
    var cardUrl = store.getState().user.currentUser.cardUrl
    var displayName = store.getState().user.currentUser.displayName

  
    const profileState = JSON.parse(store.getState().user.currentUser?.profile);
    const formDataKey = profileState === 1 ? `formData` : `formData${profileState}`;
    const croppedImgKey = profileState === 1 ? `croppedImg` :`croppedImg${profileState}`;
    const formData = JSON.parse(store.getState().user.currentUser[formDataKey]);
    const croppedImg = store.getState().user.currentUser[croppedImgKey];

  

    // const profileState = JSON.parse(store.getState().user.currentUser?.profile);
    // const formData = profileState == "1" ? JSON.parse(store.getState().user.currentUser.formData) : profileState == "2" ? JSON.parse(store.getState().user.currentUser.formData2): profileState == "3" ? JSON.parse(store.getState().user.currentUser.formData3):JSON.parse(store.getState().user.currentUser.formData);
    // const croppedImg = profileState == "1" ? store.getState().user.currentUser.croppedImg : profileState == "2" ? store.getState().user.currentUser.croppedImg2 : profileState == "3" ? store.getState().user.currentUser.croppedImg3 : store.getState().user.currentUser.croppedImg;
    // const stringImg = store.getState().user.currentUser.stringImg
    // console.log('thisistheidddddddd',profileState)


    var firstName = formData.firstName;
    var lastName = formData.lastName;
    var company = formData.company;
    var title = formData.title;
    var cellPhone = formData.cellPhone;
    var workPhone = formData.workPhone;
    var homePhone = formData.homePhone;
    var persEmail = formData.persEmail;
    var workEmail = formData.workEmail;
    var link = formData.link;
    var workLink = formData.workLink;
    var homeStreet = formData.homeStreet;
    var homeCity = formData.homeCity;
    var homeState = formData.homeState;
    var homeZip = formData.homeZip;
    var workStreet = formData.workStreet;
    var workCity = formData.workCity;
    var workState = formData.workState;
    var workZip = formData.workZip;
    var birthday = formData.birthday;
    var socialType1 = formData.socialType1.toLowerCase().trim();
    var socialUserName1 = formData.socialUserName1;
    var socialLink1 = formData.socialLink1;
    var socialType2 = formData.socialType2.toLowerCase().trim();
    var socialUserName2 = formData.socialUserName2;
    var socialLink2 = formData.socialLink2;
    var socialType3 = formData.socialType3.toLowerCase().trim();
    var socialUserName3 = formData.socialUserName3;
    var socialLink3 = formData.socialLink3;
    var socialType4 = formData.socialType4.toLowerCase().trim();
    var socialUserName4 = formData.socialUserName4;
    var socialLink4 = formData.socialLink4;
    var socialType5 = formData.socialType5.toLowerCase().trim();
    var socialUserName5 = formData.socialUserName5;
    var socialLink5 = formData.socialLink5;
    var socialType6 = formData.socialType6.toLowerCase().trim();
    var socialUserName6 = formData.socialUserName6;
    var socialLink6 = formData.socialLink6;
    var image = croppedImg;
    var message = formData.message;
    var imgLink = formData.imgLink;
    var youtubeLink = formData.youtubeLink;
    var html = formData.html;
    const withHttp = url => !/^https?:\/\//i.test(url) ? `https://${url}` : url;

    const toHtml = `<!DOCTYPE html>
    <html lang="en">
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>${displayName}</title>
    
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
        <link
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.css"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Fondamento&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Love+Ya+Like+A+Sister&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,400,300"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,300,100"
          rel="stylesheet"
        />
    
        <style>
          body,
          html,
          .wrapper {
            height: 100%;
            width: 100%;
          }
          body {
            font-family: "Open Sans", sans-serif;
            color: #404040;
          }
          .wrapper {
            color: #fff;
            background: linear-gradient(-90deg, #000000, #5e5e5e, #c2c2c2, #ffffff);
            background-size: 800% 800%;
            -webkit-animation: Gradient 10s ease infinite;
            -moz-animation: Gradient 10s ease infinite;
            animation: Gradient 10s ease infinite;
          }
    
          @-webkit-keyframes Gradient {
            0% {
              background-position: 0% 50%;
            }
            50% {
              background-position: 100% 50%;
            }
            100% {
              background-position: 0% 50%;
            }
          }
    
          @-moz-keyframes Gradient {
            0% {
              background-position: 0% 50%;
            }
            50% {
              background-position: 100% 50%;
            }
            100% {
              background-position: 0% 50%;
            }
          }
    
          @keyframes Gradient {
            0% {
              background-position: 0% 50%;
            }
            50% {
              background-position: 100% 50%;
            }
            100% {
              background-position: 0% 50%;
            }
          }
          .wrapper:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 2;
            opacity: 0.3;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
            filter: alpha(opacity=30);
          }
          .wrapper-inner {
            position: relative;
            z-index: 3;
            height: 100%;
          }
          .box-wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            margin-top: 40px;
            z-index: 1;
          }
          .box {
            position: relative;
            -webkit-border-radius: 8px;
            border-radius: 8px;
            -webkit-box-shadow: 0 4px 1px rgba(0, 0, 0, 0.1);
            box-shadow: 0 4px 1px rgba(0, 0, 0, 0.1);
            width: 370px;
            background: #fff;
            text-align: center;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            padding-top: 65px;
          }
          .box-inner {
            padding: 30px;
            padding-bottom: 10px;
          }
          .avatar {
            -webkit-border-radius: 50%;
            border-radius: 50%;
            -webkit-box-shadow: 0 0 0 3px #080808, 0 0 0 8px #fff;
            box-shadow: 0 0 0 3px #161616, 0 0 0 8px #fff;
            top: -75px;
            margin-left: -75px;
            left: 50%;
            position: absolute;
          }
          .avatar img {
            width: 150px;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            display: block;
          }
          h1,
          h2,
          h3,
          h4,
          h5 {
            font-family: "Open Sans", sans-serif;
            font-weight: 300;
            margin-top: 0;
            margin-bottom: 15px;
          }
          .name {
            font-size: 38px;
            font-weight: 375;
            font-family: "Roboto", sans-serif;
            margin-bottom: 2px;
            color: #080808;
          }
          .occupation {
            font-size: 18px;
            font-weight: 300;
            font-style: italic;
            color: #222222;
            margin-bottom: 2px;
          }
          .title {
            font-size: 16px;
            font-weight: 200;
            font-style: italic;
            color: #707070;
            margin-bottom: 2px;
          }
          .personal{
            font-family: "Roboto", sans-serif;
            color:#707070;
            text-align: left;
            list-style: none;
            margin: 10px auto;
          }
          .personal-icon{
            color:#080808;
            vertical-align: middle;
          }
          .location {
            color: #b3b3b3;
            font-size: 14px;
            margin-top: 5px;
            margin-bottom: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid #f2f2f2;
          }
          .location .fa {
            color: #c6c6c6;
            margin-right: 4px;
          }
          .social{
            padding-top: -100px;
            padding-bottom: 10px;
            /* border-right: 1px solid #000000; */
          }
          .social-item {
            display: inline-block;
            margin: 0 5px;
            color: #333; /* Default color */
            transition: color 0.3s; /* Transition effect for color change */
          }
          
          .social-item:hover {
            color: #0277bd; /* Color changes on hover */
          }
          .shake {
            font-family: "Roboto", sans-serif;
            animation: shake-animation 4.72s ease infinite;
            transform-origin: 50% 50%;
          }
          .element {
            margin: 0 auto;
            width: 150px;
            height: 150px;
            background: red;
          }
          @keyframes shake-animation {
            0% {
              transform: translate(0, 0);
            }
            1.78571% {
              transform: translate(5px, 0);
            }
            3.57143% {
              transform: translate(0, 0);
            }
            5.35714% {
              transform: translate(5px, 0);
            }
            7.14286% {
              transform: translate(0, 0);
            }
            8.92857% {
              transform: translate(5px, 0);
            }
            10.71429% {
              transform: translate(0, 0);
            }
            100% {
              transform: translate(0, 0);
            }
          }
        </style>
      </head>
    
      <body>
        <div class="wrapper">
          <div class="wrapper-inner">
            <div class="box-wrapper">
              <div class="box">
                <div class="avatar">
                ${ image ? `<img src="${image}"/> `:`<img src="https://firebasestorage.googleapis.com/v0/b/mytap-2d6fa.appspot.com/o/noprofile.png?alt=media&token=eb4bb0c4-dd90-4491-82fd-b66d6903cd66">`}
                </div>
                <div class="box-inner">
                  <h3 class="name">${firstName} ${lastName}</h3>
                  <h4 class="occupation">${company}</h4>
                  <h4 class="title">${title}</h4>
                  ${ homeCity || workCity ? `<p class="location">
                    <i class="fa fa-map-marker"></i>${homeCity || workCity}, ${homeState || workState}
                  </p>`:`<p></p>`}
                  <div class="personal">
                  ${ cellPhone ? `<li>
                      <span class="iconify personal-icon" data-icon="akar-icons:mobile-device" data-width="15"></span> <a href=tel:${cellPhone}>${cellPhone}</a>
                    </li>`:`<li></li>`}
                    ${ workPhone ? `<li>
                    <span class="iconify personal-icon" data-icon="carbon:phone-ip" data-width="15"></span> <a href=tel:${workPhone}>${workPhone}</a>
                  </li>`:`<li></li>`}
                  ${ homePhone ? `<li>
                  <span class="iconify personal-icon" data-icon="akar-icons:phone" data-width="15"></span> <a href=tel:${homePhone}>${homePhone}</a>
                </li>`:`<li></li>`}
                ${ persEmail ? `<li>
                      <span class="iconify personal-icon" data-icon="carbon:email" data-width="15"></span> <a href=mailto:${persEmail}>${persEmail}</a>
                     </li>`:`<li></li>`}
                ${ workEmail ? `<li>
                      <span class="iconify personal-icon" data-icon="carbon:email" data-width="15"></span> <a href=mailto:${workEmail}>${workEmail}</a>
                     </li>`:`<li></li>`}
                     ${ link ? `<li>
                      <span class="iconify personal-icon" data-icon="akar-icons:link-chain" data-width="15"></span> <a style="word-wrap: break-word;" href=${withHttp(link)}>${link}</a>
                     </li>`:`<li></li>`}
                     ${ workLink ? `<li>
                      <span class="iconify personal-icon" data-icon="akar-icons:link-chain" data-width="15"></span> <a style="word-wrap: break-word;" href=${withHttp(workLink)}>${workLink}</a>
                     </li>`:`<li></li>`}
                  </div>
    
                    <button
                      id="addBtn"
                      class="waves-effect shake white-text grey darken-4 btn-flat"
                      onclick="location.href='${cardUrl}'"
                    >
                      <i
                        style="vertical-align: middle"
                        class="material-icons white-text">add</i>
                      ADD CONTACT</button>
    
                </div>
                <ul class="social">
                ${ socialLink1 ? `<a href=${withHttp(socialLink1)} target="_blank" class="occupation">
                    <span class="iconify social-item" data-icon="simple-icons:${socialType1}" data-width="34"></span>
                  </a>`:`<a></a>`}
                  ${ socialLink2 ? `<a href=${withHttp(socialLink2)} target="_blank" class="occupation">
                  <span class="iconify social-item" data-icon="simple-icons:${socialType2}" data-width="34"></span>
                </a>`:`<a></a>`}
                ${ socialLink3 ? `<a href=${withHttp(socialLink3)} target="_blank" class="occupation">
                    <span class="iconify social-item" data-icon="simple-icons:${socialType3}" data-width="34"></span>
                  </a>`:`<a></a>`}
                  ${ socialLink4 ? `<a href=${withHttp(socialLink4)} target="_blank" class="occupation">
                    <span class="iconify social-item" data-icon="simple-icons:${socialType4}" data-width="34"></span>
                  </a>`:`<a></a>`}
                  ${ socialLink5 ? `<a href=${withHttp(socialLink5)} target="_blank" class="occupation">
                    <span class="iconify social-item" data-icon="simple-icons:${socialType5}" data-width="34"></span>
                  </a>`:`<a></a>`}
                  ${ socialLink6 ? `<a href=${withHttp(socialLink6)} target="_blank" class="occupation">
                    <span class="iconify social-item" data-icon="simple-icons:${socialType6}" data-width="34"></span>
                  </a>`:`<a></a>`}
                </ul>

                ${message|| imgLink  || youtubeLink || html  ? `<div id='spotlight' class="card-action">
        
                ${message ? `<p> ${message} </p>` :''}
    
                ${imgLink ? `<img class="center-align" style="width: 100%" src='${imgLink}'>`:''}
    
                ${youtubeLink ? `
                <div class="center-align"> 
                <iframe src="${youtubeLink}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                `: ''}
    
                ${html ? `<div id='html' class="center-align" > ${html} </div>` : ''}
        
                </div>`: ''}

              </div>
            </div>
          </div>
        </div>
    
        <script src="https://code.iconify.design/2/2.1.2/iconify.min.js"></script>
      </body>
    </html>
    `;

    var storageRef = firebase.storage().ref();
    var db = firebase.firestore();


    const encodedString = 'data:text/html;base64,' + new Buffer(toHtml).toString("base64");
    // console.log('dfdfdfdfddfdfddfd',encodedString)
    storageRef
      .child(`${userId}.html`)
      .putString(encodedString,'data_url')
      .then((snapshot) => {
        // console.log(snapshot)
        storageRef
          .child(`${userId}.html`)
          .getDownloadURL()
          .then((url) => {
          db.collection("users")
          .doc(`${userId}`)
          .update({
            landingUrl: `${url}`
          })
        })
      });


  }
};
