import { combineReducers } from "redux";

import userReducer from "./user/user.reducer";
import routingReducer from "./routing/routing.reducer";
import fulfillmentReducer from "./fulfillment/fulfillment.reducer";
import designImagesReducer from "./designImages/designImages.reducer";
import selectedUserReducer from "./selectedUser/selectedUser.reducer";
import {
  canvasReducer,
  designReducer,
  designTypeReducer,
  managerReducer,
  toolbarReducer
} from "./drawing-area/drawing-area.reducers";
import { objectReducer, activeObjectReducer } from "./object/object.reducers";
import { mobileReducer } from "./mobile/mobile.reducer";

const lastActionTracker = (state = "@@INIT", action) => {
  if (action) {
    return action.type;
  }

  return state;
};

export default combineReducers({
  user: userReducer,
  routing: routingReducer,
  designState: designReducer,
  designType: designTypeReducer,
  stateToolbar: toolbarReducer,
  mobile: mobileReducer,
  sceneManager: managerReducer,
  canvas: canvasReducer,
  objects: objectReducer,
  activeObject: activeObjectReducer,
  lastAction: lastActionTracker,
  fulfillment: fulfillmentReducer,
  designImages: designImagesReducer,
  selectedUser:selectedUserReducer
});
