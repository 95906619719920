import React from "react";

import "./DeviceCompatibility.styles.scss";

const DeviceCompatibility = () => {
  return (
    <div className="device-compatibility">
      <h2 className="device-compatibility__header">MyTap Compatible Devices</h2>
      <p className="device-compatibility__subheader">
        Check the list below to see if your device is compatible with MyTap's
        NFC technology! If not, don't worry -- all phones can read our QR codes.
      </p>
      <div className="device-compatibility__devices-container">
        <div className="column column--1">
          <div className="device-class">
            <h3 className="device-class__header">Apple (iPhone)</h3>
            <ul className="device-class__list">
              <li>iPhone 11 Pro Max</li>
              <li>iPhone 11 Pro</li>
              <li>iPhone 11</li>
              <li>iPhone XS Max</li>
              <li>iPhone XS</li>
              <li>iPhone XR</li>
              <li>iPhone SE (2020)</li>
              <li>iPhone X (with IOS 14)</li>
              <li>iPhone 8 (with IOS 14)</li>
              <li>iPhone 7 (with IOS 14)</li>
            </ul>
          </div>
          <div className="device-class">
            <h3 className="device-class__header">Samsung Galaxy S</h3>
            <ul className="device-class__list">
              <li>Galaxy S20, S20+, S20 Ultra</li>
              <li>Galaxy S10, S10+, S10e</li>
              <li>Galaxy S9, S9+</li>
              <li>Galaxy S8, S8+</li>
              <li>Galaxy S7, S7 Edge</li>
              <li>Galaxy S6, S6 Edge</li>
              <li>Galaxy S5, S5 Mini, S5 Neo</li>
            </ul>
          </div>
          <div className="device-class">
            <h3 className="device-class__header">Samsung Galaxy Note</h3>
            <ul className="device-class__list">
              <li>Note 10, Note 10+, Note 10+ 5G</li>
              <li>Note 9</li>
              <li>Note 8</li>
            </ul>
          </div>
          <div className="device-class">
            <h3 className="device-class__header">HTC</h3>
            <p className="device-class__subheader">
              Most HTC phones between 2015-2019 will be compatible
            </p>
            <ul className="device-class__list">
              <li>U19e, U12+, U12 Life</li>
              <li>Desire 12, Desire 12s (2017 model not compatible)</li>
              <li>U11, Life, +</li>
              <li>Exodus 1</li>
            </ul>
          </div>
        </div>
        <div className="column column--2">
          <div className="device-class">
            <h3 className="device-class__header">Google</h3>
            <ul className="device-class__list">
              <li>Pixel 4</li>
              <li>Pixel 4 XL</li>
              <li>Pixel 3</li>
              <li>Pixel 3 XL</li>
              <li>Pixel 3a</li>
              <li>Pixel 3a XL</li>
              <li>Pixel 2</li>
              <li>Pixel 2 XL</li>
              <li>Pixel</li>
              <li>Pixel XL</li>
              <li>Nexus 5X</li>
              <li>Nexus 6P</li>
              <li>Nexus 6</li>
            </ul>
          </div>
          <div className="device-class">
            <h3 className="device-class__header">Huawei</h3>
            <ul className="device-class__list">
              <li>P30, P30 Pro, P30 Lite</li>
              <li>P20, P20 Pro, P20 Lite</li>
              <li>P10, P10 Plus, P10 Lite</li>
            </ul>
          </div>
          <div className="device-class">
            <h3 className="device-class__header">OnePlus</h3>
            <ul className="device-class__list">
              <li>7, 7 Pro, 7 Pro 5G</li>
              <li>6, 6T</li>
              <li>5, 5T</li>
              <li>3, 3T</li>
              <li>One</li>
            </ul>
          </div>
        </div>
        <div className="column column--3">
          <div className="device-class">
            <h3 className="device-class__header">Samsung Galaxy A</h3>
            <ul className="device-class__list">
              <li>2019 models: A20e, A40, A50, A70</li>
              <li>2018 models: A6, A7, A8, A9</li>
              <li>2017 models: A3, A5</li>
              <li>2016 models: A3, A5 </li>
            </ul>
          </div>
          <div className="device-class">
            <h3 className="device-class__header">Samsung Galaxy J</h3>
            <ul className="device-class__list">
              <li>2018 models: J4+, J6, J5, J3</li>
            </ul>
          </div>
          <div className="device-class">
            <h3 className="device-class__header">LG</h3>
            <ul className="device-class__list">
              <li>Q9</li>
              <li>One</li>
              <li>G8, G8s ThinQ</li>
              <li>G7, G7 ThinQ</li>
              <li>V50, V40</li>
              <li>V30, V35</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceCompatibility;
