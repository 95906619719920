import React, { Component } from "react";

import { connect } from "react-redux";
import { addObject } from "../../../redux/object/object.actions";

class Shape extends Component {
  componentDidMount() {}

  componentDidUpdate() {}

  addShapeItem(pType){
    this.props.addShape({
      id : Math.floor(Math.random()*10000)+'_'+ new Date().getTime(),
      kind : "Shape",
      type : pType,
    })
  }

  render() {
    return (
        <div className='itemWraper'>
            <button className="btnShape" onClick={ e => this.addShapeItem("Triangle") }>
                <svg viewBox="0 0 100 100"><polygon points="0 100, 50 0, 100 100"></polygon></svg>
            </button>
            <button className="btnShape" onClick={ e => this.addShapeItem("Circle") }>
                <svg viewBox="0 0 100 100"><circle cx="50" cy="50" r="50"></circle></svg>
            </button>
            <button className="btnShape" onClick={ e => this.addShapeItem("Rect") }>
            <svg viewBox="0 0 100 100"><polygon points="0 0, 100 0, 100 100, 0 100"></polygon></svg>
            </button>
            <button className="btnShape" onClick={ e => this.addShapeItem("Line") }>
              <svg viewBox="0 0 100 100"><polygon points="0 48, 100 48, 100 52, 0 52"></polygon></svg>
            </button>
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // prop: state.prop
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addShape : (payload) => {
      dispatch(addObject(payload))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Shape);
