import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBIcon,
  MDBCard,
  MDBCardBody
} from "mdbreact";

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import {
  auth,
  createUserProfileDocument
} from "../../utils/firebase/firebase.utils";

import CustomButton from "../../components/custom-button/customButton";

class Register extends Component {
  constructor() {
    super();
    this.state = {
      displayName: "",
      email: "",
      password: "",
      password2: ""
    };
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { displayName, email, password, password2 } = this.state;
    if (password !== password2) {
      alert("passwords dont match");
      return;
    }
    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      );

      await createUserProfileDocument(user, { displayName });
      this.setState({
        displayName: "",
        email: "",
        password: "",
        password2: ""
      });
    } catch (error) {
      alert(error);
    }
  };

  render() {
    return (
      <div>
        <MDBContainer size="fluid"
          style={{
            marginTop: "60px",
            marginBottom: "100px",
          }}
        >
          <Link style={{ textDecoration: "none" }} to="/">
            <button className="custom-button">
              <MDBIcon icon="angle-left" /> &nbsp; Back to home
            </button>
          </Link>

          <MDBRow>
            <MDBCol size="3"></MDBCol>
            <MDBCol md="6">
              <MDBCard>
                <MDBCardBody>
                  <form onSubmit={this.handleSubmit}>
                    <p
                      style={{ fontFamily: "Roboto" }}
                      className="h4 text-center py-4"
                    >
                      SIGN UP
                    </p>

                    <div className="grey-text">
                      <MDBInput
                        label="Your name"
                        name="displayName"
                        icon="user"
                        group
                        type="text"
                        validate
                        error="wrong"
                        success="right"
                        onChange={this.onChange}
                        value={this.state.displayName}
                      />
                      <MDBInput
                        label="Your email"
                        name="email"
                        icon="envelope"
                        group
                        type="email"
                        validate
                        error="wrong"
                        success="right"
                        onChange={this.onChange}
                        value={this.state.email}
                      />
                      <MDBInput
                        label="Your password"
                        name="password"
                        icon="lock"
                        group
                        type="password"
                        validate
                        onChange={this.onChange}
                        value={this.state.password}
                      />
                      <MDBInput
                        label="Confirm Your password"
                        name="password2"
                        icon="lock"
                        group
                        type="password"
                        validate
                        onChange={this.onChange}
                        value={this.state.password2}
                      />
                    </div>
                    <div className="text-center py-4 mt-3">
                      <p className="grey-text text-darken-1">
                        Already have an account? <Link to="/login">Log in</Link>
                      </p>

                      {/* <MDBBtn color="cyan" type="submit">
                      Register
                    </MDBBtn> */}

                      <CustomButton style={{ margin: "auto" }} type="submit">
                        <MDBIcon icon="user-plus" /> &nbsp; Register
                      </CustomButton>
                    </div>
                  </form>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

export default withRouter(Register);
