import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import firebase from "../../utils/firebase/firebase.utils";
import { CircularProgress, Box, Typography } from "@material-ui/core";
import profile from "../../pages/profile/profile";


const LinkRedirect = () => {
  const { shortCode } = useParams();
  const [loading, setLoading] = useState(true);
  // const [clickDates, setClickDates] = useState([]);

  //read array of timeststamps from db,
  //set to local array
  //?dispatch to redux?
  //write local array back to db

  // const addClickDates = async () => {
  //   var db = firebase.firestore();
  //   const linkDoc = await db.collection("links").doc(shortCode).get();
  //   const { longURL, linkID, userId } = linkDoc.data();
  //   db.collection(`users/${userId}/links`)
  //     .get()
  //     .then((querySnapshot) => {
  //       querySnapshot.forEach((doc) => {
  //         // console.log(doc.id, " => ", doc.data());
  //         const clickDates = doc.data();
  //         // const linkDocId = doc.id;
  //         // console.log("this is the idddddd", linkDocId);
  //         setClickDates(clickDates);
  //         console.log("this is the clickDates", clickDates);
  //         // setClickDates(currentArray => [...currentArray, clickDates]);
          
  //       });
  //     });
  // };

  //   fetch('https://ipapi.co/json/')
  // .then(response => response.json())
  // .then(data => console.log(data));


  // useEffect(() => {
  //   var db = firebase.firestore();
  //   const fetchLinkDoc = async () => {
  //     if (shortCode.length !== 6) return setLoading(false);
  //     const linkDoc = await db.collection("links").doc(shortCode).get();
  //     if (!linkDoc.exists) return setLoading(false);
  //     const { longURL, linkID, userId } = linkDoc.data();
  //     if (linkDoc.exists && !localStorage.getItem(`${linkID}`)) {
  //       await db
  //         .collection("users")
  //         .doc(userId)
  //         .collection("links")
  //         .doc(linkID)
  //         .update({
  //           totalClicks: firebase.firestore.FieldValue.increment(1),
  //           clickDates: firebase.firestore.FieldValue.arrayUnion(new Date())
  //         });
  //       window.location.href = longURL;
  //       localStorage.setItem(`${linkID}`,longURL)
  //     } 
  //     else if(linkDoc.exists && localStorage.getItem(`${linkID}`)){

  //       window.location.href = longURL;
  //     }
  //     else {
  //       setLoading(false);
  //     }
  //   };
  //   fetchLinkDoc();
  // }, []);

  useEffect(() => {
    var db = firebase.firestore();
    const fetchLinkDoc = async () => {
      if (shortCode.length !== 6) return setLoading(false);
      const linkDoc = await db.collection("links").doc(shortCode).get();
      if (!linkDoc.exists) return setLoading(false);

      const { longURL, linkID, userId  } = linkDoc.data();
      const currentProfile = await db.collection("users")
          .doc(userId)
          .collection("links")
          .doc(linkID)
          .get();

          const {profile} = currentProfile.data()
        
      if (!profile || ![1, 2, 3, 4].includes(parseInt(profile))) {

        console.error('Invalid or no profile selected');
        return setLoading(false);
      }
      
      console.log(profile);
    const totalClicks = profile == "1" ? `totalClicks` :`totalClicks${profile}`;
    const clickDates = profile == "1" ? `clickDates` :`clickDates${profile}`;
      
     
      if (linkDoc.exists && !localStorage.getItem(`${linkID}`)) {
        await db
          .collection("users")
          .doc(userId)
          .collection("links")
          .doc(linkID)
          .update({
            [totalClicks]: firebase.firestore.FieldValue.increment(1),
            [clickDates]: firebase.firestore.FieldValue.arrayUnion(new Date())
          });
        
        window.location.href = longURL;
        localStorage.setItem(`${linkID}`, longURL);
      } 
      else if (linkDoc.exists && localStorage.getItem(`${linkID}`)) {
        window.location.href = longURL;
      }
      else {
        setLoading(false);
      }
    };
    
    fetchLinkDoc();
  }, []);

  if (loading)
    return (
      <Box mt={10} textAlign="center">
        <CircularProgress />
        <Typography>Powered by MYTAP</Typography>
      </Box>
    );
  else
    return (
      <Box mt={10} textAlign="center">
        <Typography>Link is invalid</Typography>
      </Box>
    );
};

export default LinkRedirect;
