let Point = { x: 0, y: 0 };
let Bound = {
  x: 0,
  y: 0,
  width: 800,
  height: 800
};

const CANVAS_INITIAL_STATE = {
  canvasViewport: Bound,
  pan: Point,
  zoom: 1
};

export const canvasReducer = (state = CANVAS_INITIAL_STATE, action) => {
  switch (action.type) {
    case "CANVAS/CONFIG_VIEWPORT":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const designReducer = (state = false, action) => {
  switch (action.type) {
    case "CANVAS/DESIGNING":
      return action.payload;
    default:
      return state;
  }
};

const DESIGN_TYPE_INITIAL_STATE = {
  type: "",
  path: "",
  name : "",
  phoneCase: null
};

export const designTypeReducer = (
  state = DESIGN_TYPE_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case "CANVAS/DESIGNTYPE":
      return action.payload;
    default:
      return state;
  }
};

export const toolbarReducer = (state = "hide", action) => {
  switch (action.type) {
    case "TOOLBAR/TOGGLE":
      return action.payload;
    default:
      return state;
  }
};

export const managerReducer = (state = null, action) => {
  switch (action.type) {
    case "CANVAS/MANAGER":
      return action.payload;
    default:
      return state;
  }
};
