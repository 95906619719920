import React from "react";
import { connect } from "react-redux";
import { MDBMedia, MDBBtn } from "mdbreact";

import firebase from "../../utils/firebase/firebase.utils";
import { nanoid } from "nanoid";

import { defaultRedirect } from "../../redux/routing/routing.actions";
import { fetchDesigns } from "../../redux/designImages/designImages.actions";

import API2 from "../../components/generateQr/generateGoogleQr";
import API3 from "../../components/renderDirectCardImage/renderDirectCardImage";
import { CircularProgress, Box } from "@material-ui/core";

// import UploadLogo from "../../components/upload-your-logo/uploadLogo";

// Stripe
import { ElementsConsumer } from "@stripe/react-stripe-js";

//

import {
  createSession,
  createStripeCustomer
} from "../../utils/firebase/firebase.utils";
import { updateUser } from "../../redux/user/user.actions";

import Splash from "../splash/splash";

import CR from "./assets/images/IDCard/CR.png";
import CR2 from "./assets/images/IDCard/CR2.png";

class ColumbiaResidentialOrder extends React.Component {
  state = {
    MyTapReviewCardURL: "",
    loading: false
  };

  componentDidMount() {
    this.props.defaultRedirect();
    this.props.fetchDesigns();
  }

  // Redirect to fulfillment page when applicable
  componentDidUpdate() {
    if (
      this.props.currentUser &&
      this.props.currentUser.role === "fulfillment"
    ) {
      this.props.history.push("/fulfillment");
    }
    // this.props.fetchDesigns();
  }

  onDirectURLChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  createShortLink() {
    var db = firebase.firestore();
    const link = {
      name: "relink",
      longURL:
        this.state.MyTapReviewCardURL.includes("http://") ||
        this.state.MyTapReviewCardURL.includes("https://")
          ? this.state.MyTapReviewCardURL
          : `http://${this.state.MyTapReviewCardURL}`,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      shortCode: nanoid(6),
      totalClicks: 0,
      clickDates:[]
    };

    db.collection("users")
      .doc(`${this.props.userId}`)
      .collection("links")
      .add(link)
      .then(() => {
        db.collection("users")
          .doc(`${this.props.userId}`)
          .update({
            shortUrl: `https://www.mytap.net/rd/${link.shortCode}`
          });
      });
  }

  saveMyTapReviewCardURLToDB(e) {
    var db = firebase.firestore();
    db.collection("users").doc(`${this.props.userId}`).update({
      MyTapReviewCardURL: this.state.MyTapReviewCardURL.toString()
    })
  }

  generateAndBuy() {
    API2.generateQR();
    setTimeout(() => {
      API3.renderDirectCardImage();
    }, 250);
  }

  render() {
    const { currentUser } = this.props.user;

    if (!currentUser) {
      return <Splash />;
    }

    if (this.state.loading)
      return (
        <Box mt={10} textAlign="center">
          <CircularProgress />
        </Box>
      );

    return (
      <div className="mt-1 pt-3 shop-page" style={{ marginBottom: "200px" }}>
        <div className="shop-page__header">
          <h2 className="shop-page__header--main">MyTap Review Card</h2>
        </div>

        <div className="shop-page__items">
          <div className="shop-item shop-item--card">
            {/* <h3 className="shop-item__header">MyTap Review Card</h3> */}
            <MDBMedia
              object
              className="shop-item__image shop-item__image--card--classic"
              src={CR}
              alt=""
            />

            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img
                className="shop-item__image shop-item__image--card--classic"
                style={{ width: "100%" }}
                alt="card"
                src={CR2}
              />
            </div>

            <ElementsConsumer>
              {({ stripe, elements }) => (
                <form
                  className="shop-item__buy-btn"
                  onSubmit={async (e) => {
                    this.setState({ loading: true });
                    e.preventDefault();
                    this.generateAndBuy();
                    if (!currentUser.stripeCustomerId) {
                      return await createStripeCustomer(currentUser).then(
                        async () => {
                          const session = await createSession(
                            "MyTap Review Card",
                            currentUser.uid
                          );
                          stripe.redirectToCheckout({
                            sessionId: session.data.id
                          });
                          console.log("session", session);
                        }
                      );
                    } else {
                      const session = await createSession(
                        "MyTap Review Card",
                        currentUser.uid
                      );
                      stripe.redirectToCheckout({
                        sessionId: session.data.id
                      });
                      console.log("session", session);
                    }
                  }}
                >
                  <div>
                    <label>URL: &nbsp;</label>
                    <input
                      onChange={this.onDirectURLChange.bind(this)}
                      onBlur={this.saveMyTapReviewCardURLToDB.bind(this)}
                      type="text"
                      name="MyTapReviewCardURL"
                      value={this.state.MyTapReviewCardURL}
                    ></input>
                  </div>

                  <MDBBtn
                    disabled={this.state.MyTapReviewCardURL.length < 4}
                    type="submit"
                    className="shop-item__buy-btn unique-color-dark text-white"
                    color="unique-color-dark"
                  >
                    Buy
                  </MDBBtn>
                </form>
              )}
            </ElementsConsumer>

            <div className="shop-item__price">$35</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log('dddddd',)
  return {
    user: state.user,
    email: state.email,
    currentUser: state.user?.currentUser,
    userId: state.user.currentUser?.id,
    MyCardDirectURL: state.user.currentUser?.MyCardDirectURL,
    reviewCardUploadedLogo: state.user.currentUser?.reviewCardUploadedLogo,
    formData: state.user.currentUser?.formData,
    QrImg: state.user.currentUser?.QrImg
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateUser: (payload) => {
      dispatch(updateUser(payload));
    },
    defaultRedirect: () => {
      dispatch(defaultRedirect());
    },
    fetchDesigns: (payload) => {
      dispatch(fetchDesigns(payload));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ColumbiaResidentialOrder);
