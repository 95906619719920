import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBIcon,
  MDBCard,
  MDBCardBody
} from "mdbreact";
import { Link } from "react-router-dom";

// import { connect } from "react-redux";
// import { loginUser } from "../../redux/actions/authActions";

import { auth } from "../../utils/firebase/firebase.utils";

import CustomButton from "../../components/custom-button/customButton";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: ""
    };
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    try {
      await auth.signInWithEmailAndPassword(email, password);
      this.setState({ email: "", password: "" });
    } catch (error) {
      alert(error);
    }
  };

  render() {
    return (
      <div>
        <MDBContainer size="fluid"
        style={{ marginTop: "60px" }}>
          <Link style={{ textDecoration: "none" }} to="/">
            <button className="custom-button">
              <MDBIcon icon="angle-left" /> &nbsp; Back to home
            </button>
          </Link>

          <MDBRow>
            <MDBCol size="3"></MDBCol>
            <MDBCol md="6">
              <MDBCard>
                <MDBCardBody>
                  <form onSubmit={this.onSubmit}>
                    <p
                      style={{ fontFamily: "Roboto" }}
                      className="h5 text-center mb-4"
                    >
                      SIGN IN
                    </p>
                    <div className="grey-text">
                      <MDBInput
                        label="Type your email"
                        name="email"
                        icon="envelope"
                        group
                        type="email"
                        validate
                        error="wrong"
                        success="right"
                        onChange={this.onChange}
                        value={this.state.email}
                      />
                      <MDBInput
                        label="Type your password"
                        name="password"
                        icon="lock"
                        group
                        type="password"
                        validate
                        onChange={this.onChange}
                        value={this.state.password}
                      />
                    </div>
                    <div className="text-center">
                      <p className="grey-text text-darken-1">
                        Don't have an account?{" "}
                        <Link to="/register">Register</Link>
                      </p>

                      {/* <MDBBtn color="cyan" type="submit">
                    Login
                    </MDBBtn> */}

                      <CustomButton style={{ margin: "auto" }} type="submit">
                        <MDBIcon icon="sign-in-alt" /> &nbsp; Login
                      </CustomButton>
                    </div>
                  </form>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

export default Login;
