// import 'fabric-webpack'
import { fabric } from "fabric"
import { compareAttribute } from '../../redux/compareAttribute';
import { labelFont } from '../properties/Font';
import FontFacePreloader from 'fontface-preloader';

export default class DesignTool{};

class SceneManager{
    _canvas = null;
    _width = null;
    _height = null;
    _objects = [];

    constructor(pOption){
        let canvas = this._canvas = new fabric.Canvas(pOption.canvas, {
            perPixelTargetFind : true,
            targetFindTolerance : 5,
            preserveObjectStacking : true,
            backgroundColor : "#aaa",
        });
        canvas.setDimensions({width : pOption.width, height : pOption.height});
        canvas.requestRenderAll();
        this._height = pOption.height;
        this._width = pOption.width;
        this._sendMessage = pOption.message;

        // function addEvents(){
        //     canvas.on('selection:created', function(){
        //         oneself._sendMessage({
        //             type : 'objectSelected'
        //         });
        //     });

        //     canvas.on('selection:cleared', function(){
        //         oneself._sendMessage({
        //             type : 'objectReleased'
        //         });
        //     });

        //     canvas.on('selection:updated', function(){
        //         oneself._sendMessage({
        //             type : 'objectUpdated'
        //         });
        //     });
        // }

        this._initialize = function(){
            let font = new FontFacePreloader(labelFont);
            font.load().then(function(){
                console.log('font is loaded');
            });
            // add events
            // addEvents()
            // for zoom

            // for pan

            // clear canvas
            this._objects = [];
            canvas.clear().requestRenderAll();

        }
       
        this._initialize();
    }

    get canvas(){ return this._canvas; };

    get width(){ return this._width; };
    set width(pWidth){ this._width = pWidth }

    get height(){ return this._height; }
    set height(pHeight){ this._height = pHeight; }

    resize(pW, pH){
        this.width = pW;
        this.height = pH;
        this.canvas.setDimensions({width : pW, height : pH});
        this.canvas.requestRenderAll();
    }

    clearScene(){
        this._objects = [];
        this.canvas.clear().requestRenderAll();
    }

    getObjectfromID(pID){
        for(let i=0;i<this._objects.length;i++){
            if(this._objects[i].id===pID){
                return {idx : i, object : this._objects[i]};
            }
        }
        return {idx:-1, object : null};
    }

    addObject(pAttr){
        let {idx} = this.getObjectfromID(pAttr.id),
            flagExist = idx>-1;

        if(!flagExist){
            switch(pAttr.kind){
                case "Phone":
                    this._objects.push( new DesignTool.PhoneCase(pAttr, this) );
                    break;
                case "Text":
                    this._objects.push( new DesignTool.Text(pAttr, this));
                    break;
                case "Image":
                    this._objects.push( new DesignTool.Image(pAttr, this));
                    break;
                case "Shape":
                    this._objects.push( new DesignTool.Shape(pAttr, this));
                    break;
                default:
                
            }
        }else{
            // console.log('the instance existed already');
        }
    }

    updateObject(pAttr){
        let {object} = this.getObjectfromID(pAttr.id);
        if(!!object) object.updateObject(pAttr);
    }

    deleteObject(pID){
        let {idx,object} =  this.getObjectfromID(pID);
        if(!!object){
            object.deleteInstance();
            this._objects.splice(idx,1);
        }
    }

    releaseObject(){
        this.canvas.discardActiveObject();
    }

    updateSequence(pSequance){
        let shape = this.canvas.getObjects()[pSequance.sourceIdx];
        this.canvas.remove(shape).insertAt(shape, pSequance.targetIdx).requestRenderAll()
    }

    sendMessage(pData){
        this._sendMessage(pData);
    }

    getSceneBase64Image(){
        return this._canvas.toDataURL({
			format: 'png',
            quality : 1,
            multiplier : 2,
		});
    }

    downloadImage(base64Image){
        const a = document.createElement("a");
        a.href = base64Image;
        a.download = "";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    getCropImage(){
        let own =this;
        let cropInfo = this._objects[0].attribute;
        let multi = 1200/this.width;
        return new Promise((response, reject)=>{

            own._objects[0].instance.clone(function(crop){

                cropInfo.opacity = 1;
                crop.setOptions(cropInfo);
                crop.globalCompositeOperation = 'destination-out';
                own._canvas.add(crop).requestRenderAll();

                if(crop.name!==undefined){
                    let name = new fabric.Text(crop.name, {
                        fontFamily : 'Wallpoet',
                        fontSize : 25,
                        fill : '#333',
                        top : own._canvas.height - 25,
                    });
                    let w = name.getScaledWidth();
                    name.set('left', (own._canvas.width-w)/2);
                    own._canvas.add(name).requestRenderAll();
                }

                // own.downloadImage(own._canvas.toDataURL({
                //     format: 'png',
                //     quality : 1,
                //     multiplier : 1,
                // }));
                
                let imgdata = own._canvas.toDataURL({
                    format: 'png',
                    quality : 1,
                    multiplier : multi,
                });
                response(imgdata);
            });
        });
        
    }

    getImgDataObject(pID){
        let {idx, object} = this.getObjectfromID(pID);
        if(idx>-1 && !!object){
            return object.instance.toDataURL({
                format: 'png',
                quality : 1,
                multiplier : 1,
            });
        }else{
            // throw("don't exist the object with " + pID + 'on canvas.');
        }        
    }
}
DesignTool.SceneManager = SceneManager;

class fabricObject{
    _attribute = null;
    _manager = null;

    instance = null;

    constructor(pAttr, pManager){
        this._attribute = pAttr;
        this._manager = pManager;
    }

    updateObject(pAttr){
        let diff = compareAttribute(this.attribute, pAttr);
        if(diff.length>0){
            if(diff.indexOf('index')>0){
                debugger
            }else{
                if(this.instance.type==='image') delete pAttr.stroke;
                this.instance.setOptions(pAttr);
                this.Manager.canvas.requestRenderAll();
                this._attribute = pAttr;
            }
        }
    }

    deleteInstance(){
        this.Manager.canvas.remove(this.instance).requestRenderAll();
    }

    set Manager(pManager){
        this._manager = pManager;
    }
    get Manager(){
        return this._manager;
    }
    get id(){
        return this._attribute.id;
    }

    get attribute(){
        return this._attribute;
    }
    set attribute(pAttr){
        this._attribute = pAttr;
    }

    get canvas(){
        return this._manager.canvas;
    }
}
DesignTool.fabricObject = fabricObject;

class PhoneCase extends fabricObject{
    constructor(pAttr, pManager){
        super(pAttr, pManager);

        initialize();

        let oneself = this;
        function initialize(){
            fabric.Image.fromURL(pAttr.url, function(instImage){
                let attr = {
                    ...pAttr,
                    evented : false,
                    selectable : false,
                    opacity : 0.75,
                };

                if(attr.width===0 && attr.height===0 && attr.left===-10000 && attr.top===-10000){
                    attr.scaleX = pManager.width*1.2/instImage.width;
                    attr.scaleY = pManager.height*1.2/instImage.height;
                    attr.width = instImage.width;
                    attr.height = instImage.height;
                    attr.left = pManager.width/2 - attr.width*attr.scaleX/2;
                    attr.top = pManager.height/2 - attr.height*attr.scaleY/2;
                    attr.index = -1;
                }
                instImage.setOptions(attr);
                
                pManager.canvas.add(instImage).requestRenderAll();
                oneself.attribute = attr;
                oneself.instance = instImage;

                pManager.sendMessage({
                    type : "updateAttribute",
                    data : attr,
                });

                // pManager.sendMessage({
                //     type : 'bindEvent',
                //     data : instImage,
                // })
            });
        }
    }

    updateObject(pAttr){
        let oneself = this;
        let diff = compareAttribute(this.attribute, pAttr);
        if(diff.length>0){
            if(diff.indexOf('index')>0){
            }else if(diff.indexOf('url')!==-1){
                var img=new Image();
                img.onload=function(){
                    oneself.instance.setElement(img);
                    oneself.canvas.requestRenderAll();
                }
                img.src = pAttr.url;
            }else{
                console.log('error in phone case')
            }
        }
    }
}
DesignTool.PhoneCase = PhoneCase;

class Text extends fabricObject{
    constructor(pAttr, pManager){
        super(pAttr, pManager);
        
        let oneself = this;

        initialize();

        function initialize(){
            let instText = new fabric.Text(pAttr.text, pAttr);

            if(pAttr.width===0 && pAttr.height===0 && pAttr.left===-10000 && pAttr.top===-10000){
                pAttr.width = instText.width;
                pAttr.height = instText.height;
                pAttr.left = pManager.width/2 - pAttr.width/2;
                pAttr.top = pManager.height/2 - pAttr.height/2;                
                instText.setOptions({
                    ...pAttr,
                    objectCaching: false,
                });
            }

            oneself.attribute = pAttr;
            oneself.instance = instText;

            pManager.sendMessage({
                type : "updateAttribute",
                data : pAttr,
            });

            pManager.sendMessage({
                type : 'bindEvent',
                data : instText,
            })

            pManager.canvas.insertAt(instText, pAttr.index).setActiveObject(instText).requestRenderAll();

        }
    }
}
DesignTool.Text = Text;

class Image extends fabricObject{
    constructor(pAttr, pManager){
        super(pAttr, pManager);
        
        let oneself = this;

        initialize();

        function initialize(){
            let imageDOM = document.createElement('img');
            imageDOM.src = pAttr.url;
            imageDOM.onload = () =>{
                let attr = {
                    ...pAttr,
                    evented : true,
                    selectable : true,
                };
                delete attr.stroke;
                if(attr.width===0 && attr.height===0 && attr.left===-10000 && attr.top===-10000){
                    attr.width = imageDOM.width;
                    attr.height = imageDOM.height;
                    let ratioX = pManager.width*0.75/imageDOM.width,
                        ratioY = pManager.height*0.75/imageDOM.height,
                        ratio = Math.min(ratioX, ratioY);
                    attr.scaleX = ratio;
                    attr.scaleY = ratio;
                    attr.left = pManager.width/2 - attr.width*ratio/2;
                    attr.top = pManager.height/2 - attr.height*ratio/2;
                }
                let instImage = new fabric.Image(imageDOM, attr);                
                oneself.attribute = attr;
                oneself.instance = instImage;

                pManager.sendMessage({
                    type : "updateAttribute",
                    data : attr,
                });

                pManager.sendMessage({
                    type : 'bindEvent',
                    data : instImage,
                })

                pManager.canvas.insertAt(instImage, pAttr.index).setActiveObject(instImage).requestRenderAll();

            }

        }
    }
}

DesignTool.Image = Image;

class Shape extends fabricObject{
    constructor(pAttr, pManager){
        super(pAttr, pManager);
        
        let oneself = this;

        initialize();

        function initialize(){
            let instShape = null;

            if(pAttr.width===0 && pAttr.height===0 && pAttr.radius===0 && pAttr.left===-10000 && pAttr.top===-10000){
                pAttr.width = 50;
                pAttr.height = 50;
                pAttr.radius = 25;
                pAttr.left = pManager.width/2 - pAttr.width/2;
                pAttr.top = pManager.height/2 - pAttr.height/2;
            }

            switch(pAttr.type){
                case 'Triangle':
                    instShape = new fabric.Triangle(pAttr);
                    break;
                case 'Circle':
                    instShape = new fabric.Circle(pAttr);
                    break;
                case 'Rect':
                    instShape = new fabric.Rect(pAttr);
                    break;
                case 'Line':
                    pAttr.height = 1;
                    instShape = new fabric.Rect(pAttr);
                    break;
                default:
            }
                  
            instShape.setOptions(pAttr);

            oneself.attribute = pAttr;
            oneself.instance = instShape;

            pManager.sendMessage({
                type : "updateAttribute",
                data : pAttr,
            });

            pManager.sendMessage({
                type : 'bindEvent',
                data : instShape,
            })

            pManager.canvas.insertAt(instShape, pAttr.index).setActiveObject(instShape).requestRenderAll();

        }
    }
}
DesignTool.Shape = Shape;