export const phoneOptions = [
  {
    value: "Please select phone case...",
    label: "Select Your Phone Case...",
    color: "#00B8D9",
    isDisabled: true
  },
  { value: "iPhone 6 Plus", label: "iPhone 6 Plus", color: "#00B8D9" },
  { value: "iPhone 7 - 8", label: "iPhone 7 - 8", color: "#0052CC" },
  {
    value: "iPhone 7 Plus - 8 Plus",
    label: "iPhone 7 Plus - 8 Plus",
    color: "#5243AA"
  },
  { value: "iPhone 11", label: "iPhone 11", color: "#FF5630" },
  { value: "iPhone 11 Pro", label: "iPhone 11 Pro", color: "#FF8B00" },
  { value: "iPhone 11 Pro Max", label: "iPhone 11 Pro Max", color: "#FFC400" },
  { value: "iPhone X", label: "iPhone X", color: "#36B37E" },
  { value: "iPhone XR", label: "iPhone XR", color: "#00875A" },
  { value: "iPhone XS Max", label: "iPhone XS Max", color: "#253858" },
  { value: "Samsung Note 9", label: "Samsung Note 9", color: "#666666" },
  { value: "Samsung Note 10", label: "Samsung Note 10", color: "#5243AA" },
  {
    value: "Samsung Note 10 Plus",
    label: "Samsung Note 10 Plus",
    color: "#FF5630"
  },
  { value: "Samsung S8 Plus", label: "Samsung S8 Plus", color: "#FF8B00" },
  { value: "Samsung S9", label: "Samsung S9", color: "#36B37E" },
  { value: "Samsung S9 Plus", label: "Samsung S9 Plus", color: "#00875A" },
  { value: "Samsung S10", label: "Samsung S10", color: "#253858" },
  { value: "Samsung S10E", label: "Samsung S10E", color: "#666666" },
  { value: "Samsung S20 Plus", label: "Samsung S20 Plus", color: "#666666" }
];
