const ACTIVE_OBJECT_INITAL_STATE = {
  //general
  index: -1,
  id: "",
  kind: "",
  type: "",
  top: -10000,
  left: -10000,
  width: 0,
  height: 0,
  angle: 0,
  scaleX: 1,
  scaleY: 1,
  originX: "left",
  originY: "top",

  // for shape
  radius: 0,

  // for image
  url: "",

  // for text
  text: "",
  textAlign: "center",
  fontFamily: "",
  fontSize: 25,
  fontStyle: "normal",
  fontWeight: "normal",
  underline: false,
  fill: "#000",
  stroke: "#F00",
  strokeWidth: 0.2,
  padding: 0
};
export const objectReducer = (state = [], action) => {
  switch (action.type) {
    case "OBJECT/ADD":
      let oldL = state.length,
        addingObj = { ...ACTIVE_OBJECT_INITAL_STATE, ...action.payload };
      addingObj.index = oldL - 1;
      return [].concat(state, addingObj);
    case "OBJECT/SEQUENCE":
      let { sourceIdx, targetIdx } = action.payload;
      let clone = state.slice();
      clone.splice(
        targetIdx < 0 ? clone.length + targetIdx : targetIdx,
        0,
        clone.splice(sourceIdx, 1)[0]
      );
      clone.forEach((obj, i) => {
        obj.index = i;
      });
      return clone;

    case "OBJECT/UPDATE":
      let _id1 = action.payload.id;
      return state.map((object) => {
        return object.id === _id1 ? { ...object, ...action.payload } : object;
      });

    case "OBJECT/DELETE":
      let _id2 = action.payload.id;
      let leaveObjects = state.filter(function (object) {
        return object.id !== _id2;
      });
      leaveObjects.map((obj, i) => {
        return (obj.index = i);
      });
      return leaveObjects;
    case "OBJECT/SET":
      return action.payload;

    default:
      return state;
  }
};

export const activeObjectReducer = (
  state = ACTIVE_OBJECT_INITAL_STATE,
  action
) => {
  switch (action.type) {
    case "OBJECT/SELECTED":
      return { ...ACTIVE_OBJECT_INITAL_STATE, ...action.payload };
    case "OBJECT/RELEASED":
      return { ...ACTIVE_OBJECT_INITAL_STATE };
    case "OBJECT/UPDATE":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
