import vCard from 'mycardedit';
import store from '../../redux/store';
// import firebase from "../../utils/firebase/firebase.utils";

// const { saveAs, encodeBase64 } = require('@progress/kendo-file-saver');

export default {
  generateCard: function () {
    // var userId = store.getState().user.currentUser.id;
    // const profileState = JSON.parse(store.getState().user.currentUser.profile);
    // const formData = profileState == "1" ? JSON.parse(store.getState().user.currentUser.formData) : profileState == "2" ? JSON.parse(store.getState().user.currentUser.formData2): profileState == "3" ? JSON.parse(store.getState().user.currentUser.formData3):JSON.parse(store.getState().user.currentUser.formData);
    // const croppedImg = profileState == "1" ? store.getState().user.currentUser.croppedImg : profileState == "2" ? store.getState().user.currentUser.croppedImg2 : profileState == "3" ? store.getState().user.currentUser.croppedImg3 : store.getState().user.currentUser.croppedImg;
    

    const profileState = JSON.parse(store.getState().user.currentUser?.profile);
    const formDataKey = profileState === 1 ? `formData` : `formData${profileState}`;
    const croppedImgKey = profileState === 1 ? `croppedImg` :`croppedImg${profileState}`;
    const formData = JSON.parse(store.getState().user.currentUser[formDataKey]);
    const croppedImg = store.getState().user.currentUser[croppedImgKey];

    // const stringImg = store.getState().user.currentUser.stringImg



    var firstName = formData.firstName;
    var lastName = formData.lastName;
    var company = formData.company;
    var title = formData.title;
    var cellPhone = formData.cellPhone;
    var workPhone = formData.workPhone;
    var homePhone = formData.homePhone;
    var persEmail = formData.persEmail;
    var workEmail = formData.workEmail;
    var link = formData.link;
    var workLink = formData.workLink;
    var homeStreet = formData.homeStreet;
    var homeCity = formData.homeCity;
    var homeState = formData.homeState;
    var homeZip = formData.homeZip;
    var workStreet = formData.workStreet;
    var workCity = formData.workCity;
    var workState = formData.workState;
    var workZip = formData.workZip;
    var birthday = formData.birthday;
    var socialType1 = formData.socialType1;
    var socialUserName1 = formData.socialUserName1;
    var socialLink1 = formData.socialLink1;
    var socialType2 = formData.socialType2;
    var socialUserName2 = formData.socialUserName2;
    var socialLink2 = formData.socialLink2;
    var socialType3 = formData.socialType3;
    var socialUserName3 = formData.socialUserName3;
    var socialLink3 = formData.socialLink3;
    var socialType4 = formData.socialType4;
    var socialUserName4 = formData.socialUserName4;
    var socialLink4 = formData.socialLink4;
    var socialType5 = formData.socialType5;
    var socialUserName5 = formData.socialUserName5;
    var socialLink5 = formData.socialLink5;
    var socialType6 = formData.socialType6;
    var socialUserName6 = formData.socialUserName6;
    var socialLink6 = formData.socialLink6;
    //  var image = croppedImg.substr(croppedImg.indexOf(",") + 1)
    var contact = vCard();

    //set properties
    contact.firstName = firstName;
    contact.lastName = lastName;
    contact.organization = company;
    contact.title = title;
    contact.cellPhone = cellPhone;
    contact.workPhone = workPhone;
    contact.homePhone = homePhone;
    contact.email = persEmail;
    contact.workEmail = workEmail;
    contact.url = link;
    contact.workUrl = workLink;
    // contact.homeAddress.label = homeStreet.value > 0 ? 'Home Address':null;
    function HomeStreet() {
      contact.homeAddress.label = homeStreet;
    }
    if (homeStreet) {
      HomeStreet();
    }

    contact.homeAddress.street = homeStreet;
    contact.homeAddress.city = homeCity;
    contact.homeAddress.stateProvince = homeState;
    contact.homeAddress.postalCode = homeZip;
    // contact.workAddress.label = workStreet.value > 0 ? 'Work Address':null;
    function WorkStreet() {
      contact.workAddress.label = workStreet;
    }
    if (workStreet) {
      WorkStreet();
    }

    contact.workAddress.street = workStreet;
    contact.workAddress.city = workCity;
    contact.workAddress.stateProvince = workState;
    contact.workAddress.postalCode = workZip;
    contact.birthday = new Date(birthday);

    const withHttp = url => !/^https?:\/\//i.test(url) ? `https://${url}` : url;


    function Social1() {
      contact.socialUrls[socialType1.replace(/\s+/g, '')] = {
        userName: socialUserName1,
        url: withHttp(socialLink1)
      };
    }

    if (socialType1) {
      Social1();
    }

    function Social2() {
      contact.socialUrls[socialType2.replace(/\s+/g, '')] = {
        userName: socialUserName2,
        url: withHttp(socialLink2)
      };
    }

    if (socialType2) {
      Social2();
    }

    function Social3() {
      contact.socialUrls[socialType3.replace(/\s+/g, '')] = {
        userName: socialUserName3,
        url: withHttp(socialLink3)
      };
    }

    if (socialType3) {
      Social3();
    }

    function Social4() {
      contact.socialUrls[socialType4.replace(/\s+/g, '')] = {
        userName: socialUserName4,
        url: withHttp(socialLink4)
      };
    }

    if (socialType4) {
      Social4();
    }

    function Social5() {
      contact.socialUrls[socialType5.replace(/\s+/g, '')] = {
        userName: socialUserName5,
        url: withHttp(socialLink5)
      };
    }

    if (socialType5) {
      Social5();
    }

    function Social6() {
      contact.socialUrls[socialType6.replace(/\s+/g, '')] = {
        userName: socialUserName6,
        url: withHttp(socialLink6)
      };
    }

    if (socialType6) {
      Social6();
    }

    function Image() {
      contact.photo.embedFromString(
        croppedImg.substr(croppedImg.indexOf(',') + 1),
        'image/png'
      );
    }

    if (croppedImg) {
      Image();
    }

    // contact.socialUrls[socialType2] = {};
    // contact.socialUrls[socialType2].userName = socialUserName2;
    // contact.socialUrls[socialType2].url = socialLink2;

    // contact.homeFax = '312-555-1616';
    // contact.workFax = '312-555-1717';
    // contact.homeAddress.countryRegion = 'United States of America';
    // contact.workAddress.countryRegion = 'United States of America';
    // contact.note = 'Notes on Eric';

    contact.version = '3.0';

    //get as formatted string
    var user = contact.getFormattedString();
    // console.log(user);

    // store.dispatch((saveUserCard(user,(userId))))
    localStorage.setItem('vCard', user);

    //send to vcard to firebase if needed

    // var db = firebase.firestore();
    // db.collection("users")
    //   .doc(`${localStorage.getItem("userId")}`)
    //   .update({
    //     vCard: `${user}`,
    //   })
    //   .then(function () {
    //     console.log("Document successfully written!");
    //   })
    //   .catch(function (error) {
    //     console.error("Error writing document: ", error);
    //   });

    //save to file

    // const dataURI = 'data:text/vcard;base64,' + encodeBase64(user);
    // saveAs(dataURI, 'user.vcf');
  }
};
