import React from "react";
import axios from "axios";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBInput,
} from "mdbreact";


class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
    };
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };


  handleSubmit(e){
    e.preventDefault();

    // SendMail(this.state)

    axios({
      method: "POST", 
      url:"https://us-central1-mytap-2d6fa.cloudfunctions.net/sendMail?dest=sales@mytap.net", 
      data:  this.state
    }).then((response)=>{
      if (response.data === 'Sended'){
        alert("Message Sent."); 
        this.resetForm()
      }else {
        alert("Message failed to send.")
      }
    })
  }

  resetForm() {
    this.setState({
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
    });
  }

  render() {
    return (
      <section className="contact-section my-5 contact" >
        <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
        <MDBCard>
          <MDBRow>
            <MDBCol lg="8">
              <MDBCardBody className="form"  >
                <h3 className="mt-4">
                  <MDBIcon icon="envelope" className="pr-2" />
                  Write to us:
                </h3>
                <MDBRow>
                  <MDBCol md="6">
                    <div className="md-form mb-0">
                      <MDBInput
                        type="text"
                        name="name"
                        id="form-contact-name"
                        label="Your name"
                        value={this.state.name}
                        onChange={this.onChange.bind(this)}
                      />
                    </div>
                  </MDBCol>
                  <MDBCol md="6">
                    <div className="md-form mb-0">
                      <MDBInput
                        type="text"
                        name="email"
                        id="form-contact-email"
                        label="Your email"
                        value={this.state.email}
                        onChange={this.onChange.bind(this)}
                      />
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="6">
                    <div className="md-form mb-0">
                      <MDBInput
                        type="text"
                        name="phone"
                        id="form-contact-phone"
                        label="Your phone"
                        value={this.state.phone}
                        onChange={this.onChange.bind(this)}
                      />
                    </div>
                  </MDBCol>
                  <MDBCol md="6">
                    <div className="md-form mb-0">
                      <MDBInput
                        type="text"
                        name="company"
                        id="form-contact-company"
                        label="Your company"
                        value={this.state.company}
                        onChange={this.onChange.bind(this)}
                      />
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol md="12">
                    <div className="md-form mb-0">
                      <MDBInput
                        type="textarea"
                        name="message"
                        id="form-contact-message"
                        label="Your message"
                        value={this.state.message}
                        onChange={this.onChange.bind(this)}
                      />
                      <MDBBtn rounded color="" type='submit'>
                        <MDBIcon icon="paper-plane" />
                      </MDBBtn>

                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCol>
            <MDBCol lg="4">
              <MDBCardBody className="contact h-100 black-text">
                <h3 className="my-4 pb-2 text-center ">CONTACT</h3>
                <ul className="text-lg-left list-unstyled ml-4">
                  <li>
                    <p>
                      <MDBIcon icon="envelope" className="pr-2" />
                      sales@mytap.net
                    </p>
                    
                  </li>
                </ul>
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBCard>
        </form>
      </section>
    );
  }


}

export default ContactUs;
