// You might be wondering: why do we need a routing reducer?
// In the landing page, the user can log in a few different ways.
// A couple of those ways are pretty straighforward.
// BUT, if the user clicks the log in link under the card, we want to redirect them to the card design page.
// Same idea for the log in link under the phone.
// Clicking on those buttons will set the proper redirect route in this reducer.
// We then access that route in App.js, where our routing logic lives.

const routingReducer = (state = "/contact-info", action) => {
  switch (action.type) {
    case "SET_REDIRECT":
      return action.payload;
    case "DEFAULT_REDIRECT":
      return "/contact-info";
    default:
      return state;
  }
};

export default routingReducer;
