import store from "./store";
import {
  ObjectActionTypeOpen,
  ObjectActionTypesModify
} from "./object/objects.types";

// ['a','b','c'].indexOf('a') > -1 === ['a','b','c'].includes('a')
//
var isLastAction = function isLastAction(lastAction, action) {
  return typeof action === "string"
    ? lastAction === action
    : action.indexOf(lastAction) > -1;
};

var lastActionMatchesAction = function lastActionMatchesAction(
  lastAction,
  action
) {
  switch (action) {
    case "anyChange":
      return ObjectActionTypesModify.indexOf(lastAction) > -1;
    case "open":
      return lastAction === ObjectActionTypeOpen;
    default:
      return isLastAction(lastAction, action);
  }
};

// diagramOn will return a function
// that allows you to unsubscribe from the store
// http://redux.js.org/docs/api/Store.html#subscribelistener
//
export let designOn = function designOn(action, fn) {
  return store.subscribe(function () {
    var state = store.getState();
    if (lastActionMatchesAction(state.lastAction, action)) {
      fn(state.lastAction, state.activeObject);
    }
  });
};
