import React from "react";

import StyledDemo from "./index";

import {
  MDBContainer,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter
} from "mdbreact";





class UploadLogo extends React.Component {
  state = {
    modal: false
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  render() {
    return (
      <MDBContainer>
        <MDBBtn
          className="btn unique-color-dark white-text"
          color="unique-color-dark"
          onClick={this.toggle}
        >
         <MDBIcon icon="file-upload" /> Upload Logo
        </MDBBtn>

        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalBody>
            <StyledDemo />

            
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              className="btn unique-color-dark white-text"
              color="unique-color-dark"
              onClick={this.toggle}
            >
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }
}


export default UploadLogo;
