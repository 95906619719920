import React, { useState, useEffect } from "react";
import { getSalesAdminData, getSalesAdminPromoCodes, getStripePaymentIntentData } from "../../utils/firebase/firebase.utils";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setCurrentUser } from "../../redux/user/user.actions";
import { selectCurrentUser } from "../../redux/user/user.selectors";


import {
  MDBInput,
  MDBIcon
 } from "mdbreact";

import CustomButton from "../../components/custom-button/customButton";


import {
 createNewPromoCode
} from "../../utils/firebase/firebase.utils";

import firebase from "firebase/app";

const SalesAdmin = ({currentUser}) => {
  console.log("Calling functional component")
  
  const [salesAdminData, setSalesAdminData] = useState([]);
  const [stripePromoCodes, setStripePromoCodes] = useState([]);
  const [redemptionsPerProductPerCode, setRedemptionsPerProductPerCode] = useState({})

  const [newPromoCode, setNewPromoCode] = useState("");
  
  useEffect(() => {
    // console.log("useEffect! (did current user change?: " + JSON.stringify(currentUser))
    saleAdminInit();
    getStripePromoCodes();
  }, [currentUser]);
  
  useEffect(() => {
    console.log("useEffect! (did stripe promo codes change?: " + JSON.stringify(stripePromoCodes.length))
    getRedemptions()
  }, [stripePromoCodes])

  
  const getRedemptions = async () => {
    if(!stripePromoCodes.length) return

    const temporaryStructure = {}

    const promises = stripePromoCodes.map(promoCode => {
      //ToDo : query by all promo code ids
      return firebase.database().ref('orders').orderByChild('promo').equalTo(promoCode.id).once("value" , snapshot => {
        const orders = snapshot.val()
        let parsedOrders;

        if (orders) {      
          parsedOrders = Object.values(orders)
          parsedOrders.forEach(function(doc) {
            let orderData = doc
            if (!temporaryStructure[orderData.item]) {
              temporaryStructure[orderData.item] = {}
            }
            const name = stripePromoCodes.find(x => x.id === orderData.promo).code
            const parentCode = stripePromoCodes.find(x => x.id === orderData.promo).coupon.id

            temporaryStructure[orderData.item][name] = (temporaryStructure[orderData.item][name] || 0) + 1
            temporaryStructure[orderData.item][parentCode] = (temporaryStructure[orderData.item][parentCode] || 0) + 1
          })
        }
      })
         
      // let db = firebase.firestore()
      // return db.collection("orders").where("promo", "==", promoCode.id)
      // .get()
      // .then(function(querySnapshot) {
      //   querySnapshot.forEach(function(doc) {
      //       let orderData = doc.data()
      //       if (!temporaryStructure[orderData.item]) {
      //         temporaryStructure[orderData.item] = {}
      //       }
      //       const name = stripePromoCodes.find(x => x.id === orderData.promo).code
      //       const parentCode = stripePromoCodes.find(x => x.id === orderData.promo).coupon.id

      //       temporaryStructure[orderData.item][name] = (temporaryStructure[orderData.item][name] || 0) + 1
      //       temporaryStructure[orderData.item][parentCode] = (temporaryStructure[orderData.item][parentCode] || 0) + 1
      //       console.log("state of tempStructure", temporaryStructure)


      //   });
      // })
      // .catch(function(error) {
      //   console.log("Error getting documents: ", error);
      // })
    })
    await Promise.all(promises)
    
    setRedemptionsPerProductPerCode(temporaryStructure)
    console.log("We've got state", temporaryStructure)
  }

  const saleAdminInit = async () => {
    console.log("Loading data")
    await getSalesAdminData().then((response) => {  
          //debugger
        let stuff = response.data.data.filter(coupon => currentUser.coupons && currentUser.coupons.indexOf(coupon.id) > -1);
        setSalesAdminData(stuff);
      })
  }
  const getStripePromoCodes = async () => {
    await getSalesAdminPromoCodes().then((response) => {
        const values = response.data.data.filter(promo => currentUser.coupons && currentUser.coupons.indexOf(promo.coupon.id) > -1)
        // if (salesAdminData.length && !values.length) { debugger}
        setStripePromoCodes(values);
        //getStripePromoCodeData(response.data.data.filter(promo => currentUser.coupons && currentUser.coupons.indexOf(promo.coupon.id) > -1));
      })
  }
  // function call to stripe to get current coupons object
  // reduce coupon number of redemptions
  // take current redemptions to display dollar amount
  /*  Card = $40 - $8 20%
      Discount = $32
      Sales Rep = $3.20 10%
      Travis Admin = $.064 2% of $3.20

      Case = $60 - $12 %20
      Discount = $48 
      Sales Rep = $4.80 10% commission
      Admin = .097 2% of $4.80
  */
  // console.log("sales data", data)
  const onSubmit = async (e) => {
    e.preventDefault();
    let newCodePayload ={
      code: newPromoCode,
      coupon: salesAdminData[0].id,
    }
    console.log('click event', newCodePayload)
    try {
      await createNewPromoCode(newCodePayload)
      getStripePromoCodes()
      setNewPromoCode("")
    } catch (e) {
      alert(`An error occurred creating the promo code: ${JSON.stringify(e)}`)
    }
  }
  
  return <div>
    <h1>Sales Admin</h1>
    <span>{currentUser.displayName}</span>

    <h2 style={{marginTop: "20px"}}>Admin data</h2>
    <table className="table">
      <thead>
        <tr>
          <th scope="col">
            Sales Manager Code
          </th>
          <th scope="col">
            Card Redemptions
          </th>
          <th scope="col">
            Card Earnings
          </th>
            
          <th scope="col">
            Case Redemptions
          </th>
          <th scope="col">
            Case Earnings
          </th>
          <th scope="col">
            Total Redemptions
          </th>
          <th scope="col">
            Total Earnings
          </th>
        </tr>
      </thead>
      <tbody>
        {salesAdminData.map(salesManager => {
          // console.log("Getting away with shit", redemptionsPerProductPerCode)
          return (
          <tr key={salesManager.id}>
            <td>
              {salesManager.id}
            </td>
            <td>
              {redemptionsPerProductPerCode['MyCard']?.[salesManager.id]  || 0}
            </td>
            <td>
              $ {((redemptionsPerProductPerCode['MyCard']?.[salesManager.id]  || 0) * .064 ).toFixed(2)}
            </td>
            <td>
              {redemptionsPerProductPerCode['MyCase']?.[salesManager.id]  || 0}
            </td>
            <td>
              $ {((redemptionsPerProductPerCode['MyCase']?.[salesManager.id]  || 0) * .097).toFixed(2)}
            </td>
            <td>
              {(redemptionsPerProductPerCode['MyCase']?.[salesManager.id]  || 0) + (redemptionsPerProductPerCode['MyCard']?.[salesManager.id]  || 0)}
            </td>
            <td>
              $ {(((redemptionsPerProductPerCode['MyCase']?.[salesManager.id]  || 0) * .097) + ((redemptionsPerProductPerCode['MyCard']?.[salesManager.id]  || 0) * .064 )).toFixed(2)}
            </td>
          </tr>
          )
        })}
      </tbody>
    </table>
    <h2>Promo Codes</h2>
    <div style={{display: 'flex'}}>
      <div style={{width: '60%'}}>
        <form id="addTeamMember" onSubmit={onSubmit}>
          <div className="grey-text">
                        <MDBInput
                          type="text"
                          label="Enter new Promo"
                          name="promocode"
                          onChange={e => setNewPromoCode(e.target.value)}
                          value={newPromoCode}
                        />
                      </div>

        </form>
      </div>
      
      <CustomButton style={{ margin: "auto" }} type="submit" onClick={e => onSubmit(e)}>
          <MDBIcon icon="plus-square" /> &nbsp;Add New Code
      </CustomButton>
  
    </div>
  
    <table className="table">
      <thead>
        <tr>
          <th scope="col">
            Promo Code / Sales Person
          </th>
          <th scope="col">
            Card Redemptions
          </th>
          <th scope="col">
            Card Earnings
          </th>
          
          <th scope="col">
            Case Redemptions
          </th>
          <th scope="col">
            Case Earnings
          </th>
          <th scope="col">
            Total Redemptions
          </th>
          <th scope="col">
            Total Earnings
          </th>
        </tr>
      </thead>
      <tbody>
        {stripePromoCodes.map(code => {
          return (
            <tr key={code.id}>
            <td>
              {code.code}
            </td>
            <td>
             {redemptionsPerProductPerCode['MyCard']?.[code.code] || 0}
            </td>
            <td>
              $ {((redemptionsPerProductPerCode['MyCard']?.[code.code]  || 0) * 3.20).toFixed(2)}
            </td>
            <td>
              {redemptionsPerProductPerCode['MyCase']?.[code.code] || 0}
            </td>
            <td>
              $ {((redemptionsPerProductPerCode['MyCase']?.[code.code]  || 0 ) * 4.80).toFixed(2) || 0}
            </td>
            <td>
              {(redemptionsPerProductPerCode['MyCase']?.[code.code] || 0 ) + (redemptionsPerProductPerCode['MyCard']?.[code.code] || 0)}
            </td>
            <td>
              $ {(((redemptionsPerProductPerCode['MyCase']?.[code.code]  || 0) * 4.80) + ((redemptionsPerProductPerCode['MyCard']?.[code.code]  || 0) * 3.20)).toFixed(2)}
            </td>
          </tr>
          )
        })} 
      </tbody>
    </table>

  </div>;
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => {
    return dispatch(setCurrentUser(user))
  }
});

const NoUserCouponsNoRender = function ({currentUser}) {

  if (currentUser && currentUser.coupons) {
    return <SalesAdmin currentUser={currentUser} />
  }
  return null
}

export default connect(mapStateToProps, mapDispatchToProps)(NoUserCouponsNoRender);
