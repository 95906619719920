import React from "react";

import NavHeader from "./NavHeader";
import SideNav from "./SideNav";

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.childRef = React.createRef();
    this.state = {
      sideNavHidden: true
    };
  }

  handleClick = () => {
    if (this.state.sideNavHidden) {
      // attach/remove event handler
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }

    this.toggleSideNav();
  };

  handleOutsideClick = (e) => {
    // do nothing on design page
    const routes = window.location.href.split("/");
    if (routes.includes("design")) {
      return null;
    }

    // ignore clicks on the component itself
    if (this.childRef.contains(e.target)) {
      return;
    }

    this.handleClick();
  };

  toggleSideNav = () => {
    this.setState((prevState) => ({ sideNavHidden: !prevState.sideNavHidden }));
  };

  render() {
    return (
      <div>
        <NavHeader toggleSideNav={this.handleClick} />
        <SideNav
          ref={(node) => {
            this.childRef = node;
          }}
          hidden={this.state.sideNavHidden}
          toggleSideNav={this.handleClick}
        />
      </div>
    );
  }
}

export default Nav;
