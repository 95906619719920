import React from "react";
import { connect } from "react-redux";
import {
  MDBMedia,
  MDBCard,
  MDBContainer,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBTooltip,
  MDBCardFooter,
  MDBBtn,
  MDBLink,
  MDBIcon
} from "mdbreact";

import { nanoid } from "nanoid";
import firebase from "../../utils/firebase/firebase.utils";

import { defaultRedirect } from "../../redux/routing/routing.actions";
import { fetchDesigns } from "../../redux/designImages/designImages.actions";

import API2 from "../../components/generateQr/generateMultiUserQR";
import API3 from "../../components/renderDirectCardImage/renderMultiDirectCardImage";

import UploadLogo from "../../components/upload-your-logo-multiUser/uploadLogo";
import UploadLogoFront from "../../components/upload-your-logo-front-multiUser/uploadLogo";

import { CircularProgress, Box } from "@material-ui/core";

// Stripe
import { ElementsConsumer } from "@stripe/react-stripe-js";

//

import {
  createSession,
  createStripeCustomer
} from "../../utils/firebase/firebase.utils";
import { updateUser } from "../../redux/user/user.actions";

import { Carousel } from "react-bootstrap";
import Splash from "../splash/splash";
import "./RMO.styles.scss";

import RW1 from "./assets/images/IDCard/RW1.png";
import RW2 from "./assets/images/IDCard/RW2.png";
import cardBackPlaceholder from "./assets/images/IDCard/mytapQr.png";
import CardBack from "./assets/images/IDCard/card-back copy.png";
import cardFront from "./assets/images/IDCard/card-front.png";
import logoPlaceHolder from "./assets/images/IDCard/logo-Placeholder.jpg";

class SugarcoatAdminOrders extends React.Component {
  state = {
    MyTapReviewCardURL: "",
    longUrlList: "",
    loading: false,
    oldLinkId: "",
    saveBtn: "SAVE",
    selectedIndex: ""
  };

  componentDidMount() {
    // this.props.defaultRedirect();
    // this.props.fetchDesigns();
    // console.log('dddddd',this.props.user.id)
    // console.log('bbbbbb',this.props.currentUser)
  }

  // Redirect to fulfillment page when applicable
  // componentDidUpdate() {
  //   if (
  //     this.props.currentUser &&
  //     this.props.currentUser.role === "fulfillment"
  //   ) {
  //     this.props.history.push("/fulfillment");
  //   }
  //   // this.props.fetchDesigns();

  // }

  onDirectURLChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSelect = (selectedIndex) => {
    this.setState({
      selectedIndex: selectedIndex
    });
  };

  // checkDuplicate() {
  //   firebase
  //     .firestore()
  //     .collection(`users/${this.props.userId}/links`)
  //     .get()
  //     .then((querySnapshot) => {
  //       querySnapshot.forEach((doc) => {
  //         // console.log(doc.id, " => ", doc.data());
  //         const longUrlList = doc.data().longURL;
  //         console.log("referferferferfrerfe", longUrlList);
  //         this.setState({longUrlList:longUrlList})
  //       });
  //     });
  // }

  deleteOldShortUrl() {
    firebase
      .firestore()
      .collection(`users/${this.props.userId}/links`)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log(doc.id, " => ", doc.data());
          const linkDocId = doc.id;
          // console.log("this is the idddddd", linkDocId);
          this.setState({ oldLinkId: linkDocId });
        });
      })
      .then(() => {
        firebase
          .firestore()
          .collection("users")
          .doc(this.props.userId)
          .collection("links")
          .doc(this.state.oldLinkId)
          .delete();
      })
      .then(() => {
        this.createShortLink();
      });
  }

  createShortLink() {
    var db = firebase.firestore();
    const link = {
      name: "relink",
      longURL:
        this.state.MyTapReviewCardURL.includes("http://") ||
        this.state.MyTapReviewCardURL.includes("https://")
          ? this.state.MyTapReviewCardURL
          : `http://${this.state.MyTapReviewCardURL}`,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      shortCode: nanoid(6),
      totalClicks: 0,
      clickDates: []
    };

    db.collection("users")
      .doc(`${this.props.userId}`)
      .collection("links")
      .add(link)
      .then(() => {
        db.collection("users")
          .doc(`${this.props.userId}`)
          .update({
            shortUrl: `https://www.mytap.net/rd/${link.shortCode}`
          });
      });
  }

  async updateShortLink() {
    var db = firebase.firestore();
    const longCode = this.props.shortUrl;
    const shortCode = longCode.substring(longCode.lastIndexOf("/") + 1);
    const linkDoc = await db.collection("links").doc(shortCode).get();
    const { longURL, linkID, userId } = linkDoc.data();
    // console.log("yooooooooooo", linkDoc.data());
    // console.log('yooooooooooo', longURL)
    // console.log(shortCode);

    db.collection("links")
      .doc(shortCode)
      .update({
        longURL:
          this.state.MyTapReviewCardURL.includes("http://") ||
          this.state.MyTapReviewCardURL.includes("https://")
            ? this.state.MyTapReviewCardURL
            : `https://${this.state.MyTapReviewCardURL}`
      })
      .then(() => {
        db.collection("users")
          .doc(userId)
          .collection("links")
          .doc(linkID)
          .update({
            longURL:
              this.state.MyTapReviewCardURL.includes("http://") ||
              this.state.MyTapReviewCardURL.includes("https://")
                ? this.state.MyTapReviewCardURL
                : `https://${this.state.MyTapReviewCardURL}`
          });
      });
  }

  saveMyTapReviewCardURLToDB(e) {
    var db = firebase.firestore();
    db.collection("users").doc(`${this.props.userId}`).update({
      MyTapReviewCardURL: this.state.MyTapReviewCardURL.toString()
    });
    this.updateShortLink();

    this.setState({ saveBtn: "SAVED!" });
    setTimeout(() => {
      this.setState({ saveBtn: "SAVE" });
    }, 3000);
  }

  generateAndBuy() {
    // var db = firebase.firestore();
    // db.collection("users").doc(`${this.props.userId}`).update({
    //   orderPlaced: true
    // });

    if (!this.props.MyTapReviewCardURL) {
      this.saveMyTapReviewCardURLToDB();
    }

    if (this.props.shortUrl) {
      // this.deleteOldShortUrl();
      // console.log("link is", this.props.shortUrl);
      this.updateShortLink();
    } else {
      this.createShortLink();
    }
    setTimeout(() => {
      API2.generateMultiUserQR();
    }, 1000);

    setTimeout(() => {
      API3.renderMultiDirectCardImage();
    }, 3000);
  }

  render() {
    const { currentUser } = this.props.selectedUser;

    if (this.state.loading)
      return (
        <Box mt={10} textAlign="center">
          <CircularProgress />
        </Box>
      );

    return (
      <div className="mt-1 pt-3 shop-page">
        <div className="order-page__items">
          <div className="shop-item shop-item--card">
     

          <Carousel
              activeIndex={this.index}
              onSelect={this.handleSelect}
              controls={true}
              interval={null}
              variant="dark"
            >
              <Carousel.Item className="p-4">


                <img
                  className="shop-item__image shop-item__image--card"
                  alt="card"
                  src={CardBack}
                />

                <img
                  style={{
                    width: "28%",
                    position: "absolute",
                    top: "26%",
                    left: "52.5%"
                  }}
                  alt="Qr"
                  src={
                    this.props.reviewCardUploadedLogo? this.props.reviewCardUploadedLogo : logoPlaceHolder
                  }
                />

                <img
                  style={{
                    width: "29%",
                    position: "absolute",
                    top: "25%",
                    left: "19.5%"
                  }}
                  alt="Qr"
                  src={
                    this.props.QrImg? this.props.QrImg : cardBackPlaceholder
                  }
                />

            </Carousel.Item>


          <Carousel.Item className="p-4">


                <img
                  className="shop-item__image shop-item__image--card"
                  alt="card"
                  src={cardFront}
                />
                <img
                className="shop-item__image shop-item__image--card"
                  style={{
                    // width: "47.5%",
                    // position: "absolute",
                    // top: "9.5%",
                    // left: "27%"
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: 'translate(-50%, -50%)',
                    width: '78%',
                    height: "auto"
                  }}
                  alt="Qr"
                  src={this.props.reviewCardUploadedFrontLogo? this.props.reviewCardUploadedFrontLogo : logoPlaceHolder}
                />
  
              </Carousel.Item>
            </Carousel>


            <MDBCardBody cascade className="text-center">
              <MDBCardTitle>
                {!this.props.orderPlaced ? 
                this.state.selectedIndex === 1? <UploadLogoFront /> :<UploadLogo /> 
                : null}
              </MDBCardTitle>
              <MDBCardText>
                <div>
                  <label>URL: &nbsp;</label>
                  <input
                    style={{ width: "70%" }}
                    onChange={this.onDirectURLChange.bind(this)}
                    // onBlur={this.saveMyTapReviewCardURLToDB.bind(this)}
                    // disabled={this.props.orderPlaced}
                    type="text"
                    name="MyTapReviewCardURL"
                    placeholder={this.props.MyTapReviewCardURL}
                    value={this.state.MyTapReviewCardURL}
                  ></input>

                  {this.props.orderPlaced ? (
                    <MDBBtn
                      onClick={this.saveMyTapReviewCardURLToDB.bind(this)}
                    >
                      {this.state.saveBtn}
                    </MDBBtn>
                  ) : null}

                  {this.props.orderPlaced ? this.props.shortUrl : null}
                  {/* <p>Current: {this.props.MyTapReviewCardURL}</p> */}
                </div>
              </MDBCardText>
              <MDBCardFooter>
                <span className="float-left">
                  <div className="shop-item__price">$50</div>
                </span>
                <span className="float-right">
                  <MDBTooltip placement="top">
                    <MDBBtn color="transparent" className="p-1 m-0 z-depth-0">
                      <ElementsConsumer>
                        {({ stripe, elements }) => (
                          <form
                            onSubmit={async (e) => {
                              this.setState({ loading: true });
                              e.preventDefault();
                              this.generateAndBuy();
                              if (!currentUser.stripeCustomerId) {
                                return await createStripeCustomer(
                                  currentUser
                                ).then(async () => {
                                  const session = await createSession(
                                    "ReviewCard",
                                    currentUser.uid
                                  );
                                  stripe.redirectToCheckout({
                                    sessionId: session.data.id
                                  });
                                  console.log("session", session);
                                });
                              } else {
                                const session = await createSession(
                                  "ReviewCard",
                                  currentUser.uid
                                );
                                stripe.redirectToCheckout({
                                  sessionId: session.data.id
                                });
                                console.log("session", session);
                              }
                            }}
                          >
                            <MDBBtn
                              disabled={
                                this.state.MyTapReviewCardURL.length < 4 ||
                                !this.props.reviewCardUploadedLogo || !this.props.reviewCardUploadedFrontLogo
                              }
                              color="transparent"
                              className="p-1 m-0 z-depth-0"
                              type="submit"
                            >
                              <MDBIcon fas size="lg" icon="shopping-cart" />
                            </MDBBtn>

                            {/* {!this.props.orderPlaced ? (
                              <MDBBtn
                                disabled={
                                  this.state.MyTapReviewCardURL.length < 4 ||
                                  !this.props.reviewCardUploadedLogo
                                }
                                color="transparent"
                                className="p-1 m-0 z-depth-0"
                                type="submit"
                              >
                                <MDBIcon fas size="lg" icon="shopping-cart" />
                              </MDBBtn>
                            ) : null} */}
                          </form>
                        )}
                      </ElementsConsumer>
                    </MDBBtn>
                    {this.state.MyTapReviewCardURL.length < 4 ||!this.props.reviewCardUploadedLogo || !this.props.reviewCardUploadedFrontLogo ? 
                    <div>Must provide both logos and link!</div>: <div>Order!</div>
                    }
                    
                  </MDBTooltip>
                </span>
              </MDBCardFooter>
            </MDBCardBody>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log('dddddd',this.props.user.id)
  // console.log('bbbbbb',this.props.currentUser)
  // console.log('selectedUser',state.selectedUser.currentUser?.uid)

  return {
    reviewCardUploadedLogo:
      state.selectedUser.currentUser?.reviewCardUploadedLogo,
      reviewCardUploadedFrontLogo:state.selectedUser.currentUser?.reviewCardUploadedFrontLogo,
    MyTapReviewCardURL: state.selectedUser.currentUser?.MyTapReviewCardURL,
    reviewCardImage: state.selectedUser.currentUser?.reviewCardImage,
    QrImg: state.selectedUser.currentUser?.QrImg,
    shortUrl: state.selectedUser.currentUser?.shortUrl,
    orderPlaced: state.selectedUser.currentUser?.orderPlaced,
    selectedUser: state.selectedUser,
    userId: state.selectedUser.currentUser?.uid
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateUser: (payload) => {
      dispatch(updateUser(payload));
    },
    defaultRedirect: () => {
      dispatch(defaultRedirect());
    },
    fetchDesigns: (payload) => {
      dispatch(fetchDesigns(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SugarcoatAdminOrders);
