import React, { Component } from "react";
import { auth } from "../../utils/firebase/firebase.utils";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBIcon,
  MDBCard,
  MDBCardBody
} from "mdbreact";
import { Link } from "react-router-dom";
import CustomButton from "../../components/custom-button/customButton";

class PasswordReset extends Component {
  constructor() {
    super();
    this.state = {
      email: ""
    };
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  reset=()=>{
    this.setState({email: ""})
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const email = this.state.email
    await auth
      .sendPasswordResetEmail(email)
      .then(function () {
        // Email sent.
        alert("Email Sent");        
      })
      .catch(function (error) {
        // An error happened.
      });
      this.reset()

  };

  render() {
    return (
      <div>
        <MDBContainer size='fluid' style={{ marginTop: "60px" }}>
          <Link style={{ textDecoration: "none" }} to="/">
            <button className="custom-button">
              <MDBIcon icon="angle-left" /> &nbsp; Back to home
            </button>
          </Link>

          <MDBRow>
            <MDBCol size="3"></MDBCol>
            <MDBCol md="6">
              <MDBCard>
                <MDBCardBody>
                  <form onSubmit={this.handleSubmit}>
                    <p
                      style={{ fontFamily: "Roboto" }}
                      className="h5 text-center mb-4"
                    >
                      RESET YOUR PASSWORD
                    </p>
                    <div className="grey-text">
                      <MDBInput
                        label="Type your email"
                        name="email"
                        icon="envelope"
                        group
                        type="email"
                        validate
                        error="wrong"
                        success="right"
                        onChange={this.onChange}
                        value={this.state.email}
                      />
                    </div>
                    <div className="text-center">
                      <CustomButton style={{ margin: "auto" }} type="submit">
                        RESET
                      </CustomButton>
                    </div>
                  </form>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>


    );
  }
}

export default PasswordReset;
