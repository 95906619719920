import React from "react";
import { connect } from "react-redux";
import {
  MDBMedia,
  MDBCard,
  MDBContainer,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBTooltip,
  MDBCardFooter,
  MDBBtn,
  MDBLink,
  MDBIcon
} from "mdbreact";

import { nanoid } from "nanoid";
import firebase from "../../utils/firebase/firebase.utils";

import { defaultRedirect } from "../../redux/routing/routing.actions";
import { fetchDesigns } from "../../redux/designImages/designImages.actions";

import API2 from "../../components/generateQr/generateMultiUserQR";
import API3 from "../../components/renderDirectCardImage/renderMultiDirectCardImage";

import UploadLogo from "../../components/upload-your-logo-multiUser/uploadLogo";
import { CircularProgress, Box } from "@material-ui/core";

// Stripe
import { ElementsConsumer } from "@stripe/react-stripe-js";

//

import {
  createSession2,
  createStripeCustomer
} from "../../utils/firebase/firebase.utils";
import { updateUser } from "../../redux/user/user.actions";

import Splash from "../splash/splash";
import "./RMO.styles.scss";

import RW1 from "./assets/images/IDCard/RW1.png";
import RW2 from "./assets/images/IDCard/RW2.png";

class RangeWaterMultiOrder extends React.Component {
  state = {
    MyTapReviewCardURL: "",
    longUrlList: "",
    loading: false,
    oldLinkId: ""
  };

  componentDidMount() {
    // this.props.defaultRedirect();
    // this.props.fetchDesigns();
    // console.log('dddddd',this.props.user.id)
    // console.log('bbbbbb',this.props.currentUser)
  }

  // Redirect to fulfillment page when applicable
  // componentDidUpdate() {
  //   if (
  //     this.props.currentUser &&
  //     this.props.currentUser.role === "fulfillment"
  //   ) {
  //     this.props.history.push("/fulfillment");
  //   }
  //   // this.props.fetchDesigns();

  // }

  onDirectURLChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  // checkDuplicate() {
  //   firebase
  //     .firestore()
  //     .collection(`users/${this.props.userId}/links`)
  //     .get()
  //     .then((querySnapshot) => {
  //       querySnapshot.forEach((doc) => {
  //         // console.log(doc.id, " => ", doc.data());
  //         const longUrlList = doc.data().longURL;
  //         console.log("referferferferfrerfe", longUrlList);
  //         this.setState({longUrlList:longUrlList})
  //       });
  //     });
  // }

  deleteOldShortUrl() {
    firebase
      .firestore()
      .collection(`users/${this.props.userId}/links`)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log(doc.id, " => ", doc.data());
          const linkDocId = doc.id;
          // console.log("this is the idddddd", linkDocId);
          this.setState({ oldLinkId: linkDocId });
        });
      })
      .then(() => {
        firebase
          .firestore()
          .collection("users")
          .doc(this.props.userId)
          .collection("links")
          .doc(this.state.oldLinkId)
          .delete();
      })
      .then(() => {
        this.createShortLink();
      });
  }

  createShortLink() {
    var db = firebase.firestore();
    const link = {
      name: "relink",
      longURL:
        this.state.MyTapReviewCardURL.includes("http://") ||
        this.state.MyTapReviewCardURL.includes("https://")
          ? this.state.MyTapReviewCardURL
          : `http://${this.state.MyTapReviewCardURL}`,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      shortCode: nanoid(6),
      totalClicks: 0,
      clickDates: []
    };

    db.collection("users")
      .doc(`${this.props.userId}`)
      .collection("links")
      .add(link)
      .then(() => {
        db.collection("users")
          .doc(`${this.props.userId}`)
          .update({
            shortUrl: `https://www.mytap.net/rd/${link.shortCode}`
          });
      });
  }

  saveMyTapReviewCardURLToDB(e) {
    var db = firebase.firestore();
    db.collection("users").doc(`${this.props.userId}`).update({
      MyTapReviewCardURL: this.state.MyTapReviewCardURL.toString()
    });
  }

  generateAndBuy() {
    API2.generateMultiUserQR();

    if (this.props.shortUrl) {
      this.deleteOldShortUrl();
    } else {
      this.createShortLink();
    }

    setTimeout(() => {
      API3.renderMultiDirectCardImage();
    }, 250);
  }

  render() {
    const { currentUser } = this.props.selectedUser;

    if (this.state.loading)
      return (
        <Box mt={10} textAlign="center">
          <CircularProgress />
        </Box>
      );

    return (
      <div className="mt-1 pt-3 shop-page">
        <div className="order-page__items">
          <div className="shop-item shop-item--card">
            <MDBMedia
              object
              className="shop-item__image shop-item__image--card--classic"
              src={RW1}
              alt=""
            />

            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img
                className="shop-item__image shop-item__image--card--classic"
                style={{ width: "100%" }}
                alt="card"
                src={RW2}
              />
              {this.props.reviewCardUploadedLogo ? (
                <img
                  style={{
                    position: "absolute",
                    top: "22%",
                    width: "31%",
                    right: "16%",
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "right"
                  }}
                  alt="Qr"
                  src={this.props.reviewCardUploadedLogo}
                />
              ) : null}
            </div>

            <MDBCardBody cascade className="text-center">
              <MDBCardTitle>
                {!this.props.orderPlaced ? <UploadLogo /> : null}
              </MDBCardTitle>
              <MDBCardText>
                <div>
                  <label>URL: &nbsp;</label>
                  <input
                  style={{width:'90%'}}
                    onChange={this.onDirectURLChange.bind(this)}
                    onBlur={this.saveMyTapReviewCardURLToDB.bind(this)}
                    disabled={this.props.orderPlaced}
                    type="text"
                    name="MyTapReviewCardURL"
                    placeholder={this.props.MyTapReviewCardURL}
                    value={this.state.MyTapReviewCardURL}
                  ></input>
                  {/* <p>Current: {this.props.MyTapReviewCardURL}</p> */}
                </div>
              </MDBCardText>
              <MDBCardFooter>
                <span className="float-left">
                  {this.props.orderPlaced ? null : (
                    <div className="shop-item__price">$3-5/month</div>
                  )}
                </span>
                <span className="float-right">
                  <MDBTooltip placement="top">
                    <MDBBtn color="transparent" className="p-1 m-0 z-depth-0">
                      <ElementsConsumer>
                        {({ stripe, elements }) => (
                          <form
                            onSubmit={async (e) => {
                              this.setState({ loading: true });
                              e.preventDefault();
                              this.generateAndBuy();
                              if (!currentUser.stripeCustomerId) {
                                return await createStripeCustomer(
                                  currentUser
                                ).then(async () => {
                                  const session = await createSession2(
                                    "MyTap Smart Review Card",
                                    currentUser.uid
                                  );
                                  stripe.redirectToCheckout({
                                    sessionId: session.data.id
                                  });
                                  console.log("session", session);
                                });
                              } else {
                                const session = await createSession2(
                                  "MyTap Smart Review Card",
                                  currentUser.uid
                                );
                                stripe.redirectToCheckout({
                                  sessionId: session.data.id
                                });
                                console.log("session", session);
                              }
                            }}
                          >
                            {!this.props.orderPlaced ? (
                              <MDBBtn
                                disabled={
                                  this.state.MyTapReviewCardURL.length < 4 ||
                                  !this.props.reviewCardUploadedLogo
                                }
                                color="transparent"
                                className="p-1 m-0 z-depth-0"
                                type="submit"
                              >
                                <MDBIcon fas size="lg" icon="shopping-cart" />
                              </MDBBtn>
                            ) : null}
                          </form>
                        )}
                      </ElementsConsumer>
                    </MDBBtn>
                    <div>Order!</div>
                  </MDBTooltip>
                </span>
              </MDBCardFooter>
            </MDBCardBody>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log('dddddd',this.props.user.id)
  // console.log('bbbbbb',this.props.currentUser)
  // console.log('selectedUser',state.selectedUser.currentUser?.uid)

  return {
    reviewCardUploadedLogo:
      state.selectedUser.currentUser?.reviewCardUploadedLogo,
    MyTapReviewCardURL: state.selectedUser.currentUser?.MyTapReviewCardURL,
    shortUrl: state.selectedUser.currentUser?.shortUrl,
    orderPlaced: state.selectedUser.currentUser?.orderPlaced,
    selectedUser: state.selectedUser,
    userId: state.selectedUser.currentUser?.uid
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateUser: (payload) => {
      dispatch(updateUser(payload));
    },
    defaultRedirect: () => {
      dispatch(defaultRedirect());
    },
    fetchDesigns: (payload) => {
      dispatch(fetchDesigns(payload));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RangeWaterMultiOrder);
