import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/database";
import "firebase/functions";
require("dotenv").config();

// const key = process.env.API_KEY;

const config = {
  apiKey: "AIzaSyBXoxTmjpnseSqHEXzX3ZEq2FqHgTgPHXM",
  authDomain: "mytap-2d6fa.firebaseapp.com",
  databaseURL: "https://mytap-2d6fa.firebaseio.com",
  projectId: "mytap-2d6fa",
  storageBucket: "mytap-2d6fa.appspot.com",
  messagingSenderId: "774375704684",
  appId: "1:774375704684:web:72d66de58b375c70f13049",
  measurementId: "G-Z4HYEYM77K",
};

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;
  const userRef = firestore.doc(`users/${userAuth.uid}`);
  const snapShot = await userRef.get();
  if (!snapShot.exists) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();

    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        ...additionalData,
      });
    } catch (error) {
      console.log('error creating user', error.message)
    }
  }
  return userRef
};

firebase.initializeApp(config);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const database = firebase.database();
export const functions = firebase.functions();

export const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export const provider2 = new firebase.auth.FacebookAuthProvider();
provider2.setCustomParameters({ prompt: "select_account" });
export const signInWithFacebook = () => auth.signInWithPopup(provider2);

const firebaseCreateOrder = functions.httpsCallable('createOrder');
const firebaseUpdatePaymentMethod = functions.httpsCallable('updatePaymentMethod');
const firebaseCreateSession = functions.httpsCallable('createSession');
const firebaseCreateSession2 = functions.httpsCallable('createSession2');
const firebaseCreateStripeCustomer = functions.httpsCallable('createStripeCustomer')
const firebaseGetSalesData = functions.httpsCallable('getSalesAdminData')
const firebaseGetStripePromoCodes = functions.httpsCallable('getSalesAdminPromoCodes')
const firebaseCreateNewPromoCode = functions.httpsCallable('setNewPromoCode')
const firebaseGetStripePaymentIntentData = functions.httpsCallable('getPaymentIntentData')
const firebaseDeleteUser = functions.httpsCallable('adminDeleteUser');


export const updatePaymentMethod = async (paymentMethod) => {
  return await firebaseUpdatePaymentMethod({ paymentMethod });
};

export const createOrder = async (productType) => {
  return await firebaseCreateOrder({
    productType,
  });
}

export const createSession = async (productType,currentUser) => {
  return await firebaseCreateSession({
    successUrl: "https://www.mytap.net/contact-info",
    cancelUrl: "https://www.mytap.net/shop",
    productType,
    currentUser
  });
}

export const createSession2 = async (productType,currentUser) => {
  return await firebaseCreateSession2({
    successUrl: "https://www.mytap.net/contact-info",
    cancelUrl: "https://www.mytap.net/shop",
    productType,
    currentUser
  });
}

export const createStripeCustomer = async (currentUser) => {
  return await firebaseCreateStripeCustomer({
    currentUser
  })
}

export const getStripePaymentIntentData = async () => {
  return await firebaseGetStripePaymentIntentData()
}

export const getSalesAdminData = async () => {
  return await firebaseGetSalesData()
}

export const getSalesAdminPromoCodes = async () => {
  return await firebaseGetStripePromoCodes()
}

export const createNewPromoCode = async (data) => {
  return await firebaseCreateNewPromoCode(data)
}

export const adminDeleteUser = async (id) => {
  return await firebaseDeleteUser({
    id
  })
}

// export const provider = new firebase.auth.FacebookAuthProvider();
// provider.setCustomParameters({ 'display': 'popup' });
// export const signInWithFacebook = () => auth.signInWithPopup(provider);

// export { storage, firebase as default}
export default firebase;
