import React, { Component } from "react";
import { connect } from "react-redux";
import { addObject } from "../../../redux/object/object.actions";

class Image extends Component {

  state = {
    imgInfo : {
      file: null,
      imageURL: "",
    },
  }

  componentDidMount() {}

  componentDidUpdate() {}

  selectImage = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    let _this = this;
    let _fileInput = e.target;

    reader.onloadend = () => {
      let imgInfo = {
        file: file,
        imageURL: reader.result,
      };
      
      this.setState({imgInfo : imgInfo});
      _this.props.addImage({
        id : Math.floor(Math.random()*10000)+'_'+ new Date().getTime(),
        kind : "Image",
        type : 'image',
        url : imgInfo.imageURL,
      })

      _fileInput.value = "";
    };

    reader.readAsDataURL(file);
  };

  render() {
    return (
      <div className='itemWraper'>
        <input className="fileInput" type="file" onChange={(e) => this.selectImage(e)} />
        {/* <img src={this.state.imgInfo.imageURL} className='hidden1' style={{width:'200px', height:'auto'}}/> */}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // prop: state.prop
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addImage: (payload) => {
      dispatch(addObject(payload))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Image);
