import React from "react";
import { MDBIcon, MDBModal, MDBModalBody, MDBBtn } from "mdbreact";
import { auth } from "../../utils/firebase/firebase.utils";

const LogoutModal = ({ isOpen, toggleModal, history, toggleSideNav }) => {
  const onLogoutClick = async (e) => {
    e.preventDefault();
    toggleSideNav();
    toggleModal(false);
    history.push("/");
    await auth.signOut();
  };

  return (
    <MDBModal isOpen={isOpen} toggle={toggleModal} className="logout-modal">
      {/* <MDBModalHeader toggle={() => toggleModal(false)}></MDBModalHeader> */}
      <MDBModalBody className="logout-modal__body">
        <MDBBtn onClick={onLogoutClick} 
        className="btn unique-color-dark white-text pushBtn"
        color="unique-color-dark">
          Log Out <MDBIcon icon="sign-out-alt" />
        </MDBBtn>
      </MDBModalBody>
    </MDBModal>
  );
};

export default LogoutModal;
