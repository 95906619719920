import React from "react";
import { connect } from "react-redux";

import { MDBContainer } from "mdbreact";

import "./DesignPhone.css";

// import ToolBar from "../components/ToolBar.js"
import ContentPanel from "../../components/content-panel/ContentPanel";
import DrawingArea from "../../components/drawing-area/DrawingArea";
import { configViewport } from "../../redux/drawing-area/drawing-area.actions";
import { addObject } from "../../redux/object/object.actions";

class DesignPhone extends React.Component {
  state = {
    width: 800,
    height: 800,
    top: 0
  };

  componentDidMount() {
    const root = document.getElementById("root");
    root.classList.remove("padding-for-sidenav");
  }

  componentWillUnmount() {
    const root = document.getElementById("root");
    root.classList.add("padding-for-sidenav");
  }

  UNSAFE_componentWillMount(){
    if(!this.props.designState) this.props.history.push('/');
    let sideBarWidth = this.props.mobile ? 0 : 60+250;
    // let margin = this.props.mobile ? 100 : 100;
    let w = window.innerWidth - sideBarWidth, h = window.innerHeight-64;
    let size = Math.min(w,h);
    let top  = this.props.mobile ? 35 : (h-size)/2;

    // window.onresize = ()=>{
    //     this.setState({
    //         width : size,
    //         height : size,
    //         top : top,
    //     });
    // }
    this.setState({
        width : size, height : size,
        top : top,
    });

    this.props.addPhoneCase({
        id : Math.floor(Math.random()*10000)+'_'+ new Date().getTime(),
        kind : "Phone",
        type : 'image',
        url : this.props.designType.path,
        name : this.props.designType.name,
    });
}

render (){
    return (
        <MDBContainer fluid  className='p-0'>
            {/* <ToolBar /> */}
            <ContentPanel drawingH={this.state.height}/>
            <DrawingArea width={this.state.width} height={this.state.height}  top={this.state.top} />            
        </MDBContainer>
    )
}
}

const mapStateToProps = (state, ownProps) => {
return {
    canvas : state.canvas,
    designState : state.designState,
    designType : state.designType,
    mobile : state.mobile,
}
}

const mapDispatchToProps = (dispatch, ownProps) => {
return {
    configViewport: (payload) => {
        dispatch(configViewport(payload))
    },
    addPhoneCase : (payload) => {
      dispatch(addObject(payload))
    },
}
}

export default connect(mapStateToProps, mapDispatchToProps)(DesignPhone);
