import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setCurrentUser } from "./redux/user/user.actions";
import { selectCurrentUser } from "./redux/user/user.selectors";
import { selectRedirectRoute } from "./redux/routing/routing.selectors";

import ScrollToTop from "./components/scrollToTop/ScrollToTop";

import Register from "./pages/sign-up/signUp";
import Login from "./pages/sign-in/signIn";
import PasswordReset from "./pages/passwordReset/passwordReset";
import FormPage from "./pages/contact-form/contact-form";
import Shop from "./pages/shop/Shop";
import Nav from "./components/nav/Nav";

import SplashPage from "./pages/splash/splash";
import Dashboard from "./pages/dashboard/Dashboard";
import DesignPhone from "./pages/design-phone/DesignPhone";
import DesignCard from "./pages/design-card/DesignCard";
import Enterprise from "./pages/enterprise/enterprise";
import Sales from "./pages/sales/sales";
import SalesAdmin from "./pages/sales-admin/sales-admin";
import Admin from "./pages/admin/admin";
import ContactUs from "./pages/contact-us/ContactUs";
import DeviceCompatibility from "./pages/device-compatibility/DeviceCompatibility";
import Fulfillment from "./pages/fulfillment/Fulfillment";
import LinkRedirect from "./components/LinkRedirect/";
import RangeWaterOrder from "./pages/rangewater-order/rangewaterOrder";
import GalleryResidential from "./pages/gallery-residential-order/galleryResidential";
import RangeWaterMultiOrder from "./pages/rangewater-multiOrder/rangewaterMultiOrder";
import ColumbiaResidentialOrder from "./pages/columbia-residential-order/columbiaResidential";


import {
  auth,
  createUserProfileDocument
} from "./utils/firebase/firebase.utils";

import "./App.css";

const Landing = lazy(() => import("./pages/landing/Landing"));

class App extends React.Component {
  unsubscribeFromAuth = null;

  componentDidMount() {
    const { setCurrentUser } = this.props;

    this.unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth);

        userRef.onSnapshot((snapShot) => {
          setCurrentUser({
            id: snapShot.id,
            ...snapShot.data()
          });
        });
      }
      setCurrentUser(userAuth);
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }

  render() {
    // console.log(this.props.currentUser.role)
    return (
      <Router>
        <ScrollToTop />
        <Route component={Nav} />

        <Switch className="App">
          <Route
            exact
            path="/"
            render={() => {
              // If currentUser is exactly equal to false, render Splash page
              // currentUser is false until Firebase determines auth state
              if (this.props.currentUser === false) {
                return <SplashPage />;
              }

              // Now, if Firebase has determined no user is logged in, currentUser is null
              return this.props.currentUser ? (
                <Redirect to={this.props.redirectRoute} />
              ) : (
                <Suspense fallback={<SplashPage />}>
                  <Landing />
                </Suspense>
              );
            }}
          />

          <Route
            exact
            path="/device-compatibility"
            render={() => {
              // If currentUser is exactly equal to false, render Splash page
              // currentUser is false until Firebase determines auth state
              if (this.props.currentUser === false) {
                return <SplashPage />;
              }

              // Now, if Firebase has determined no user is logged in, currentUser is null
              return this.props.currentUser ? (
                <Redirect to={this.props.redirectRoute} />
              ) : (
                <Suspense fallback={<SplashPage />}>
                  <DeviceCompatibility />
                </Suspense>
              );
            }}
          />

          <Route
            exact
            path="/contact-us"
            render={() => {
              // If currentUser is exactly equal to false, render Splash page
              // currentUser is false until Firebase determines auth state
              if (this.props.currentUser === false) {
                return <SplashPage />;
              }

              // Now, if Firebase has determined no user is logged in, currentUser is null
              return this.props.currentUser ? (
                <Redirect to={this.props.redirectRoute} />
              ) : (
                <Suspense fallback={<SplashPage />}>
                  <ContactUs />
                </Suspense>
              );
            }}
          />

          <Route
            exact
            path="/register"
            render={() =>
              this.props.currentUser ? (
                <Redirect to={this.props.redirectRoute} />
              ) : (
                <Register />
              )
            }
          />
          <Route
            exact
            path="/login"
            render={() =>
              this.props.currentUser ? (
                <Redirect to={this.props.redirectRoute} />
              ) : (
                <Login />
              )
            }
          />

          <Route
            exact
            path="/password-reset"
            render={() =>
              this.props.currentUser ? (
                <Redirect to={this.props.redirectRoute} />
              ) : (
                <PasswordReset />
              )
            }
          />

          <Route
            exact
            path="/dashboard"
            render={() =>
              this.props.currentUser ? <Dashboard /> : <Redirect to="/" />
            }
          />

          <Route
            exact
            path="/contact-info"
            render={() =>
              this.props.currentUser ? <FormPage /> : <Redirect to="/" />
            }
          />

          <Route
            exact
            path="/fulfillment"
            render={() =>
              this.props.currentUser.role === "fulfillment" ? (
                <Fulfillment />
              ) : (
                <Redirect to="/" />
              )
            }
          />

          <Route
            exact
            path="/rangewater-order"
            render={() =>
              this.props.currentUser.email === "rangewater@gmail.com" ? (
                <RangeWaterOrder />
              ) : (
                <Redirect to="/" />
              )
            }
          />

          <Route
            exact
            path="/gallery-residential"
            render={() =>
              this.props.currentUser.email === "marmenta@galleryresidential.com" ? (
                <GalleryResidential />
              ) : (
                <Redirect to="/" />
              )
            }
          />

          <Route
            exact
            path="/columbia-residential-order"
            render={() =>
              this.props.currentUser.email === "ksarvis@columbiares.com" ? (
                <ColumbiaResidentialOrder />
              ) : (
                <Redirect to="/" />
              )
            }
          />

          <Route
            exact
            path="/enterprise"
            render={() =>
              this.props.currentUser.isEnterprise ? (
                <Enterprise />
              ) : (
                <Redirect to="/" />
              )
            }
          />

          <Route path="/rd/:shortCode">
            <LinkRedirect />
          </Route>

          <Route exact path="/shop" component={Shop} />
          <Route exact path="/shop/design/phone" component={DesignPhone} />
          <Route path="/shop/design/card" component={DesignCard} />
          {/* <Route exact path="/enterprise" component={Enterprise} /> */}
          <Route exact path="/RangeWaterMultiOrder" component={RangeWaterMultiOrder} />
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/salesadmin" component={SalesAdmin} />
          <Route exact path="/sales" component={Sales} />
        </Switch>
      </Router>
    );
  }
}

// currentUser is false before Firebase fetches data
// currentUser is null if Firebase determines no user is logged in
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  redirectRoute: selectRedirectRoute
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user))
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
