import React from "react";
import ReactDOM from "react-dom";

import "./splash.styles.scss";

const Splash = () => {
  return ReactDOM.createPortal(
    <div className="splash-page">
      <div className="splash-page__content">
        <h1>MyTap</h1>
        <h2>Loading...</h2>
      </div>
    </div>,
    document.getElementById("splash")
  );
};

export default Splash;
