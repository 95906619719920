import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import firebase from "firebase/app";
// import firebase from "../../utils/firebase/firebase.utils";

import { createStructuredSelector } from "reselect";

import { setCurrentUser } from "../../redux/user/user.actions";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { nanoid } from "nanoid";


import "./enterprise.css";

import {
  MDBIcon,
  MDBInput,
  MDBContainer,
  MDBCardHeader,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCol,
  MDBCardImage,
  MDBCardBody,
  MDBBtn,
  MDBRow
} from "mdbreact";

import UserCard from "../../components/user-card/userCard";
import { getDefaultNormalizer } from "@testing-library/react";

const FieldValue = firebase.firestore.FieldValue;

const config = {
  apiKey: "AIzaSyBXoxTmjpnseSqHEXzX3ZEq2FqHgTgPHXM",
  authDomain: "mytap-2d6fa.firebaseapp.com",
  databaseURL: "https://mytap-2d6fa.firebaseio.com",
  projectId: "mytap-2d6fa",
  storageBucket: "mytap-2d6fa.appspot.com",
  messagingSenderId: "774375704684",
  appId: "1:774375704684:web:72d66de58b375c70f13049",
  measurementId: "G-Z4HYEYM77K"
};
const firebaseUserConfig = firebase.initializeApp(config, "Secondary");

const Enterprise = ({ currentUser }) => {
  //Company Name top for current user, if doesn't exist prompt admin to add company name to personal form.
  const [email, setEmail] = useState(`${nanoid(6)}@gmail.com`);
  const [password, setPassword] = useState("123456");
  const [password2, setPassword2] = useState("123456");
  const [displayName, setDisplayName] = useState("");
  const [createdUsers, setCreatedUsers] = useState(null);
  const [newGroup, setNewGroup] = useState("");
  const [userGroups, setUserGroups] = useState(currentUser.groups);
  const [search, setSearch] = useState("");

  const [newUserModal, setNewUserModal] = useState(false);
  const [newGroupModal, setNewGroupModal] = useState(false);

  useEffect(() => {
    firebase
      .firestore()
      .collection("users")
      .where("creatorId", "==", currentUser.id)
      .onSnapshot((snapshot) => {
        setCreatedUsers(
          snapshot.docs.map((user) => {
            const data = user.data();
            data.id = user.id;
            return data;
          })
        );
      });
  }, [currentUser]);

  // useEffect(() => {
  //   firebase
  //     .firestore()
  //     .collection("users")
  //     .doc(currentUser.id)
  //     .update({ groups: userGroups });
  // }, [userGroups]);

  const onCreateUser = async (e) => {
    e.preventDefault();
    const nonEmptyFields = { email, password, displayName };
    for (let field of Object.keys(nonEmptyFields)) {
      if (!nonEmptyFields[field] || nonEmptyFields[field].trim().length === 0) {
        alert(`${field} should not be empty`);
        return;
      }
    }

    if (password !== password2) {
      alert("passwords dont match");
      return;
    }

    try {
      const credentials = await firebaseUserConfig
        .auth()
        .createUserWithEmailAndPassword(email, password);
      firebaseUserConfig.auth().signOut();

      const user = await firebase
        .firestore()
        .collection("users")
        .doc(credentials.user.uid)
        .set({
          email,
          creatorId: currentUser.id,
          displayName: displayName,
          uid: credentials.user.uid
        });

      // Add the new user id to a list of createdUserIds under currentUsera
      await firebase
        .firestore()
        .collection("users")
        .doc(currentUser.id)
        .update({
          createdUsers: firebase.firestore.FieldValue.arrayUnion(
            credentials.user.uid
          )
        });

      setEmail(`${nanoid(6)}@gmail.com`);
      setPassword("123456");
      setPassword2("123456");
      setDisplayName("");
      setNewUserModal(false);
    } catch (e) {
      alert(e.message);
    }

    // 4. Look into transactions to make steps 2 and 3 atomic
  };

  const filterChange = (e) => {
    setSearch(e.target.value);
  };

  const toggleNewUserModal = () => {
    setNewUserModal(!newUserModal);
    console.log("trying to");
  };

  const toggleNewGroupModal = () => {
    setNewGroupModal(!newGroupModal);
  };

  const onCreateGroup = (e) => {
    e.preventDefault();
    if (currentUser.group && userGroups.includes(newGroup.toUpperCase().trim())) {
      alert("Group already exists!");
    } else {
      // setUserGroups((userGroups) => [...userGroups, newGroup.toUpperCase().trim()]);

      firebase
        .firestore()
        .collection("users")
        .doc(currentUser.id)
        .update({
          groups: firebase.firestore.FieldValue.arrayUnion(
            newGroup.toUpperCase().trim()
          )
        });
    }

    // addGroupToAdmin()

    setNewGroup("");
    setNewGroupModal(false);
    // debugger
  };
  const addGroupToAdmin = () => {
    console.log("updating group to db");
    // firebase.firestore().collection('users').doc(currentUser.id).update({ groups : userGroups})
    // updates admin doc add group key update array of groups
  };
  const assignNewGroup = (event) => {
    console.log("assigning new group", event.target);
  };

  const getAssignedGroupMembers = (group) => {
    // console.log("returning members", group);
    const assignedGroupMembers = createdUsers?.filter(
      (user) => user.group === group
    );
    return (
      <div style={{ display: "grid" }}>
        {assignedGroupMembers &&
          assignedGroupMembers.map((user) => {
            return (
              <UserCard
                key={user.id}
                value={user.id}
                user={user}
                groups={currentUser.groups || []}
              />
            );
          })}
      </div>
    );
  };
  const deleteGroupFromDb = (group) => {
    console.log("removing group", group);

    firebase
      .firestore()
      .collection("users")
      .where("group", "==", group)
      .onSnapshot((snapshot) => {
        snapshot.docs.map((user) => {
          console.log("User is ", user.data());
          firebase
            .firestore()
            .collection("users")
            .doc(user.data().uid)
            .update({ group: firebase.firestore.FieldValue.delete() });
        });
      });

    firebase
      .firestore()
      .collection("users")
      .doc(`${currentUser.id}`)
      .update({
        groups: firebase.firestore.FieldValue.arrayRemove(group)
      });
  };
  const removeGroup = (group) => {
    let confirmRemoveGroup = window.confirm(
      "Are you sure you want to delete this group?"
    );
    confirmRemoveGroup === true
      ? deleteGroupFromDb(group)
      : console.log("user canceled");
  };

  const addGroupToUser = () => {
    // updates user doc add group key and assign string
  };
  //Fetch all current users where formData company = admin formData company, display in table, develop UI to allow admin to assign.
  //add button so admin can add user
  return (
    <MDBContainer fluid className="p-3 bg-light border">
      <MDBCardBody className="mb-3 border-bottom">
        <MDBBtn color="unique-color-dark" onClick={toggleNewGroupModal}>
          <MDBIcon icon="users" />&nbsp; Add New Property
        </MDBBtn>
        <MDBModal isOpen={newGroupModal} toggle={toggleNewGroupModal}>
          <MDBModalHeader toggle={toggleNewGroupModal}>
            Add New Property
          </MDBModalHeader>
          <MDBModalBody>
            <form id="addGroup" onSubmit={onCreateGroup}>
              <div className="grey-text">
                <MDBInput
                  type="text"
                  label="Enter Property Name"
                  name="newGroup"
                  onChange={(e) => setNewGroup(e.target.value)}
                  value={newGroup}
                />
              </div>
            </form>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              className="unique-color-dark white-text"
              color="unique-color-dark"
              onClick={toggleNewGroupModal}
            >
              Close
            </MDBBtn>
            <MDBBtn
              className="unique-color-dark white-text"
              color="unique-color-dark"
              type="submit"
              onClick={(e) => onCreateGroup(e)}
            >
              ADD Property
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBBtn color="unique-color-dark" onClick={toggleNewUserModal}>
          <MDBIcon icon="user-plus" /> &nbsp;Add New Card Type
        </MDBBtn>

        <MDBModal isOpen={newUserModal} toggle={toggleNewUserModal}>
          <MDBModalHeader toggle={toggleNewUserModal}>
            Add New Card Type
          </MDBModalHeader>
          <MDBModalBody>
            <form id="addTeamMember" onSubmit={onCreateUser}>
              <div className="grey-text">
                {/* <MDBInput
                  type="text"
                  label="Enter user email address"
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <MDBInput
                      type="text"
                      label="Enter user password"
                      name="password"
                      onChange={e => setPassword(e.target.value)}
                      value={password}
                    />
                    <MDBInput
                      type="text"
                      label="Confirm user password"
                      name="password2"
                      onChange={e => setPassword2(e.target.value)}
                      value={password2}
                    /> */}
                <MDBInput
                  type="text"
                  label="Enter card type"
                  name="displayName"
                  onChange={(e) => setDisplayName(e.target.value)}
                  value={displayName}
                />
              </div>
            </form>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              className="unique-color-dark white-text"
              color="unique-color-dark"
              onClick={toggleNewUserModal}
            >
              Close
            </MDBBtn>
            <MDBBtn
              className="unique-color-dark white-text"
              color="unique-color-dark"
              type="submit"
              onClick={(e) => onCreateUser(e)}
            >
              ADD CARD Type
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBCardBody>

      <MDBCol md="2">
        <MDBInput
          onChange={filterChange}
          hint="Search"
          type="text"
          containerClass="mt-0"
        />
      </MDBCol>

      <MDBCardBody style={{ justifyContent: "center", display: "flex" }}>
        <MDBRow className="d-block">
          {currentUser.groups &&
            currentUser.groups
              .sort()
              .map((group) => {
                return (
                  <MDBCard
                    style={{ maxWidth: "22rem", minWidth: "22rem" }}
                    key={group}
                    className="m-2 group-container float-left"
                  >
                    <MDBCol>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}
                      >
                        <MDBCardTitle>{group}</MDBCardTitle>
                        <MDBIcon
                          className={"edit-menu-close"}
                          value={group}
                          onClick={() => {
                            removeGroup(group);
                          }}
                          icon="times"
                        />
                      </div>
                      {getAssignedGroupMembers(group)}
                    </MDBCol>
                  </MDBCard>
                );
              })
              .filter((group) =>
                group.key.toLowerCase().includes(search.toLowerCase())
              )}
        </MDBRow>
      </MDBCardBody>

      <MDBCardBody className="mt-5 border-top">
        <MDBCardTitle>Unassigned Users</MDBCardTitle>

        {!createdUsers && <p>Fetching...</p>}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignContent: "space-around",
            justifyContent: "space-evenly",
            maxWidth: "22rem",
            minWidth: "22rem"
          }}
        >
          {createdUsers &&
            createdUsers.map((user) => {
              if (user.group) {
                return null;
              }
              return (
                <UserCard
                  key={user.id}
                  value={user.id}
                  user={user}
                  groups={currentUser.groups || []}
                />
              );
            })}
        </div>
      </MDBCardBody>
    </MDBContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => {
    return dispatch(setCurrentUser(user));
  }
});

const NoUserAdminNoRender = function ({ currentUser }) {
  if (currentUser && currentUser.isEnterprise) {
    return <Enterprise currentUser={currentUser} />;
  }
  return null;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoUserAdminNoRender);

// export default Enterprise;
