import React, { Component } from "react";
import { connect } from "react-redux";
import { MDBRow, MDBContainer, MDBIcon, MDBCard } from "mdbreact";
import { Link } from "react-router-dom";

import "./dashboard.scss";

class Dashboard extends Component {
  render() {
    return (
      <MDBContainer className="dashboard">
        <MDBRow className="dashboard__links">
          <MDBCard className="dashboard__link-card">
            <Link to="/contact-info" className="dashboard__link">
              <MDBIcon icon="address-card" size="3x" />
              <h2>Add your info</h2>
            </Link>
          </MDBCard>
          <MDBCard className="dashboard__link-card">
            <Link to="/shop" className="dashboard__link">
              <MDBIcon icon="store" size="3x" />
              <h2>Enter our shop</h2>
            </Link>
          </MDBCard>
        </MDBRow>
      </MDBContainer>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser.displayName
});

export default connect(mapStateToProps)(Dashboard);
