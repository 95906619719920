import React from "react";
import { connect } from "react-redux";

import { MDBContainer } from "mdbreact";

import "../design-phone/DesignPhone.css";

// import ToolBar from "../components/ToolBar.js"
import ContentPanel from "../../components/content-panel/ContentPanel";
import DrawingArea from "../../components/drawing-area/DrawingArea";
import { configViewport } from "../../redux/drawing-area/drawing-area.actions";
import { addObject } from "../../redux/object/object.actions";

class IDCard extends React.Component {
  state = {
    width: 1486,
    height: 1109,
    top: 0
  };

  componentDidMount() {
    const root = document.getElementById("root");
    root.classList.remove("padding-for-sidenav");
  }

  componentWillUnmount() {
    const root = document.getElementById("root");
    root.classList.add("padding-for-sidenav");
  }

  UNSAFE_componentWillMount(){
    if(!this.props.designState) this.props.history.push('/');

    let sideBarWidth = this.props.mobile ? 0 : 60+250;
    let margin = this.props.mobile ? 2 : 100;        
    let dW = 1486, dH=1109;
    let w = window.innerWidth - sideBarWidth, h = window.innerHeight-64;
    let ratio = Math.min((w-margin)/dW, (h-margin)/dH);
    let top  = this.props.mobile ? 35 : (h- ratio*dH)/2;
    
    // let own = this;
    // window.onresize = ()=>{
    //     own.setState({
    //         width : ratio*dW,
    //         height : ratio*dH,
    //         top : top,
    //     });
    // }        

    this.setState({
        width : ratio*dW,
        height : ratio*dH,
        top : top,
    });

    this.props.addPhoneCase({
        id : Math.floor(Math.random()*10000)+'_'+ new Date().getTime(),
        kind : "Phone",
        type : 'image',
        url : this.props.designType.path,
        // url : './assets/images/IDCard/idcard better.png',
    });
}

render (){
    return (
        <MDBContainer fluid className='p-0'>
            {/* <ToolBar /> */}
            <MDBContainer fluid className='p-0'>
                <ContentPanel drawingH={this.state.height}/>
                <DrawingArea width={this.state.width} height={this.state.height} top={this.state.top}/>
            </MDBContainer>                
        </MDBContainer>
    )
}
}

const mapStateToProps = (state, ownProps) => {
return {
    canvas : state.canvas,
    designState : state.designState,
    designType : state.designType,
    mobile : state.mobile,
}
}

const mapDispatchToProps = (dispatch, ownProps) => {
return {
    configViewport: (payload) => {
        dispatch(configViewport(payload))
    },
    addPhoneCase : (payload) => {
      dispatch(addObject(payload))
    },
}
}

export default connect(mapStateToProps, mapDispatchToProps)(IDCard);
