export const setRedirect = (route) => {
  return {
    type: "SET_REDIRECT",
    payload: route
  };
};

export const defaultRedirect = () => {
  return {
    type: "DEFAULT_REDIRECT"
  };
};
