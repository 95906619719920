import React from "react";
import ReactDOM from "react-dom";
import store from "../../redux/store";

import ReactQr from "react-awesome-qr";

import firebase from "../../utils/firebase/firebase.utils";

import MyLogo from "../../assets/logo5.png";

export default {
  generateQR: function () {
    var db = firebase.firestore();
    var storageRef = firebase.storage().ref();
    var database = firebase.database();

    const MyCardDirectURL = store.getState().user.currentUser.MyCardDirectURL;
    const MyTapReviewCardURL =
      store.getState().user.currentUser.MyTapReviewCardURL;
    const userId = store.getState().user.currentUser.id;
    const shortUrl = store.getState().user.currentUser.shortUrl;
    // const isPremium = store.getState().user.currentUser.premium;


    // console.log('eowedwedcewsecdwswcsdcswdcs',userId)

    ReactDOM.render(
      <ReactQr
        text={
          MyTapReviewCardURL && MyTapReviewCardURL.length > 4 ?
          MyTapReviewCardURL:''
        }
        correctLevel={3}
        size={700}
        dotScale={0.7}
        // MyTapReviewCardURL.length > 4 ? null :
        bgSrc={
          MyTapReviewCardURL && MyTapReviewCardURL.length > 4 ? null : MyLogo
        }
        callback={
          (data) =>
            // console.log(data)

            db
              .collection("users")
              .doc(`${userId}`)
              .update({
                GoogleQrImg: `${data}`
              })
            

          // storageRef
          //   .child(`${this.props.userId}, QRCODE.png`)
          //   .putString(data, "data_url")
          //   .then(function (snapshot) {
          //     // alert("QR SENT");
          //   })
        }
      />,
      document.getElementById("yo")
    );
  }
};
