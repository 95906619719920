import React from "react";
import { List} from "react-movable";

import { connect, useSelector } from "react-redux";
import { MDBMedia } from "mdbreact";

import {
  updateSequence,
  releasedObject,
  selectedObject
} from "../../../redux/object/object.actions";

function Layer({
  objects,
  activedObject,
  lastAction,
  sceneManager,
  canvas,
  updateSequence,
  releasedObject,
  selectedObject,
}) {
  const { items } = useSelector((state) => ({
    items: state.objects.slice(1, state.objects.length).map((el) => {
      let img = sceneManager.getImgDataObject(el.id);
      el.img = img;
      return el;
    }),
  }));

  const changed = (oldIndex, newIndex) => {
    updateSequence({
      sourceIdx: oldIndex + 1,
      targetIdx: newIndex + 1,
    });
    sceneManager.updateSequence({
      sourceIdx: oldIndex,
      targetIdx: newIndex,
    });
  };

  return (
    <List
      lockVertically
      values={items}
      onChange={({ oldIndex, newIndex }) => changed(oldIndex, newIndex)}
      renderList={({ children, props, isDragged }) => (
        <ul
          {...props}
          style={{
            padding: 0,
            cursor: isDragged ? "grabbing" : undefined,
            overflowY: "scroll",
            overflowX: "hidden",
            height: "calc( 100vh - 170px )",
          }}
        >
          {children}
        </ul>
      )}
      renderItem={({ value, props, isDragged, isSelected }) => (
        <li
          {...props}
          style={{
            ...props.style,
            zIndex: 100,
            width: "260px",
            padding: "10px",
            margin: "10px 10px",
            listStyleType: "none",
            cursor: isDragged ? "grabbing" : "grab",
            border: "2px solid #CCC",
            boxShadow: "3px 3px #AAA",
            color: "#333",
            borderRadius: "5px",
            fontFamily: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
            backgroundColor: isDragged || isSelected ? "#FFF" : "#dee2e6",
          }}
        >
          {/* {value.kind+" : "} */}
          <MDBMedia
            object
            src={value.img}
            style={{
              "max-height": "30px",
              "max-width": "100%",
              borderRadius: 0,
              boxShadow: "none",
            }}
            alt=""
          />
        </li>
      )}
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    objects: state.objects,
    lastAction: state.lastAction,
    activedObject: state.activedObject,
    sceneManager: state.sceneManager,
    canvas: state.canvas,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateSequence: (payload) => {
      dispatch(updateSequence(payload));
    },
    releasedObject: (payload) => {
      dispatch(releasedObject(payload));
    },
    selectedObject: (payload) => {
      dispatch(selectedObject(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layer);
