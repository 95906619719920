import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import FontAwesome from "react-fontawesome";
import fonts from "./Font";
import { Button, Modal, Form } from "react-bootstrap";
import { SketchPicker } from "react-color";

// import { MDBBtn, MDBIcon } from 'mdbreact';

import { updateObject, deleteObject } from "../../redux/object/object.actions";
import FontPicker from "font-picker-react";

import firebase from "../../utils/firebase/firebase.utils";

// import imageCompression from 'browser-image-compression';


const PropertyPanel = ({
  height,
  options,
  designType,
  phoneCase,
  lastAction,
  sceneManager,
  updateObject,
  deleteObject,
  mobile,
  userId,
  googleUserId
}) => {
  const [activeFontFamily, setActiveFontFamily] = useState("Open Sans");
  const [showModalText, setShowModalText] = useState(false);

  const [text, setText] = useState(options.text);

  const [showColorModal, setShowColorModal] = useState(false);
  const [color, setColor] = useState(options.fill);

  const [showOutlineColorModal, setShowOutlineColorModal] = useState(false);
  const [outlineColor, setOutlineColor] = useState(options.stroke);

  const [fontSize, setFontSize] = useState(options.fontSize);

  const [textAlign, setTextAlign] = useState(options.textAlign);

  const [fontWeight, setFontWeight] = useState(options.fontWeight);
  const [fontStyle, setFontStyle] = useState(options.fontStyle);
  const [underline, setUnderline] = useState(options.underline);

  const [strokeWidth, setStrokeWidth] = useState(options.strokeWidth);

  const showPanel = () => {
    return lastAction === "OBJECT/SELECTED" || lastAction === "OBJECT/UPDATE";
  };

  const showTextModal = (pShow) => {
    if (pShow) setText(options.text);
    setShowModalText(pShow);
  };
  const updateText = (e) => {
    setShowModalText(false);
    updateObject({ id: options.id, text: text });
  };
  const handleChange = (event) => {
    setText(event.target.value);
  };

  const updateOutlineColor = (event) => {
    setOutlineColor(event.hex);
    updateObject({ id: options.id, stroke: event.hex });
    // setShowOutlineColorModal(false);
  };

  const updateColor = (event) => {
    setColor(event.hex);
    updateObject({ id: options.id, fill: event.hex });
    // setShowColorModal(false);
  };

  const handleChangeFontSize = (value) => {
    setFontSize(value);
    updateObject({ id: options.id, fontSize: value });
  };

  const changeTextAlign = (pAlign = "center") => {
    setTextAlign(pAlign);
    updateObject({ id: options.id, textAlign: pAlign });
  };

  const changeTextStyle = (pStyle) => {
    if ("fontWeight" in pStyle) {
      setFontWeight(pStyle.fontWeight);
    } else if ("fontStyle" in pStyle) {
      setFontStyle(pStyle.fontStyle);
    } else if ("underline" in pStyle) {
      setUnderline(pStyle.underline);
    }
    updateObject({
      ...pStyle,
      id: options.id
    });
  };

  const changeThickness = (value) => {
    setStrokeWidth(value);
    updateObject({ id: options.id, strokeWidth: Number(value) });
  };

  const deleteItem = () => {
    deleteObject({ id: options.id });
    sceneManager.deleteObject(options.id);
  };

  let history = useHistory();

  async function saveDesign() {
    // let imgbase64 =  sceneManager.getSceneBase64Image();
    console.log("save");
  let imgbase64 = await sceneManager.getCropImage();

  
    var database = firebase.database();
    database.ref(`designs/ + ${userId}`).update({
      [designType]: imgbase64
    })

    history.push("/shop");
  }


  function changeValue(pKind, pPM) {
    switch (pKind) {
      case "fontSize":
        let font = fontSize + (pPM === "plus" ? 1 : -1);
        if (font < 1) font = 1;
        handleChangeFontSize(font);
        break;
      case "strokeWidth":
        let stroke =
          Math.round((strokeWidth + (pPM === "plus" ? 0.1 : -0.1)) * 100) / 100;
        if (stroke < 0.1) stroke = 0;
        changeThickness(stroke);
        break;
      default:
    }
  }

  const panelContent = () => {
    let objKind = options.kind;
    let content = null;

    switch (objKind) {
      case "Phone":
        break;
      case "Image":
        content = <>{deleteButton()}</>;
        break;
      case "Text":
        content = (
          <>
            {mobile && deleteButton()}
            <button
              className="propertyItem"
              onClick={(e) => showTextModal(true)}
            >
              <div className="propIcon ">
                <FontAwesome name="pencil-alt" />
              </div>
              <div className="propLabel2">Edit Text</div>
            </button>
            <div className="propertyItem">
              <div className="propIcon">
                <FontAwesome name="font" />
              </div>
              <div className="propLabel">Font</div>
              <div className="">
                <FontPicker
                  apiKey="AIzaSyCmmFH9UfEESFOdHycuCCz466-xmb6FNOc"
                  activeFontFamily={activeFontFamily}
                  families={fonts}
                  limit="1209"
                  onChange={(nextFont) => {
                    setActiveFontFamily(nextFont.family);
                    setTimeout(function () {
                      updateObject({
                        id: options.id,
                        fontFamily: nextFont.family
                      });
                    }, 1000);
                  }}
                  style={{ width: "150px", height: "50px" }}
                />
              </div>
            </div>
            <div className="propertyItem">
              <div className="propIcon">
                <FontAwesome name="palette" />
              </div>
              <div className="propLabel">Text</div>
              <Button
                className="actionButton"
                variant="outline-secondary"
                onClick={() => setShowColorModal(true)}
                style={{ display: "flex" }}
              >
                <FontAwesome
                  name="circle"
                  style={{
                    color: color,
                    fontSize: 25
                  }}
                />
                &nbsp;&nbsp;
                <p className="mb-0" style={{ fontSize: ".81rem" }}>
                  {color}
                </p>
              </Button>
            </div>
            <div className="propertyItem">
              <div className="propIcon">
                <FontAwesome name="text-height" />
              </div>
              <div className="propLabel">Size</div>
              <div className="actionButton">
                <input
                  type="number"
                  min="0"
                  style={{ width: "100%", textAlign: "center" }}
                  value={fontSize}
                  onChange={(e) => handleChangeFontSize(e.target.value)}
                  onKeyDown={(e) => setFontSize(e.target.value)}
                />
              </div>
              {mobile && changeButtons("fontSize")}
            </div>
            <div className="propertyItem">
              <div className="propIcon">
                <FontAwesome name="palette" />
              </div>
              <div className="propLabel">Outline</div>
              <Button
                className="actionButton"
                variant="outline-secondary"
                onClick={() => setShowOutlineColorModal(true)}
                style={{ display: "flex" }}
              >
                <FontAwesome
                  name="circle"
                  style={{
                    color: outlineColor,
                    fontSize: 25
                  }}
                />
                &nbsp;&nbsp;
                <p className="mb-0" style={{ fontSize: ".81rem" }}>
                  {outlineColor}
                </p>
              </Button>
            </div>
            <div className="propertyItem">
              <div className="propIcon">
                <FontAwesome name="arrows-alt-h" />
              </div>
              <div className="propLabel">Outline Size</div>
              <div className="actionButton">
                <input
                  type="number"
                  min="0"
                  max="20"
                  step="0.1"
                  style={{ width: "100%", textAlign: "center" }}
                  value={strokeWidth}
                  onChange={(e) => changeThickness(e.target.value)}
                  onKeyDown={(e) => setStrokeWidth(e.target.value)}
                />
              </div>
              {mobile && changeButtons("strokeWidth")}
            </div>
            <div className="propertyItem">
              <div className="propIcon">
                <FontAwesome name="align-justify" />
              </div>
              <div className="propLabel1">Align</div>
              <div className="actionButton flex">
                <Button
                  variant="outline-secondary"
                  className={textAlign === "left" ? "active" : ""}
                  onClick={(e) => changeTextAlign("left")}
                >
                  <FontAwesome name="align-left"></FontAwesome>
                </Button>
                <Button
                  variant="outline-secondary"
                  className={textAlign === "center" ? "active" : ""}
                  onClick={(e) => changeTextAlign("center")}
                >
                  <FontAwesome name="align-center"></FontAwesome>
                </Button>
                <Button
                  variant="outline-secondary"
                  className={textAlign === "right" ? "active" : ""}
                  onClick={(e) => changeTextAlign("right")}
                >
                  <FontAwesome name="align-right"></FontAwesome>
                </Button>
              </div>
            </div>
            <div className="propertyItem">
              <div className="propIcon">
                <FontAwesome name="align-justify" />
              </div>
              <div className="propLabel1">Style</div>
              <div className="actionButton flex">
                <Button
                  variant="outline-secondary"
                  className={fontWeight === "bold" ? "active" : ""}
                  onClick={(e) => {
                    let updateProp = {
                      fontWeight: fontWeight === "bold" ? "" : "bold"
                    };
                    changeTextStyle(updateProp);
                  }}
                >
                  <FontAwesome name="bold"></FontAwesome>
                </Button>
                <Button
                  variant="outline-secondary"
                  className={fontStyle === "italic" ? "active" : ""}
                  onClick={(e) => {
                    let updateProp = {
                      fontStyle: fontStyle === "italic" ? "" : "italic"
                    };
                    changeTextStyle(updateProp);
                  }}
                >
                  <FontAwesome name="italic"></FontAwesome>
                </Button>
                <Button
                  variant="outline-secondary"
                  className={underline ? "active" : ""}
                  onClick={(e) => {
                    let updateProp = {
                      underline: underline ? false : true
                    };
                    changeTextStyle(updateProp);
                  }}
                >
                  <FontAwesome name="underline"></FontAwesome>
                </Button>
              </div>
            </div>
            {!mobile && deleteButton()}
            <Modal show={showModalText} onHide={(e) => showTextModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Text</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Control
                    as="textarea"
                    rows="10"
                    value={text}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={(e) => showTextModal(false)}
                >
                  Close
                </Button>
                <Button variant="primary" onClick={(e) => updateText(false)}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={showOutlineColorModal}
              onHide={() => setShowOutlineColorModal(false)}
              centered
            >
              <SketchPicker
                disableAlpha
                color={outlineColor}
                onChangeComplete={(e) => updateOutlineColor(e)}
                enabled
              />
            </Modal>

            <Modal
              show={showColorModal}
              onHide={() => setShowColorModal(false)}
              centered
            >
              <SketchPicker
                disableAlpha
                color={color}
                onChangeComplete={(e) => updateColor(e)}
                enabled
              />
            </Modal>
          </>
        );
        break;
      case "Shape":
        content = (
          <>
            {mobile && deleteButton()}
            <div className="propertyItem">
              <div className="propIcon">
                <FontAwesome name="palette" />
              </div>
              <div className="propLabel">fill</div>
              <Button
                className="actionButton"
                variant="outline-secondary"
                onClick={() => setShowColorModal(true)}
                style={{ display: "flex" }}
              >
                <FontAwesome
                  name="circle"
                  style={{
                    color: color,
                    fontSize: 25
                  }}
                />
                &nbsp;&nbsp;
                <p className="mb-0" style={{ fontSize: ".81rem" }}>
                  {color}
                </p>
              </Button>
            </div>
            <div className="propertyItem">
              <div className="propIcon">
                <FontAwesome name="palette" />
              </div>
              <div className="propLabel">stroke</div>
              <Button
                className="actionButton"
                variant="outline-secondary"
                onClick={() => setShowOutlineColorModal(true)}
                style={{ display: "flex" }}
              >
                <FontAwesome
                  name="circle"
                  style={{
                    color: outlineColor,
                    fontSize: 25
                  }}
                />
                &nbsp;&nbsp;
                <p className="mb-0" style={{ fontSize: ".81rem" }}>
                  {outlineColor}
                </p>
              </Button>
            </div>
            <div className="propertyItem">
              <div className="propIcon">
                <FontAwesome name="arrows-alt-h" />
              </div>
              <div className="propLabel">Outline Size</div>
              <div className="actionButton">
                <input
                  type="number"
                  min="0"
                  max="20"
                  step="0.1"
                  style={{ width: "100%", textAlign: "center" }}
                  value={strokeWidth}
                  onChange={(e) => changeThickness(e.target.value)}
                  onKeyDown={(e) => setStrokeWidth(e.target.value)}
                />
              </div>
              {mobile && changeButtons("strokeWidth")}
            </div>
            {!mobile && deleteButton()}
            <Modal
              show={showOutlineColorModal}
              onHide={() => setShowOutlineColorModal(false)}
              centered
            >
              <SketchPicker
                disableAlpha
                color={outlineColor}
                onChangeComplete={(e) => updateOutlineColor(e)}
                enabled
              />
            </Modal>

            <Modal
              show={showColorModal}
              onHide={() => setShowColorModal(false)}
              centered
            >
              <SketchPicker
                disableAlpha
                color={color}
                onChangeComplete={(e) => updateColor(e)}
                enabled
              />
            </Modal>
          </>
        );
        break;
      default:
    }
    return content;
  };

  const changeButtons = (pKind) => {
    return (
      <div>
        <FontAwesome
          className="btnSize"
          name="plus-square"
          onClick={(e) => changeValue(pKind, "plus")}
        ></FontAwesome>
        <FontAwesome
          className="btnSize"
          name="minus-square"
          onClick={(e) => changeValue(pKind, "minus")}
        ></FontAwesome>
      </div>
    );
  };

  const saveButton = () => {
    return (
      <button className="saveDesign  propertyItem" onClick={saveDesign}>
        <div className="propIcon">
          <FontAwesome name="save" />
        </div>
        <div> Save Design</div>
      </button>
    );
  };

  const deleteButton = () => {
    return (
      <button className="propertyItem" onClick={deleteItem}>
        <div className="propIcon">
          <FontAwesome name="trash" />
        </div>
        <div className="propLabel2">Delete</div>
      </button>
    );
  };

  return (
    <div
      style={{ height: height + "px" }}
      className={
        "propertyPanel " +
        (showPanel() ? "show" : "hide") +
        (mobile ? " mobile" : "")
      }
    >
      {panelContent()}
      {!mobile && saveButton()}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    options: { ...state.activeObject },
    designType: state.designType.type,
    phoneCase: state.designType.phoneCase,
    lastAction: state.lastAction,
    sceneManager: state.sceneManager,
    mobile: state.mobile,
    userId: state.user?.currentUser?.id,
    googleUserId: state.user?.currentUser?.uid
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteObject: (payload) => {
      dispatch(deleteObject(payload));
    },
    updateObject: (payload) => {
      dispatch(updateObject(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyPanel);
