import React, { useRef, useState, useEffect } from "react";
import EditEnterpriseUserPage from "../../pages/enterprise/editEnterpriseUserPage";
import firebase from "../../utils/firebase/firebase.utils";
import { adminDeleteUser } from "../../utils/firebase/firebase.utils";
import { BarChart as ChartIcon } from "@material-ui/icons";
import { Box, Typography } from "@material-ui/core";

import API from "./graph";

// import OutsideAlerter from "../outsideAlerter/outsideAlerter";

import "./userCard.css";

import {
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBContainer,
  MDBCard,
  MDBInput
} from "mdbreact";

function UserCard(props) {
  const [editModal, setEditModal] = useState(false);
  const [editModal2, setEditModal2] = useState(false);
  const [editMenu, setEditMenu] = useState(false);
  const [groupAssignmentMenu, setGroupAssignmentMenu] = useState(false);
  const [ID, setID] = useState(0);
  const [totalClicks, setClicks] = useState(0);
  const [clickDates, setClickDates] = useState([]);
  const [clickTimes, setClickTimes] = useState([]);
  const [linkDocId, setLinkDocId] = useState(0);
  const [search, setSearch] = useState("");

  // const handleClick = (e) => {
  //     e.preventDefault();
  //     console.log('The link was clicked.', e);
  // };

  useEffect(() => {
    getClicks();
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setTimeout(() => {
      setEditMenu(editMenu);
    }, 200);
  };
  const node = useRef();

  const filterChange = (e) => {
    setSearch(e.target.value);
  };

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };
  const toggleEditModal2 = () => {
    API.graph(props.user, clickDates, clickTimes);
    setTimeout(() => {
      setEditModal2(!editModal2);
    }, 450);
  };
  const toggleEditMenu = () => {
    setEditMenu(!editMenu);
  };

  const deleteUser = () => {
    // var user = firebase.auth().props?.user?.id;
    console.log("Deleted:", props.user.id);
    firebase
      .firestore()
      .collection("users")
      .doc(`${firebase.auth().currentUser.uid}`)
      .update({
        createdUsers: firebase.firestore.FieldValue.arrayRemove(props.user.id)
      });
    //delete all shortLink data
    if (props.user.shortUrl) {
      firebase
        .firestore()
        .collection("users")
        .doc(props.user.id)
        .collection("links")
        .doc(linkDocId)
        .delete();
    }

    //delete user data
    adminDeleteUser(props.user.id);

    // firebase.firestore().collection("users").doc(props.user.id).delete();
  };

  const removeUser = () => {
    let confirmDeleteUser = window.confirm(
      "Are you sure you want to delete this user?"
    );
    confirmDeleteUser === true ? deleteUser() : console.log("user canceled");
  };

  const order = () => {
    const docRef = firebase.firestore().collection("users").doc(props.user.id);
    setID(ID + 1);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          const docData = doc.data();

          firebase
            .firestore()
            .collection("TanyasOrders")
            .doc(`${props.user.id}-${ID}`)
            .set({ ...docData, dateOrdered: new Date(), id: ID });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });

    // var user = firebase.auth().props?.user?.id;
    console.log("order this:", props.user.id);
    // console.log("yo", docRef);

    firebase.firestore().collection("users").doc(props.user.id).update({
      fulfilled: false
    });
  };

  const getClicks = () => {
    firebase
      .firestore()
      .collection(`users/${props.user.id}/links`)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log(doc.id, " => ", doc.data());
          const clicks = doc.data().totalClicks;
          const clickDates = doc.data().clickDates;
          const linkDocId = doc.id;
          // console.log("this is the idddddd", linkDocId);
          setLinkDocId(linkDocId);
          setClicks(clicks);

          const dates = clickDates.map((date) =>
            new Date(date.seconds * 1000).setHours(0, 0, 0, 0)
          );
          const clickTimes = clickDates.map((date) =>
            new Date(date * 1000).toLocaleTimeString()
          );

          setClickDates(dates);
          setClickTimes(clickTimes);
        });
      });
  };

  const toggleGroupAssignmentMenu = () => {
    setGroupAssignmentMenu(!groupAssignmentMenu);

    setTimeout(() => {
      setEditMenu(editMenu);
    }, 205);
  };

  const addToGroup = (group) => {
    const removeGroupFromUser = () => {
      const fieldValue = firebase.firestore.FieldValue;
      firebase
        .firestore()
        .collection("users")
        .doc(props.user.id)
        .update({ group: firebase.firestore.FieldValue.delete() });
    };
    group === null
      ? removeGroupFromUser()
      : firebase
          .firestore()
          .collection("users")
          .doc(props.user.id)
          .update({ group: group });
  };

  return (
    <MDBContainer className="user-box">
      <div className="user-box-content">
        <Box paddingTop={0}>
          <Box display="flex" justifyContent="center">
            <Typography className="click-counter">{totalClicks}</Typography>
            <ChartIcon onClick={toggleEditModal2} className="graph-click-box" />
          </Box>
          <Typography className="click-counter-title">Total Clicks</Typography>
        </Box>
        <div className="user-info">
          <div className="display-namee">
            {props.user.displayName
              ? props.user.displayName
              : `${props.user.formData?.firstName}`}
          </div>
          {/* <div className="user-email">{props.user.email}</div> */}
          {/* <div className="user-group">
            {props.user.group ? props.user.group : ""}
          </div> */}
        </div>
      </div>

      <div>
        <a ref={node} onClick={toggleEditMenu}>
          <MDBIcon className={"edit-menu"} icon="ellipsis-v" />
        </a>

        <MDBModalBody className="menu-box-container">
          <div className={`menu-box${editMenu ? "" : " hidden"}`}>
            <div onClick={toggleEditModal} className="user-menu-item">
              {props.user.orderPlaced ? "View" : "Order"}
            </div>
            <div onClick={removeUser} className="user-menu-item">
              Delete
            </div>

            <MDBInput
              onMouseDown={toggleGroupAssignmentMenu}
              onChange={filterChange}
              hint="Add to Group..."
              type="text"
              containerClass="mt-0"
            />
            <div
              className={`"group-menu-item" ${
                !editMenu ? "hidden" : !groupAssignmentMenu ? "hidden" : ""
              } `}
            >
              <ul>
                {props.groups
                  .map((group) => (
                    <li
                      className="group-menu-item"
                      onClick={() => {
                        addToGroup(group);
                      }}
                      key={group}
                    >
                      <a>{group}</a>
                    </li>
                  ))
                  .filter((group) =>
                    group.key.toLowerCase().includes(search.toLowerCase())
                  )}
              </ul>
              <div
                className="group-menu-item"
                style={{
                  borderTop: "1px black solid"
                }}
                key={null}
                onClick={() => {
                  addToGroup(null);
                }}
              >
                <strong>Unassign</strong>
              </div>
            </div>
          </div>
        </MDBModalBody>
      </div>
      <MDBModal size="lg" isOpen={editModal} toggle={toggleEditModal}>
        <MDBModalBody>
          <EditEnterpriseUserPage
            explicitUser={props.user}
            afterSubmit={toggleEditModal}
          />
        </MDBModalBody>
      </MDBModal>

      <MDBModal size="lg" isOpen={editModal2} toggle={toggleEditModal2}>
        <MDBModalBody style={{ height: "60vh", padding: "0" }}>
          <iframe
            name="iframe1"
            title="frame"
            style={{
              border: "none",
              width: "100%",
              height: "100%"
            }}
            src={`https://firebasestorage.googleapis.com/v0/b/mytap-2d6fa.appspot.com/o/${props.user.id}-graph.html?alt=media&token=c3efec8d-69cc-45b6-9d96-0d0bee85f58b`}
          ></iframe>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
}

export default UserCard;
