import React from "react";
import { connect } from "react-redux";
import firebase from "../../utils/firebase/firebase.utils";
import StyledDemo from "./index";
import ReactQr from "react-awesome-qr";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon
} from "mdbreact";

import cardBack from "./assets/card-back.png";

import { fetchDesigns } from "../../redux/designImages/designImages.actions";

class CustomQr extends React.Component {
  state = {
    modal: false
  };

  toggle = () => {
    var db = firebase.firestore();
    this.props.fetchDesigns();

    this.setState({
      modal: !this.state.modal
    });
    db.collection("users").doc(`${this.props.userId}`).update({
      customLogo: firebase.firestore.FieldValue.delete()
    });
    // .then(()=>{
    //     db.collection("users").doc(`${this.props.userId}`).update({
    //         customQrImgPreview: firebase.firestore.FieldValue.delete()
    //       })
    // })
  };

  generateQr() {
    var db = firebase.firestore();
    var database = firebase.database();

    const thing = (
      <div style={{ display: "none" }}>
        <ReactQr
          text="https://www.mytap.net/rd/6YXp2o"
          size={200}
          dotScale={0.7}
          margin={0}
          bgSrc={this.props.customLogo}
          callback={(data) =>
            // localStorage.setItem('customQrImg',data)
            db
              .collection("users")
              .doc(`${this.props.userId}`)
              .update({
                customQrImgPreview: `${data}`
              })
          }
        />

        <ReactQr
          text={
            this.props.MyCardDirectURL && this.props.MyCardDirectURL.length > 4
              ? this.props.MyCardDirectURL
              : this.props.shortUrl
          }
          correctLevel={3}
          size={700}
          dotScale={0.7}
          margin={0}
          bgSrc={this.props.customLogo}
          callback={
            (data) =>
              database.ref(`designs/ + ${this.props.userId}`).update({
                customQrImg: `${data}`
              })

            // db
            //   .collection("users")
            //   .doc(`${this.props.userId}`)
            //   .update({
            //     customQrImg: `${data}`
            //   })
          }
        />
      </div>
    );

    return thing;
  }

  render() {
    return (
      <MDBContainer>
        <MDBBtn
          // className="btn unique-color-dark white-text"
          // color="unique-color-dark"
          color="transparent"
          className="p-1 m-0 z-depth-0"
          onClick={this.toggle}
        >
          <MDBIcon className="shake" fas size='lg' icon="qrcode" />
        </MDBBtn>

        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalBody>
            <StyledDemo />

            {this.props.customLogo ? this.generateQr() : null}

            {this.props.customLogo ? (
              <div>
                <img
                  style={{ width: "100%", position: "relative" }}
                  alt="card"
                  src={cardBack}
                />
                <img
                  style={{
                    width: "24%",
                    position: "absolute",
                    top: "41%",
                    left: "39.5%"
                  }}
                  alt="Qr"
                  src={this.props.customQrImgPreview}
                />
              </div>
            ) : null}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              className="btn unique-color-dark white-text"
              color="unique-color-dark"
              onClick={this.toggle}
            >
              Close
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  currentUser: user?.currentUser?.displayName,
  userId: user?.currentUser?.uid,
  customQrImg: user?.currentUser?.customQrImg,
  customQrImgPreview: user?.currentUser?.customQrImgPreview,
  customLogo: user?.currentUser?.customLogo,
  directLinksUrl: user?.currentUser?.directLinksUrl,
  shortUrl: user?.currentUser?.shortUrl,
  MyCardDirectURL: user?.currentUser?.MyCardDirectURL
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchDesigns: (payload) => {
      dispatch(fetchDesigns(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomQr);
