export const setCurrentUser = user => ({
    type: 'SET_CURRENT_USER',
    payload: user
})

export const updateUser = formData => ({
    type: 'UPDATE_USER',
    payload: formData
})

export const saveUserCard = formData => ({
    type: 'SAVE_USER_CARD',
    payload: formData
})