import React from "react";
import { connect } from "react-redux";

import {
  selectedObject,
  releasedObject,
  updateObject
} from "../../redux/object/object.actions";
import { designOn } from "../../redux/observe";

class EntityObject extends React.Component {
  state = {
    attribute: null
  };

  UNSAFE_componentWillMount() {
    designOn("anyChange", this.updateObject.bind(this));
  }

  componentDidMount() {
    // if(!this.state.attribute && (this.props.lastAction==='OBJECT/ADD' || this.props.lastAction==='OBJECT/SET' ) ){
    if (!this.state.attribute) {
      this.props.scene.addObject(this.props.attr);
      this.setState({ attribute: this.props.attr });
    }
  }
  UNSAFE_componentWillReceiveProps(newProp) {
    this.props.scene.addObject(newProp.attr);
    this.setState({ attribute: newProp.attr });
  }

  updateObject(action, option) {
    if (option.id === this.props.attr.id && this.state.attribute) {
      if (
        action === "OBJECT/UPDATE" ||
        (action === "OBJECT/DELETE" && option.kind !== "Phone")
      ) {
        this.props.scene.updateObject(option);
      }
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    lastAction: state.lastAction,
    scene: state.sceneManager
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    activeObject: (payload) => {
      dispatch(selectedObject(payload));
    },
    releasedObject: (payload) => {
      dispatch(releasedObject(payload));
    },
    updateObject: (payload) => {
      dispatch(updateObject(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EntityObject);
