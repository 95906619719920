import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "bootstrap/dist/css/bootstrap.min.css";
import store from "./redux/store";
import { Provider } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const STRIPE_PUBLISHABLE_KEY = 'pk_live_51HXSqEAeW9tgiIdvHKYVpjVZgEItnNV7F4hqnlT5N5FooLT4yPksbuhgDTnq89nXMGyUQYomJ1DiqVXeDZRiQEtj00UvQu2QXV';

// const STRIPE_PUBLISHABLE_KEY = "pk_test_51HXSqEAeW9tgiIdvMxn5b7RBuaFv1aHjTy10mVjgxV1H91EtGZfbDKbc4q7u1OEeCddlrkS5XV7AxPkG0JK4fwzi00B4ub7Fz6";
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

ReactDOM.render(
  <Provider store={store}>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
