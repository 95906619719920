import firebase from "../../utils/firebase/firebase.utils";

export const fetchOrders = () => async (dispatch) => {
 await firebase.database().ref('orders').once('value', snapshot => {
  const orders = snapshot.val()
  const parsedOrders = Object.values(orders)

   dispatch({
    type: "FETCH_ORDERS",
    payload: parsedOrders
  });
});
};


// export const fetchOrders = () => async (dispatch) => {
//   var query = await firebase.database().ref("orders").orderByKey()
//   query.once("value")
//   .then(function (snapshot) {
//     snapshot.forEach(function (childSnapshot) {

//       var orders = childSnapshot.val();

//       // var childData = childSnapshot.val();
//       dispatch({
//         type: "FETCH_ORDERS",
//         payload: orders
//       });
//       return true;
//     });
//   });

// };

// import firebase from "../../utils/firebase/firebase.utils";

// export const fetchOrders = () => async (dispatch) => {
//   const snapshot = await firebase.firestore().collection("orders").get();

//   dispatch({
//     type: "FETCH_ORDERS",
//     payload: snapshot.docs.map((doc) => doc.data())
//   });
// };
