import { createSelector } from "reselect";

const selectUser = (state) => state.user;

// Return false if current user status is unknown (i.e. Firebase hasn't checked yet)
// Return null if no current user (and Firebase has checked)
// Else, return current user
export const selectCurrentUser = createSelector([selectUser], (user) => {
  if (user.hasOwnProperty("currentUser")) {
    return user.currentUser;
  }

  return false;
});
