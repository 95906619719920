import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
// import { MDBMedia, MDBBtn } from "mdbreact";
import {
  MDBMedia,
  MDBCard,
  MDBContainer,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBTooltip,
  MDBCardFooter,
  MDBBtn,
  MDBLink,
  MDBIcon
} from "mdbreact";

import { Carousel } from "react-bootstrap";

import firebase from "../../utils/firebase/firebase.utils";
import GoogleReview from "../google-review/googleReview"
import CustomQr from "../../components/customQr/customQr";
import PhoneCase from "../../components/design-tools/phonecase";
import {
  toggleDesignState,
  changeDesignType
} from "../../redux/drawing-area/drawing-area.actions";
import { setObject, addObject } from "../../redux/object/object.actions";
import { defaultRedirect } from "../../redux/routing/routing.actions";
import { fetchDesigns } from "../../redux/designImages/designImages.actions";

import API2 from "../../components/generateQr/generateQR";

import { CircularProgress, Box } from "@material-ui/core";

// Stripe
import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js";

//

import "./Shop.styles.scss";
import {
  createSession,
  createStripeCustomer
} from "../../utils/firebase/firebase.utils";
import { updateUser } from "../../redux/user/user.actions";

import Splash from "../splash/splash";

import basicCard from "./assets/images/IDCard/mytapcard.png";
import cardBack from "./assets/images/IDCard/card-back.png";
import customCard from "./assets/images/IDCard/customcard.png";
import customCardYours from "./assets/images/IDCard/customcardyours.png";



// import RangewaterCardTemplate from "../../components/renderDirectCardImage/imgs/RW3.png";

const RouterButton = ({ path, label, click, disabled, productType }) => {
  let history = useHistory();
  function handleClick() {
    click();
    history.push(path);
  }
  return (
    <button
      type="button"
      onClick={handleClick}
      className={`design-link design-link--${productType} ${
        disabled ? "design-link--disabled" : ""
      }`}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

class ShopPage extends React.Component {
  state = {
    caseURL: "/assets/images/PhoneCase/iPhone 6 Plus/PNG File/sample (1).png",
    caseName: "iPhone 6 Plus",
    cardURL: "/assets/images/IDCard/idcard better.png",
    selectedPhoneCase: "",
    addedPhone: false,
    MyCardClassic: false,
    MyCard: false,
    MyCase: false,
    bandColor: "",
    MyCardDirect: false,
    MyCardDirectURL: "",
    loading: false,
    classicSelectedIndex: "",
    customSelectedIndex: ""
  };

  componentDidMount() {
    this.props.defaultRedirect();
    this.props.fetchDesigns();

    setTimeout(() => {
      if (this.props.MyCardDirectURL) {
        this.setState({ MyCardDirectURL: this.props.MyCardDirectURL });
      } else {
        this.setState({ MyCardDirectURL: "" });
      }
    }, 2000);
  }

  // Redirect to fulfillment page when applicable
  componentDidUpdate() {
    if (
      this.props.currentUser &&
      this.props.currentUser?.role === "fulfillment"
    ) {
      this.props.history.push("/fulfillment");
    }
    if (this.props.currentUser?.email === "rangewater@gmail.com") {
      this.props.history.push("/rangewater-order");
    }
    if (this.props.currentUser.email === "marmenta@galleryresidential.com") {
      this.props.history.push("/gallery-residential");
    }
    if (this.props.currentUser?.email === "ksarvis@columbiares.com") {
      this.props.history.push("/columbia-residential-order");
    }
    if (this.props.currentUser?.isEnterprise) {
      this.props.history.push("/enterprise");
    }
    // this.props.fetchDesigns();
    if (this.state.MyCardDirect === false) {
      this.resetDirectUrlInput();
    }
  }

  open = () => {
    let objects = JSON.parse(window.localStorage.getItem("phoneState"));

    this.props.openScene(objects);
    let oneself = this;
    setTimeout(function () {
      oneself.props.sceneManager.releaseObject();
      oneself.props.toggleDesignState(true);
    }, 1);
  };

  setDesignState(pType) {
    this.props.initScene([]);
    let designType = {
      type: pType,
      path:
        pType === "card"
          ? this.state.cardURL
          : pType === "phone"
          ? this.state.caseURL
          : "",
      name: pType === "card" ? "" : this.state.caseName,
      phoneCase:
        pType === "card"
          ? null
          : pType === "phone"
          ? this.state.selectedPhoneCase
          : ""
    };
    this.props.changeDesignType(designType);
    this.props.toggleDesignState(true);
  }

  changeCase(pUrl, pName, selectedPhoneCase) {
    this.setState({
      caseURL: pUrl,
      caseName: pName
    });
    this.setState({ addedPhone: true });
    this.setState({ selectedPhoneCase });
  }

  customQrCheckbox = (event) => {
    // console.log(event.target.name, event.target.checked);
    const { name, checked } = event.target;
    this.setState({ [name]: checked });
  };

  onDirectURLChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  bandColorChangeHandler(event) {
    // alert(event.target.value)
    this.setState({ bandColor: event.target.value });
  }

  directURLToDB(e) {
    var db = firebase.firestore();
    db.collection("users")
      .doc(`${this.props.userId}`)
      .update({
        MyCardDirectURL: this.state.MyCardDirectURL.toString()
      })
      .then(() => {
        API2.generateQR();
      });
  }

  resetDirectUrlInput() {
    var db = firebase.firestore();
    db.collection("users").doc(`${this.props.userId}`).update({
      MyCardDirectURL: ""
    });
  }

  renderCustomQrCardImg() {
    var canvas = document.getElementById("canvas2");
    if (!canvas || !canvas.getContext) {
      return false;
    }
    canvas.width = 2.5 * 445;
    canvas.height = 2.5 * 280;
    var ctx = canvas.getContext("2d");
    var img1 = new Image();
    var img2 = new Image();
    img1.src = cardBack;
    img2.src = this.props.customQrImgPreview;
    img1.onload = function () {
      ctx.drawImage(img1, 0, 0, 2.5 * 445, 2.5 * 280);
    };
    img2.onload = function () {
      ctx.drawImage(img2, 2.5 * 151, 2.5 * 65, 2.5 * 147, 2.5 * 147);
    };
    setTimeout(() => {
      const img = canvas.toDataURL("image/png");
      localStorage.setItem("customQrCardImg", img);
    }, 500);
  }

  handleSelect = (selectedIndex) => {
    this.setState({
      classicSelectedIndex: selectedIndex
    });
  };
  handleSelect2 = (selectedIndex) => {
    this.setState({
      customSelectedIndex: selectedIndex
    });
  };

  render() {
    const { currentUser } = this.props.user;

    if (!currentUser) {
      return <Splash />;
    }

    if (this.state.loading)
      return (
        <Box mt={10} textAlign="center">
          <CircularProgress />
        </Box>
      );

    return (
      <MDBContainer fluid className="mt-1 pt-3">
        <div className="shop-page__header">
          <h1 className="shop-page__header--main">Shop</h1>
          <p className="shop-page__header--sub">
            Fully-customizable, with
            more exciting products on the way! Create yours today.
          </p>
        </div>

        <div className="shop-page__items">
          <div className="shop-item shop-item--card">
            <Carousel
              activeIndex={this.index}
              onSelect={this.handleSelect}
              controls={true}
              interval={null}
              variant="dark"
            >
              <Carousel.Item className="p-4">
              <div className="shop-item__image shop-item__image--card">
                <MDBMedia
                  object
                  className="shop-item__image shop-item__image--cardIMG"
                  src={customCard}
                  alt=""
                />
                </div>
                {/* <Carousel.Caption>
                  <h6>Frontside</h6>
                </Carousel.Caption> */}
              </Carousel.Item>

              <Carousel.Item className="p-4">
                {!this.state.MyCardClassic
                  ? null
                  : this.renderCustomQrCardImg()}
                {!this.state.MyCardClassic ? (
                  <MDBMedia
                    object
                    className="shop-item__image shop-item__image--card--back"
                    src={cardBack}
                    alt=""
                  />
                ) : (
                  <MDBMedia
                    object
                    className="shop-item__image shop-item__image--card--back"
                    src={localStorage.getItem("customQrCardImg")}
                    alt=""
                  />
                )}
                {/* <Carousel.Caption>
                  <h6>Backside</h6>
                </Carousel.Caption> */}
              </Carousel.Item>
            </Carousel>
            <MDBCardBody cascade className="text-center">
              <MDBCardTitle>
                <strong>{this.state.classicSelectedIndex === 1? 
                <span>Pre-Designed Card<i>-Back</i></span>
                : <span>Pre-Designed Card<i>-Front</i></span>}</strong>
              </MDBCardTitle>
              <MDBCardText>
                {this.state.classicSelectedIndex === 1 ? (
                  <div>
                    <input
                      onChange={this.customQrCheckbox}
                      name="MyCardClassic"
                      type="checkbox"
                      className="checkbox"
                      checked={this.state.MyCardClassic}
                    ></input>
                    <label>Custom QR Code (+$5)</label>
                  </div>
                ) : null}
              </MDBCardText>
              <MDBCardFooter>
                <span className="float-left">
                  {this.state.MyCardClassic === true ? (
                    <div className="shop-item__price">$25</div>
                  ) : (
                    <div className="shop-item__price">$20</div>
                  )}
                </span>
                <span className="float-right">
                  {this.state.MyCardClassic === true ? (
                    <MDBTooltip placement="top">
                      <MDBBtn color="transparent" className="p-1 m-0 z-depth-0">
                        <CustomQr/>
                      </MDBBtn>
                      <div>Create your custom QR-Code!</div>
                    </MDBTooltip>
                  ) : null}

                  <MDBTooltip placement="top">
                    <MDBBtn color="transparent" className="p-1 m-0 z-depth-0">
                      <ElementsConsumer>
                        {({ stripe, elements }) => (
                          <form
                            onSubmit={async (e) => {
                              this.setState({ loading: true });
                              e.preventDefault();
                              if (!currentUser.stripeCustomerId) {
                                return await createStripeCustomer(
                                  currentUser
                                ).then(async () => {
                                  const session = await createSession(
                                    `${
                                      this.state.MyCardClassic === true
                                        ? "MyCard Classic + QR"
                                        : "MyCard Classic"
                                    }`,
                                    currentUser.uid
                                  );
                                  stripe.redirectToCheckout({
                                    sessionId: session.data.id
                                  });
                                  console.log("session", session);
                                });
                              } else {
                                const session = await createSession(
                                  `${
                                    this.state.MyCardClassic === true
                                      ? "MyCard Classic + QR"
                                      : "MyCard Classic"
                                  }`,
                                  currentUser.uid
                                );
                                stripe.redirectToCheckout({
                                  sessionId: session.data.id
                                });
                                console.log("session", session);
                              }
                            }}
                          >
                            <MDBBtn
                              color="transparent"
                              className="p-1 m-0 z-depth-0"
                              type="submit"
                            >
                              <MDBIcon fas size='lg' icon="shopping-cart" />
                            </MDBBtn>
                          </form>
                        )}
                      </ElementsConsumer>
                    </MDBBtn>
                    <div>Buy!</div>
                  </MDBTooltip>
                </span>
              </MDBCardFooter>
            </MDBCardBody>
          </div>

          <div className="shop-item shop-item--card">
            <Carousel
              activeIndex={this.index}
              onSelect={this.handleSelect2}
              controls={true}
              interval={null}
              variant="dark"
            >
              <Carousel.Item className="p-4">
                <div className="shop-item__image shop-item__image--card">
                  <MDBMedia
                    object
                    className="shop-item__image shop-item__image--cardIMG"
                    src={
                      this.props.card || customCardYours
                    }
                    alt=""
                  />
                </div>
              
              </Carousel.Item>

              <Carousel.Item className="p-4">
                {!this.state.MyCard ? null : this.renderCustomQrCardImg()}
                {!this.state.MyCard ? (
                  <MDBMedia
                    object
                    className="shop-item__image shop-item__image--card--back"
                    src={cardBack}
                    alt=""
                  />
                ) : (
                  <MDBMedia
                    object
                    className="shop-item__image shop-item__image--card--back"
                    src={localStorage.getItem("customQrCardImg")}
                    alt=""
                  />
                )}
            
              </Carousel.Item>
            </Carousel>

            <MDBCardBody cascade className="text-center">
              <MDBCardTitle>
                <strong>{this.state.customSelectedIndex === 1? 
                <span>Customizable Card<i>-Back</i></span>
                : <span>Customizable Card<i>-Front</i></span>}</strong>
              </MDBCardTitle>
              <MDBCardText>
                {this.state.customSelectedIndex === 1 ? (
                  <div>
                    <input
                      onChange={this.customQrCheckbox}
                      name="MyCard"
                      type="checkbox"
                      className="checkbox"
                      checked={this.state.MyCard}
                    ></input>
                    <label>Custom QR Code (+$5)</label>
                  </div>
                ) : null}
              </MDBCardText>
              <MDBCardFooter>
                <span className="float-left">
                  {this.state.MyCard === true &&
                  this.state.MyCardDirect === true ? (
                    <div className="shop-item__price">$40</div>
                  ) : this.state.MyCardDirect === true ? (
                    <div className="shop-item__price">$30</div>
                  ) : this.state.MyCard === true ? (
                    <div className="shop-item__price">$45</div>
                  ) : (
                    <div className="shop-item__price">$40</div>
                  )}
                </span>
                <span className="float-right">
                  {this.state.customSelectedIndex != 1 ? (
                    <MDBTooltip placement="top">
                      <MDBBtn color="transparent" className="p-1 m-0 z-depth-0">
                        {/* <RouterButton
                          path="/shop/design/card"
                          click={(e) => {
                            this.setDesignState("card");
                          }}
                          productType="card"
                        /> */}
                        <MDBLink
                          className="shake"
                          to="/shop/design/card"
                          onClick={() => this.setDesignState("card")}
                          productType="card"
                          link
                        >
                          <MDBIcon fas size='lg' icon="crop" />
                        </MDBLink>
                      </MDBBtn>
                      <div>DESIGN YOUR CARD!</div>
                    </MDBTooltip>
                  ) : null}

                  {this.state.MyCard === true ? (
                    <MDBTooltip placement="top">
                      <MDBBtn color="transparent" className="p-1 m-0 z-depth-0">
                        <CustomQr />
                      </MDBBtn>
                      <div>Create your custom QR-Code!</div>
                    </MDBTooltip>
                  ) : null}

                  <MDBTooltip placement="top">
                    <MDBBtn color="transparent" className="p-1 m-0 z-depth-0">
                      <ElementsConsumer>
                        {({ stripe, elements }) => (
                          <form
                            onSubmit={async (e) => {
                              // this.directURLToDB();
                              this.setState({ loading: true });
                              e.preventDefault();
                              if (!currentUser.stripeCustomerId) {
                                return await createStripeCustomer(
                                  currentUser
                                ).then(async () => {
                                  const session = await createSession(
                                    `${
                                      // this.state.MyCard === true ? "card + QR" : "card"
                                      this.state.MyCard === true &&
                                      this.state.MyCardDirect === true
                                        ? "MyCard Direct + QR"
                                        : this.state.MyCardDirect === true
                                        ? "MyCard Direct"
                                        : this.state.MyCard === true
                                        ? "card + QR"
                                        : "card"
                                    }`,
                                    currentUser.uid
                                  );
                                  stripe.redirectToCheckout({
                                    sessionId: session.data.id
                                  });
                                  console.log("session", session);
                                });
                              } else {
                                const session = await createSession(
                                  `${
                                    this.state.MyCard === true &&
                                    this.state.MyCardDirect === true
                                      ? "MyCard Direct + QR"
                                      : this.state.MyCardDirect === true
                                      ? "MyCard Direct"
                                      : this.state.MyCard === true
                                      ? "card + QR"
                                      : "card"
                                  }`,
                                  currentUser.uid
                                );
                                stripe.redirectToCheckout({
                                  sessionId: session.data.id
                                });
                                console.log("session", session);
                              }
                            }}
                          >
                            <MDBBtn
                              disabled={!this.props.card}
                              color="transparent"
                              className="p-1 m-0 z-depth-0"
                              type="submit"
                            >
                              <MDBIcon fas size='lg' icon="shopping-cart" />
                            </MDBBtn>
                          </form>
                        )}
                      </ElementsConsumer>
                    </MDBBtn>
                    <div>Buy!</div>
                  </MDBTooltip>
                </span>
              </MDBCardFooter>
            </MDBCardBody>
          </div>

          {/* <div className="shop-item shop-item--phonecase">
            <h3 className="shop-item__header">MyCase Custom</h3>
            <MDBMedia
              object
              className="shop-item__image shop-item__image--phonecase"
              src={
                this.props.phone ||
                "/assets/images/PhoneCase/iPhone 6 Plus/PNG File/sample (1).png"
              }
              alt=""
            />

            <div className="shop-item__edit">
              <div style={{ width: "85%" }}>
                <PhoneCase changeCase={this.changeCase.bind(this)} />
              </div>
              <br />
              <RouterButton
                path="/shop/design/phone"
                label="DESIGN YOUR PHONE CASE"
                click={(e) => {
                  this.setDesignState("phone");
                }}
                disabled={!this.state.addedPhone}
                productType="phonecase"
              />
            </div>
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <form
                  className="shop-item__buy-btn"
                  onSubmit={async (e) => {
                    e.preventDefault();

                    if (!currentUser.stripeCustomerId) {
                      return await createStripeCustomer(currentUser).then(
                        async () => {
                          const session = await createSession(
                            `${
                              this.state.MyCase === true
                                ? "phoneCase + QR"
                                : "phoneCase"
                            }`
                          );
                          stripe.redirectToCheckout({
                            sessionId: session.data.id
                          });
                          console.log("session", session);
                        }
                      );
                    } else {
                      const session = await createSession(
                        `${
                          this.state.MyCase === true
                            ? "phoneCase + QR"
                            : "phoneCase"
                        }`
                      );
                      stripe.redirectToCheckout({
                        sessionId: session.data.id
                      });
                      console.log("session", session);
                    }
                  }}
                >

                  <MDBBtn
                    disabled={!this.props.phone}
                    type="submit"
                    className="shop-item__buy-btn unique-color-dark text-white"
                    color="unique-color-dark"
                  >
                    Buy
                  </MDBBtn>
                </form>
              )}
            </ElementsConsumer>

            {this.state.MyCase === true ? (
              <div className="shop-item__price">$70</div>
            ) : (
              <div className="shop-item__price">$60</div>
            )}

            <div className="checkbox">
              <input
                onChange={this.customQrCheckbox}
                name="MyCase"
                type="checkbox"
                className="checkbox"
              ></input>
              <label>Custom QR Code (+$10)</label>
            </div>

            {this.state.MyCase === true ? <CustomQr /> : null}
          </div> */}

          <div className="shop-item shop-item--card">
            <MDBMedia
              object
              className="shop-item__image shop-item__image--phonecase"
              src={
                this.state.bandColor === "white"
                  ? "/assets/images/MyBandWhite.png"
                  : this.state.bandColor === "black"
                  ? "/assets/images/MyBandBlack.png"
                  : "/assets/images/Mybands.png"
              }
              alt=""
            />

            <MDBCardBody cascade className="text-center">
              <MDBCardTitle>
                <strong>MyBand</strong>
              </MDBCardTitle>
              <MDBCardText>
                <div className="shop-item__edit">
                  <div style={{ width: "85%" }}>
                    <select
                      value={this.state.bandColor}
                      className="browser-default custom-select"
                      onChange={this.bandColorChangeHandler.bind(this)}
                    >
                      <option value={""}>Select your band color...</option>

                      <option value={"black"}>Black</option>

                      <option value={"white"}>White</option>
                    </select>
                  </div>
                </div>
              </MDBCardText>
              <MDBCardFooter>
                <span className="float-left">
                  <div className="shop-item__price">$10</div>
                </span>
                <span className="float-right">
                  <MDBTooltip placement="top">
                    <MDBBtn color="transparent" className="p-1 m-0 z-depth-0">
                      <ElementsConsumer>
                        {({ stripe, elements }) => (
                          <form
                            onSubmit={async (e) => {
                              this.setState({ loading: true });
                              e.preventDefault();

                              if (!currentUser.stripeCustomerId) {
                                return await createStripeCustomer(
                                  currentUser
                                ).then(async () => {
                                  const session = await createSession(
                                    `${
                                      this.state.bandColor === "white"
                                        ? "MyBand - White"
                                        : "MyBand - Black"
                                    }`,
                                    currentUser.uid
                                  );
                                  stripe.redirectToCheckout({
                                    sessionId: session.data.id
                                  });
                                  console.log("session", session);
                                });
                              } else {
                                const session = await createSession(
                                  `${
                                    this.state.bandColor === "white"
                                      ? "MyBand - White"
                                      : "MyBand - Black"
                                  }`,
                                  currentUser.uid
                                );
                                stripe.redirectToCheckout({
                                  sessionId: session.data.id
                                });
                                console.log("session", session);
                              }
                            }}
                          >
                            <MDBBtn
                              disabled={this.state.bandColor === ""}
                              color="transparent"
                              className="p-1 m-0 z-depth-0"
                              type="submit"
                            >
                              <MDBIcon fas size='lg' icon="shopping-cart" />
                            </MDBBtn>
                          </form>
                        )}
                      </ElementsConsumer>
                    </MDBBtn>
                    <div>Buy!</div>
                  </MDBTooltip>
                </span>
              </MDBCardFooter>
            </MDBCardBody>
          </div>

          <div 
          className="shop-item shop-item--card"
          >
        
             <GoogleReview/>
        
          </div>


        </div>
      </MDBContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log('dddddd',state.user)
  return {
    user: state.user,
    email: state.email,
    objects: state.objects,
    sceneManager: state.sceneManager,
    // phone: state.user.currentUser?.phone,
    // card: state.user.currentUser?.card,
    currentUser: state.user?.currentUser,
    phone: state?.designImages[0]?.phone,
    card: state?.designImages[0]?.card,
    userId: state.user.currentUser?.id,
    MyCardDirectURL: state.user.currentUser?.MyCardDirectURL,
    reviewCardUploadedLogo: state.user.currentUser?.reviewCardUploadedLogo,
    formData: state.user.currentUser?.formData,
    QrImg: state.user.currentUser?.QrImg,
    customQrImgPreview: state.user.currentUser?.customQrImgPreview
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    openScene: (payload) => {
      dispatch(setObject(payload));
    },
    toggleDesignState: (payload) => {
      dispatch(toggleDesignState(payload));
    },
    initScene: (payload) => {
      dispatch(setObject(payload));
    },
    addObject: (payload) => {
      dispatch(addObject(payload));
    },
    changeDesignType: (payload) => {
      dispatch(changeDesignType(payload));
    },
    updateUser: (payload) => {
      dispatch(updateUser(payload));
    },
    defaultRedirect: () => {
      dispatch(defaultRedirect());
    },
    fetchDesigns: (payload) => {
      dispatch(fetchDesigns(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPage);
