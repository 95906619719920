import React from "react";
import { connect } from "react-redux";
import { MDBMedia, MDBBtn, MDBIcon, MDBCardFooter, MDBTooltip } from "mdbreact";

import { nanoid } from "nanoid";
import firebase from "../../utils/firebase/firebase.utils";

import { defaultRedirect } from "../../redux/routing/routing.actions";
import { fetchDesigns } from "../../redux/designImages/designImages.actions";

import API2 from "../../components/generateQr/generateGoogleQr";
import API3 from "../../components/renderDirectCardImage/renderDirectCardImage";

import UploadLogo from "../../components/upload-your-logo/uploadLogo";
import { CircularProgress, Box } from "@material-ui/core";

// Stripe
import { ElementsConsumer } from "@stripe/react-stripe-js";

//

import {
  createSession,
  createStripeCustomer
} from "../../utils/firebase/firebase.utils";
import { updateUser } from "../../redux/user/user.actions";

import Splash from "../splash/splash";

import LogoFront from "./assets/images/IDCard/GalleryMyTapCardLogo.png";
import RW2 from "./assets/images/IDCard/RW2.png";

class GalleryResidential extends React.Component {
  state = {
    MyTapReviewCardURL: "",
    loading: false
  };

  componentDidMount() {
    this.props.defaultRedirect();
    this.props.fetchDesigns();
  }

  // Redirect to fulfillment page when applicable
  componentDidUpdate() {
    if (
      this.props.currentUser &&
      this.props.currentUser.role === "fulfillment"
    ) {
      this.props.history.push("/fulfillment");
    }
    // this.props.fetchDesigns();
  }

  onDirectURLChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  createShortLink() {
    var db = firebase.firestore();
    const link = {
      name: "relink",
      longURL:
        this.state.MyTapReviewCardURL.includes("http://") ||
        this.state.MyTapReviewCardURL.includes("https://")
          ? this.state.MyTapReviewCardURL
          : `http://${this.state.MyTapReviewCardURL}`,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      shortCode: nanoid(6),
      totalClicks: 0,
      clickDates:[]
    };

    db.collection("users")
      .doc(`${this.props.userId}`)
      .collection("links")
      .add(link)
      .then(() => {
        db.collection("users")
          .doc(`${this.props.userId}`)
          .update({
            shortUrl: `https://www.mytap.net/rd/${link.shortCode}`
          });
      });
  }

  saveMyTapReviewCardURLToDB(e) {
    var db = firebase.firestore();
    db.collection("users").doc(`${this.props.userId}`).update({
      MyTapReviewCardURL: this.state.MyTapReviewCardURL.toString()
    })
  }

  generateAndBuy() {
    API2.generateQR();
    // await this.createShortLink();
    setTimeout(() => {
      API3.renderDirectCardImage();
    }, 250);
  }

  render() {
    const { currentUser } = this.props.user;

    if (!currentUser) {
      return <Splash />;
    }

    if (this.state.loading)
      return (
        <Box mt={10} textAlign="center">
          <CircularProgress />
        </Box>
      );

    return (
      <div className="mt-1 pt-3 shop-page" style={{ marginBottom: "200px" }}>
        <div className="shop-page__items">
          <div className="shop-item shop-item--card">
            <MDBMedia
              object
              className="shop-item__image shop-item__image--card"
              src={LogoFront}
              alt=""
              style={{
                aspectRatio: "1.68 / 1",
              }}
            />

            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img
                className="shop-item__image shop-item__image--card--classic"
                style={{ width: "100%" }}
                alt="card"
                src={RW2}
              />
              {this.props.reviewCardUploadedLogo ? (
                <img
                  style={{
                    position: "absolute",
                    top: "22%",
                    width: "31%",
                    right: "16%",
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "right"
                  }}
                  alt="Qr"
                  src={this.props.reviewCardUploadedLogo}
                />
              ) : null}
            </div>

          <MDBCardFooter>
          <UploadLogo />
            <div>
                    <label>URL: &nbsp;</label>
                    <input
                      style={{ width: "70%" }}
                      onChange={this.onDirectURLChange.bind(this)}
                      onBlur={this.saveMyTapReviewCardURLToDB.bind(this)}
                      type="text"
                      name="MyTapReviewCardURL"
                      value={this.state.MyTapReviewCardURL}
                    ></input>
                  </div>

                <span className="float-left">
                  <div className="shop-item__price"> $35 </div>
                </span>
                <span className="float-right">
            <MDBTooltip placement="top">
              <MDBBtn color="transparent" className="p-1 m-0 z-depth-0">

            <ElementsConsumer>
              {({ stripe, elements }) => (
                <form
                  className="shop-item__buy-btn"
                  onSubmit={async (e) => {
                    this.setState({ loading: true });
                    e.preventDefault();
                    this.generateAndBuy();
                    if (!currentUser.stripeCustomerId) {
                      return await createStripeCustomer(currentUser).then(
                        async () => {
                          const session = await createSession(
                            "MyTap Review Card",
                            currentUser.uid
                          );
                          stripe.redirectToCheckout({
                            sessionId: session.data.id
                          });
                          console.log("session", session);
                        }
                      );
                    } else {
                      const session = await createSession(
                        "MyTap Review Card",
                        currentUser.uid
                      );
                      stripe.redirectToCheckout({
                        sessionId: session.data.id
                      });
                      console.log("session", session);
                    }
                  }}
                >
     
            <MDBBtn
              disabled={
                this.state.MyTapReviewCardURL.length < 4 ||
                !this.props.reviewCardUploadedLogo
              }
              type="submit"
              className="p-1 m-0 z-depth-0"
              color="transparent"
            >
                <MDBIcon fas size="lg" icon="shopping-cart" />
            </MDBBtn>
                </form>
              )}
            </ElementsConsumer>
          </MDBBtn>
          <div>Order!</div>
        </MDBTooltip>
      </span>
    </MDBCardFooter>

            
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log('dddddd',)
  return {
    user: state.user,
    email: state.email,
    currentUser: state.user?.currentUser,
    userId: state.user.currentUser?.id,
    MyCardDirectURL: state.user.currentUser?.MyCardDirectURL,
    reviewCardUploadedLogo: state.user.currentUser?.reviewCardUploadedLogo,
    formData: state.user.currentUser?.formData,
    QrImg: state.user.currentUser?.QrImg
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateUser: (payload) => {
      dispatch(updateUser(payload));
    },
    defaultRedirect: () => {
      dispatch(defaultRedirect());
    },
    fetchDesigns: (payload) => {
      dispatch(fetchDesigns(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GalleryResidential);
