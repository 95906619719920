// const selectedUserReducer = (state = [], action) => {
//   switch (action.type) {
//     case "SET_SELECTED_USER":
//       return [...action.payload];
//     default:
//       return state;
//   }
// };

// export default selectedUserReducer;


const INITIAL_STATE = {};

const selectedUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_SELECTED_USER":
      return {
        ...state,
        currentUser: action.payload
      };
    default:
      return state;
  }
};

export default selectedUserReducer;