import firebase from "../../utils/firebase/firebase.utils";
// import store from "../../redux/store";

// setTimeout(() => {
//   var userId = store.getState().user.currentUser.uid;

//   console.log("sdsdsd", userId);
// }, 3000);

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    localStorage.setItem("uid", user.uid);
  } else {
    // No user is signed in.
  }
});

export const fetchDesigns = () => async (dispatch) => {
  await firebase
    .database()
    .ref(`designs/ + ${localStorage.getItem("uid")}`)
    .once("value", (snapshot) => {
      const designs = snapshot.val();
      const parsedOrders = Object.values({ designs });

      dispatch({
        type: "FETCH_DESIGNS",
        payload: parsedOrders
      });
    });
};
